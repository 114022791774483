import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import Service from "../service/apis";
import { Avatar, Loader } from "rsuite";
import moment from "moment";
import Cookies from "universal-cookie";
import Header from "../Header";
import NavigationBar from "../NavigationBar";
import { useSelector } from "react-redux";

const History = (history) => {
  const navigate = useNavigate();
  let username = useSelector((state) => state.Uname);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [start_week, setStart_week] = useState("");
  const [end_week, setEnd_week] = useState("");
  const [loading, setLoading] = useState(false);

  const [calender_toggle, setCalender_toggle] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function getStartEndOfWeekMondayUTC(date = new Date()) {
    // Get the current day of the week in UTC (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    // const dayOfWeek = date.getUTCDay();

    // // Calculate the difference in days to the start of the week (Monday) in UTC
    // const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

    // // Set the start of the week to the calculated Monday (in UTC)
    // const startOfWeek = new Date(date);
    // startOfWeek.setUTCDate(date.getUTCDate() + diffToMonday);

    // // Set the end of the week to the following Sunday (in UTC)
    // const endOfWeek = new Date(startOfWeek);
    // endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6); // Move to the following Sunday
    const dayOfWeek = date.getDay();

    // Calculate the difference in days to the start of the week (Sunday)
    const diffToSunday = dayOfWeek === 0 ? 1 : dayOfWeek; // If it's Sunday, no change; otherwise, adjust backward to previous Sunday

    // Set the start of the week to the calculated Sunday (in local time)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek);

    // Set the end of the week to the following Saturday (in local time)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return {
      startOfWeek: startOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      endOfWeek: endOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      startOfWeeks: startOfWeek, // Format as YYYY-MM-DD
      endOfWeeks: endOfWeek,
    };
  }
  const { startOfWeek, endOfWeek, startOfWeeks, endOfWeeks } =
    getStartEndOfWeekMondayUTC();

  const disabledDate = (date) => {
    const today = new Date();
    return date > today; // Disable future dates
  };

  useEffect(() => {
    leaderboard_fetch();
  }, []);
  const leaderboard_fetch = () => {
    setLoading(true);
    let params = {
      start_date: startOfWeek,
      end_date: endOfWeek,
    };
    Service.playing_history(params)
      .then((res) => {
        console.log(res?.data);
        if (res?.status == 1) {
          setLeaderboard(res?.data.reverse());
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const leaderboard_fetch_search = (start, end) => {
    setLoading(true);
    let params = {
      start_date: start,
      end_date: end,
    };
    Service.playing_history(params)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status == 1) {
          setLeaderboard(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setVisible(false);
          setCalender_toggle(false);
          //console.log("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [previousDate, setPreviousDate] = useState(null); // To track the previously selected date

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const generateCalendarGrid = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const startOffset = firstDayOfMonth;
    const endOffset = 6 - lastDayOfMonth;

    const placeholdersBefore = Array.from({ length: startOffset }, () => null);
    const placeholdersAfter = Array.from({ length: endOffset }, () => null);

    return [...placeholdersBefore, ...daysInMonth, ...placeholdersAfter];
  };

  // Adjusted function to get the week range (Monday to Sunday)
  const getWeekRange = (date) => {
    const dayIndex = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayIndex);
    startOfWeek.setHours(0, 0, 0, 0); // Reset time to the start of the day

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day

    return { startOfWeek, endOfWeek };
  };
  const generateWeeks = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const placeholdersBefore = Array.from(
      { length: firstDayOfMonth },
      () => null
    );
    const placeholdersAfter = Array.from(
      { length: 6 - lastDayOfMonth },
      () => null
    );

    const allDays = [
      ...placeholdersBefore,
      ...daysInMonth,
      ...placeholdersAfter,
    ];

    // Split days into weeks (7 days per row)
    const weeks = [];
    for (let i = 0; i < allDays.length; i += 7) {
      weeks.push(allDays.slice(i, i + 7));
    }
    return weeks;
  };
  const isCurrentWeek = (week) => {
    const currentWeekRange = getWeekRange(new Date());
    return week.some((day) => {
      return (
        day &&
        day >= currentWeekRange.startOfWeek &&
        day <= currentWeekRange.endOfWeek
      );
    });
  };

  const isPreviousWeek = (week) => {
    if (!selectedDate) return false; // If no date is selected, return false.
    const selectedWeekRange = getWeekRange(selectedDate);

    return (
      week.some((day) => {
        return (
          day &&
          day >= selectedWeekRange.startOfWeek &&
          day <= selectedWeekRange.endOfWeek
        );
      }) && !isCurrentWeek(week)
    );
  };
  const weeks = generateWeeks(currentDate);
  const isSelectedWeek = (week) => {
    if (!selectedDate) return false; // If no date is selected, return false.
    const selectedWeekRange = getWeekRange(selectedDate);
    return week.some((day) => {
      return (
        day &&
        day >= selectedWeekRange.startOfWeek &&
        day <= selectedWeekRange.endOfWeek
      );
    });
  };
  const isInCurrentWeek = (date) => {
    return (
      date >= currentWeekRange.startOfWeek && date <= currentWeekRange.endOfWeek
    );
  };
  const isDateInWeekRange = (date, week) => {
    console.log(date);
    console.log(week);
    let dateInWeek = week.some(
      (day) => day && day.toDateString() === date.toDateString()
    ); // week.includes(date);
    console.log("hello", dateInWeek);
    return dateInWeek;
  };

  const getIndexOfWeekbyDate = (date) => {
    console.log(date);
    let index = -1;
    weeks.forEach((week, weekIndex) => {
      console.log("WEEK", week);
      let matchFound = isDateInWeekRange(date, week);
      if (matchFound) {
        index = weekIndex;
      }
    });

    return index;
  };

  const isFutureDate = (date) => {
    const today = new Date();
    return date > today; // Return true if the date is in the future
  };
  const isDateInWeek = (date, weekRange) => {
    return date >= weekRange.startOfWeek && date <= weekRange.endOfWeek;
  };

  const getCurrentWeekRange = () => {
    return getWeekRange(new Date()); // Using today's date to calculate the current week
  };

  const isDateInCurrentWeek = (date) => {
    const { startOfWeek, endOfWeek } = getWeekRange(currentDate);
    return date >= startOfWeek && date <= endOfWeek;
  };

  const handleCurrentWeekHighlight = (week) => {
    // Get the range for the current week
    const currentWeekRange = getWeekRange(new Date());
    return week.some((day) => {
      return (
        day &&
        day >= currentWeekRange.startOfWeek &&
        day <= currentWeekRange.endOfWeek
      );
    });
  };

  const handleDateClick = (date) => {
    let newDateWeekIndex = getIndexOfWeekbyDate(date);
    let currentDateWeekIndex = -1;
    if (selectedDate) {
      currentDateWeekIndex = getIndexOfWeekbyDate(selectedDate);
    }

    console.log("newDateWeekIndex", newDateWeekIndex);
    console.log("currentDateWeekIndex", currentDateWeekIndex);

    if (newDateWeekIndex != currentDateWeekIndex) {
      if (selectedDate) setPreviousDate(selectedDate); // Update previously selected date
    }

    setSelectedDate(date);

    //if (date) onDateSelect(date);
    // Get the week range for the clicked date
    const { startOfWeek, endOfWeek } = getWeekRange(date);

    // Pass the start and end of week to the console
    leaderboard_fetch_search(
      moment(startOfWeek).format("YYYY-MM-DD"),
      moment(endOfWeek).format("YYYY-MM-DD")
    );
    setStart_week(startOfWeek);
    setEnd_week(endOfWeek);
    setCalender_toggle(false);
    console.log(
      "Start of Week singleton: ",
      moment(startOfWeek).format("YYYY-MM-DD")
    );
    console.log("End of Week singleton: ", endOfWeek.toLocaleDateString());
  };

  const calendarGrid = generateCalendarGrid(currentDate);
  const selectedWeekRange = selectedDate ? getWeekRange(selectedDate) : null;
  const previousWeekRange = previousDate ? getWeekRange(previousDate) : null;
  const currentWeekRange = getCurrentWeekRange();
  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };
  return (
    <div>
      <Header onPress={toggleMenu} />

      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="row h-100">
          {/* NAVIGATION */}
          <NavigationBar isActive={isActive} username={username} />
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center">
                {visible && (
                  <div className="custom-picker-popup-mobile">
                    {search_data?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem"
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            //window.location.reload();
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item?.title?.replace(/-/g, " ")}
                            <div className="d-flex gameInfo">
                              <small>{item?.category_names}</small>&nbsp; |
                              &nbsp;
                              <small>{item?.technology_names}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="row h-100 mb-3">
              {/* <div className="col-12 col-sm-3"></div> */}
              <div className="col-12 ">
                <div className="dark-bg h-100 px-3">
                  <div className="row m-0">
                    <div className="col mb-2 d-flex align-items-center justify-content-between">
                      <h5 className="leaderboardTitle">
                        <img
                          onClick={() => navigate("/profile")}
                          src={require("../assets/left-arrow.png")}
                          alt=""
                          className="backBtn"
                        />
                        Playing History
                      </h5>
                      <span className="date" ref={wrapperRef}>
                        {calender_toggle && (
                          <div className="calendar">
                            <div className="calendar-header text-white">
                              <button onClick={handlePreviousMonth}>
                                <i class="fa-solid fa-chevron-left"></i>
                              </button>
                              <h2>
                                {currentDate.toLocaleDateString("en-US", {
                                  month: "long",
                                  year: "numeric",
                                })}
                              </h2>
                              <button onClick={handleNextMonth}>
                                <i class="fa-solid fa-chevron-right"></i>
                              </button>
                            </div>
                            <div>
                              <div className="calendar-grid1">
                                {[
                                  "Sun",
                                  "Mon",
                                  "Tue",
                                  "Wed",
                                  "Thu",
                                  "Fri",
                                  "Sat",
                                ].map((day) => (
                                  <div
                                    key={day}
                                    className="calendar-day-header text-white"
                                  >
                                    {day}
                                  </div>
                                ))}
                              </div>
                              <div className="calendar-grid">
                                {weeks.map((week, weekIndex) => (
                                  <div
                                    key={weekIndex}
                                    className={`calendar-week ${
                                      selectedDate &&
                                      isDateInWeekRange(selectedDate, week)
                                        ? "highlighted-week-current"
                                        : currentDate &&
                                          isDateInWeekRange(currentDate, week)
                                        ? "highlighted-previous-week"
                                        : ""
                                    } 
                                   
                                    `}
                                  >
                                    {week.map((day, index) => (
                                      <div
                                        key={index}
                                        className={`calendar-day ${
                                          day &&
                                          selectedDate &&
                                          isDateInWeekRange(selectedDate, week)
                                            ? "highlighted-week-current"
                                            : "placeholder"
                                        }
                                       
                                        
                                        `}
                                        onClick={() =>
                                          day &&
                                          !isFutureDate(day) &&
                                          handleDateClick(day)
                                        }
                                      >
                                        {day ? day.getDate() : ""}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setCalender_toggle(!calender_toggle)}
                          className="orange_sec"
                        >
                          {`${moment(
                            location?.state?.start_weeks ||
                              start_week ||
                              startOfWeek
                          ).format("DD MMM")} - ${moment(
                            location?.state?.end_weeks || end_week || endOfWeek
                          ).format("DD MMM, YYYY")}`}
                          <span
                            // className="editLink d-flex align-items-center"
                            style={{ pointerEvents: "none", cursor: "pointer" }}
                          >
                            <img
                              style={{ marginLeft: 6 }}
                              src={require("../assets/down-arrow.png")}
                              width={14}
                              alt=""
                            />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="col-12">
                      <div className="scrollTable">
                        <table className="leaderboardTable w-100 text-white">
                          {loading ? (
                            <div className="text-center">
                              <Loader
                                center
                                size="md"
                                backdrop
                                content="Please wait..."
                              />
                            </div>
                          ) : (
                            leaderboard?.map((item, index) => {
                              return (
                                <tr>
                                  {/* <td style={{ width: "50px" }}>{index + 1}</td> */}
                                  <td style={{ width: "400px" }}>
                                    <div className=" d-flex align-items-center text-nowrap">
                                      <Avatar
                                        bordered={false}
                                        src={item.Game_image}
                                        className="me-3"
                                        size="md"
                                      />
                                      {item?.Game_name?.replace(/-/g, " ")}
                                    </div>
                                  </td>
                                  <td>
                                    {`${moment(item?.Playing_date_time).format(
                                      "DD MMM, YYYY"
                                    )}`}
                                  </td>
                                  {/* <td>
                                  {moment(item?.Playing_date_time).format(
                                    "HH:MM"
                                  )}
                                </td> */}
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {`Score : ${item?.Score}`}
                                    </div>
                                  </td>
                                  <td
                                    style={{ width: "100px" }}
                                    className="text-start"
                                  >
                                    <div className="d-flex align-items-center justify-content-start">
                                      <img
                                        src={require("../assets/star.png")}
                                        width={15}
                                        alt=""
                                        className="me-1"
                                      />
                                      {item?.Equivalent_Score}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </table>
                      </div>
                      {leaderboard?.length == 0 && (
                        <div className="row">
                          <div className="col">
                            <div className="empty_list">
                              <img src={require("../assets/empty.png")} />
                              <p className="text-center ">
                                Opps! You have not played any game yet. Start
                                playing.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default History;
