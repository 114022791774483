import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import NavigationBar from "../NavigationBar";
import Header from "../Header";
import { useSelector } from "react-redux";

const Terms = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const username = useSelector((state) => state.Uname);

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="scroll_issue">
      <Header onPress={toggleMenu} />
      <div className="container-fluid pl-80 d-flex flex-column mh-100vh">
        <div className="row scroll-section">
          {/* NAVIGATION */}
          <NavigationBar isActive={isActive} username={username} />
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center"></div>
            </div>
            <div className="row gx-2 terms_sec">
              <div className="catgoryTitle mb-3">
                <h3>{location?.state?.name}</h3>
                <p>Last updated: December 2024</p>
              </div>
              <div className="col text-white">
                <h5>Introduction</h5>
                <p>
                  Welcome to the Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited website (the "Website"). These
                  Terms of Service (the "Terms") are entered into between
                  Thinktrek Entertainment Private Limited, a company registered
                  under the applicable laws, with its registered office at
                  Thinktrek Entertainment Private Limited, 22/748, DDA Flats,
                  Kalkaji, New Delhi- 110019, India and the User ("User", "you",
                  or "your") (together, the "Parties"). Playzhub.com owned and
                  operated by Thinktrek Entertainment Private Limited operates a
                  free, online casual gaming platform ("Platform") where users
                  can discover and enjoy a variety of web games in an easy and
                  accessible way.
                </p>
                <p>
                  By accessing or using the Website and/or Platform, including
                  playing games, registering an account, or otherwise
                  interacting with the Website, you agree to comply with and be
                  bound by these Terms and all applicable laws and regulations.
                </p>
                <p>
                  The Platform is intended for visitors and users aged thirteen
                  (13) years or older, or the applicable minimum age in your
                  country. If you are under thirteen (13) years old (or the
                  applicable minimum age), this Platform is not intended for
                  you. We do not knowingly collect or solicit personal
                  information from children under the age of thirteen (13) (or
                  the applicable minimum age in your country).
                </p>
                <p>
                  Your use of the Website and/or the Platform means that you
                  acknowledge and agree to the most recent version of these
                  Terms and our Privacy Policy, as published on the Website. You
                  are responsible for ensuring that your use of any third-party
                  content or services complies with their respective terms and
                  conditions.
                </p>
                <p>
                  We reserve the right to update and modify these Terms at any
                  time. If you are a registered user, we will notify you of any
                  material changes via email or by posting a notice on the
                  Website. Changes will be effective once posted, and continued
                  use of the Website and/or Platform after the update will
                  signify your acceptance of the revised Terms. These Terms will
                  remain in effect until terminated by either you or us, as
                  outlined in the relevant sections of these Terms.
                </p>

                <h5>User License and Gaming Content</h5>
                <p>
                  Our Platform is licensed to you for personal use, but
                  ownership of the Platform and all associated content remains
                  with Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited and its licensors. As long as
                  you comply with these Terms, Thinktrek Entertainment Private
                  Limited grants you a limited license to use our Platform and
                  play the available web games for personal, non-commercial use.
                </p>
                <p>
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited grants you a non-exclusive,
                  limited, non-sublicensable, and non-transferable right to use
                  our Platform, subject to the limitations outlined in these
                  Terms and any other restrictions we may communicate to you in
                  writing. Nothing in these Terms prevents Thinktrek
                  Entertainment Private Limited from providing the Platform to
                  others.
                </p>
                <p>
                  Except for the limited rights expressly granted herein,
                  Thinktrek Entertainment Private Limited reserves all rights,
                  title, and interest in and to the Website and Platform,
                  including all associated intellectual property rights. Any
                  games made available through our Platform are owned by
                  Thinktrek Entertainment Private Limited or its partners. No
                  rights are granted to you except as expressly stated in these
                  Terms. You agree not to reproduce, duplicate, copy, sell,
                  resell, or exploit any part of the Website or Platform, or
                  your access to them, without our express written consent. You
                  may not duplicate, copy, or reuse any of the visual design
                  elements without our express written consent.
                </p>
                <p>
                  Users may upload, create, or provide content on the Platform
                  ("User Content"). By uploading, creating, or otherwise
                  providing User Content, you grant Thinktrek
                  Entertainment Private Limited a non-exclusive, royalty-free,
                  worldwide, sublicensable, transferable license to use, copy,
                  store, modify, transmit, and display such User Content as
                  necessary to provide and maintain the Website and Platform.
                  Thinktrek Entertainment Private Limited reserves the right,
                  but is not obligated, to review and remove User Content that
                  it deems to violate these Terms, infringe third-party rights,
                  or be inappropriate or illegal.
                </p>
                <p>
                  Users may create, upload, livestream, and monetize videos on
                  third-party streaming platforms using gameplay and screenshots
                  from games available on our Platform ("Gaming Content"),
                  subject to the following conditions:
                </p>
                <ul>
                  <li>
                    Playzhub.com Platform and its logo must be clearly visible
                    and not obscured
                  </li>
                  <li>
                    Monetization of such videos and streams must comply with the
                    monetization methods available on the streaming platforms
                    (e.g., YouTube)
                  </li>
                  <li>
                    you must not imply or state that your Gaming Content is
                    officially affiliated with, sponsored by, endorsed by, or
                    approved by Thinktrek Entertainment Private Limited, unless
                    we have entered into a partnership with you, and
                  </li>
                  <li>
                    you are responsible for any third-party content (e.g.,
                    music) used in connection with your Gaming Content.
                    Thinktrek Entertainment Private Limited reserves the right
                    to take action at any time against Gaming Content it
                    believes is unlawful, infringing, inappropriate, or not in
                    accordance with these Terms.
                  </li>
                </ul>
                <p>
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited will make reasonable efforts to
                  resolve any issues, provided that the User complies with these
                  Terms.
                </p>

                <h5>Access and Usability</h5>
                <p>
                  You can access our Platform and play web games either with or
                  without an account. It is your responsibility to keep your
                  username and password secure. Any activities that occur under
                  your account or on our Platform are your responsibility. The
                  information you provide for your account must be accurate and
                  complete. We are not liable for any loss or damage caused by
                  your failure to comply with these requirements or by the use
                  of your account. If you are under 13 years old (or the
                  applicable minimum age in your country), or if you do not
                  understand this agreement, you must review it with your parent
                  or guardian. Your access to our Platform is also subject to
                  certain limitations. In general, you should avoid actions that
                  could harm Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited or others. You may not copy,
                  misuse, or abuse the Platform or its content, make false
                  representations, or violate anyone’s rights or any applicable
                  laws. Failure to comply with these rules may result in the
                  suspension or termination of your account.
                </p>
                <p>
                  You can access the Platform either with or without an account.
                  To create an account, log in to the Website, or participate in
                  our Platform, you must meet the eligibility requirements and
                  agree to these Terms. Failure to qualify or comply with these
                  Terms may lead to the termination of your account and access
                  to the Platform. Specifically, you agree that:
                </p>
                <p>You are either</p>
                <ul style={{ listStyleType: "none" }}>
                  <li>(a) of legal age to provide consent or</li>
                  <li>
                    (b) at least thirteen (13) years old or the applicable
                    minimum age in your country, state, or province, or
                  </li>
                  <li>
                    (c) have consent from a parent or guardian to access the
                    Platform in accordance with the laws applicable to you. We
                    may request proof of your age at any time, though we are not
                    obligated to do so.
                  </li>
                </ul>
                <i>
                  All information you provide when registering for an account
                  must be true and accurate, and you agree to update us promptly
                  if any of that information changes.
                </i>
                <p>
                  Your account is for your personal use only. You may not allow
                  others to use your account, username, password, or login to
                  access or use the Website or Platform. We are not responsible
                  for any third-party access to your account. If you suspect
                  unauthorized use, you must notify us immediately. You are
                  responsible for all activities under your account and for the
                  security of your password.
                </p>
                <i>
                  You have verified that your use of the Website and Platform
                  complies with all applicable laws and regulations. It is your
                  responsibility to ensure compliance.
                </i>
                <p>
                  You will not use the Website or Platform for fraudulent or
                  illegal activities.
                </p>
                <p>
                  We may detect your Internet access location and may block or
                  restrict access from locations where participation in the
                  Platform is illegal or restricted.
                </p>
                <p>
                  You will not attempt to mask your identity, including using
                  proxy servers or IP masking, to access the Website or
                  Platform.
                </p>
                <p>
                  You agree to ensure all activities on your account comply with
                  these Terms. We may suspend or terminate your access if your
                  account remains inactive for a long period.
                </p>
                <p>
                  You agree to use the Website and Platform only for their
                  intended purposes. Specifically, you will not:
                </p>
                <p>
                  <i>
                    Sublicense, lease, rent, lend, distribute, or transfer
                    access to the Website or Platform to third parties;
                  </i>
                </p>
                <p>
                  Decompile, reverse engineer, disassemble, or attempt to derive
                  the source code of the Website or Platform;
                </p>
                <p>
                  Use or copy the Website or Platform except as expressly
                  permitted in these Terms;
                </p>
                <p>
                  Use the Website or Platform to send unsolicited emails, spam,
                  or advertisements;
                </p>
                <p>
                  Modify, remove, or obscure any copyright, trademark, or other
                  proprietary notices on the Platform;
                </p>
                <p>
                  Intentionally distribute viruses, malware, or other harmful
                  files or use the Platform for illegal, fraudulent, or invasive
                  purposes;
                </p>
                <i>
                  Circumvent any security or technical protection measures on
                  the Website or Platform;
                </i>
                <p>
                  Use automated tools, such as robots, spiders, or scrapers, to
                  access, acquire, copy, or monitor the Website or Platform
                  content without our prior written consent. This includes using
                  such tools for commercial purposes like training machine
                  learning algorithms.
                </p>
                <p>
                  You may, however, temporarily store copies of materials in
                  RAM, store files automatically cached by your web browser, and
                  print reasonable numbers of pages from the Website, provided
                  you do not modify or remove any copyright or proprietary
                  notices.
                </p>
                <p>
                  Content or opinions shared by users on message boards, blogs,
                  chat rooms, or other publicly accessible areas of the Website,
                  other than content provided by Thinktrek Entertainment Private
                  Limited, reflect the views of the person or entity sharing
                  them and not of Thinktrek Entertainment Private Limited. You
                  are responsible for any content you share and must ensure it
                  complies with all applicable laws.
                </p>
                <p>
                  <strong>
                    You agree not to post, transmit, or share any content that:
                  </strong>
                </p>
                <p>
                  Threatens, harasses, or degrades individuals or groups based
                  on religion, gender, sexual orientation, race, ethnicity, age,
                  disability, or otherwise violates others’ rights and dignity;
                </p>
                <p>
                  Is defamatory, libelous, fraudulent, invasive of privacy, or
                  otherwise unlawful;
                </p>
                <p>
                  Is obscene, pornographic, harmful to children, or otherwise
                  objectionable;
                </p>
                <p>
                  Infringes on copyrights, trademarks, privacy rights, or other
                  proprietary rights without the express written consent of the
                  owner.
                </p>
                <p>
                  We reserve the right to remove any content that violates these
                  Terms or is otherwise deemed inappropriate.
                </p>

                <h5>Account Information verification</h5>
                <p>
                  We reserve the right to verify your information and ensure
                  that your use of the Platform complies with our rules.
                </p>
                <p>
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limitedre serves the right (but is not
                  obligated) to conduct an audit at any time to verify your
                  account information and to ensure that your participation on
                  the Website and use of the Platform does not violate these
                  Terms or any applicable laws. You authorize us and our agents
                  to make inquiries and disclose information to third parties as
                  necessary to validate the information you have provided. To
                  assist with this verification, you agree to provide any
                  information or documentation that we may request, at our sole
                  discretion. If you fail to provide the requested information
                  within thirty (30) days of our request, if your responses are
                  incomplete or insufficient, or if we are unable to verify the
                  information associated with your account, we may terminate
                  your account.
                </p>

                <h5>Third-Party Websites and Promotions</h5>
                <p>
                  We do not control third-party services, and we are not liable
                  for any transactions or actions involving them. Your security
                  is your responsibility when using third-party services. Please
                  note that promotions available through our Platform may have
                  additional rules.
                </p>
                <p>
                  Our website may contain links to third-party websites.
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited is not responsible for, nor does
                  it endorse, the content, products, services, or practices of
                  these third-party websites, including those embedded within
                  our website or third-party advertisements. We make no
                  representations regarding the quality, content, accuracy, or
                  suitability of these external websites. Your use of
                  third-party websites is at your own risk and governed by the
                  terms of use of those websites.
                </p>
                <p>
                  If you choose to purchase products or services from a
                  third-party merchant, Playzhub.com owned and operated by
                  Thinktrek Entertainment Private Limited is not responsible for
                  those transactions. We are not a party to such transactions
                  and are not liable for any costs, damages, or disputes arising
                  between you and the third party. Playzhub.com owned and
                  operated by Thinktrek Entertainment Private Limited and its
                  licensors or contractors do not make any express or implied
                  representations or warranties regarding the goods or services
                  offered by these third-party merchants, including but not
                  limited to warranties of merchantability, fitness for a
                  particular purpose, title, non-infringement, or compatibility.
                </p>
                <p>
                  Any sweepstakes, contests, raffles, surveys, games, or similar
                  promotions (collectively, "Promotions") made available through
                  the Website may be governed by separate rules. If you
                  participate in any Promotions, please review the applicable
                  rules and our Privacy Policy. If the rules for a Promotion
                  conflict with these Terms, the Promotion rules will take
                  precedence.
                </p>

                <h5>Embedding Games</h5>
                <p>
                  Other websites may embed our games under the conditions
                  outlined below. We reserve the right to stop embedding any
                  game at any time, without the need to provide justification.
                  When games are embedded, they will display in-game
                  advertisements.Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited reserves the right to withdraw
                  permission for any website to embed our games at any time, at
                  our sole discretion, without prior warning or justification.
                </p>
                <p>It is strictly prohibited to:</p>
                <ul>
                  <li>
                    Reproduce, modify, adapt, translate, or create derivative
                    works of any portion of the Platform.
                  </li>
                  <li>
                    Remove, hide, or otherwise obstruct any copyright,
                    trademark, or proprietary rights notice in the Platform.
                  </li>
                  <li>
                    Remove, block, hide, or otherwise interfere with the
                    performance of any links within the Platform.
                  </li>
                  <li>
                    Remove, block, make invisible, or otherwise interfere with
                    the operation of any advertisements within the Platform.
                  </li>
                  <li>Add any layers or content above the Platform.</li>
                  <li>
                    Playzhub.com owned and operated by Thinktrek
                    Entertainment Private Limited reserves the right to
                    discontinue or modify any portion of the Platform at any
                    time.
                  </li>
                  <li>
                    If you have any questions regarding embedding our games,
                    please contact us at publisher-support@thinktrekgames.com.-
                    <a href="mailto:support@thinktrekgames.com">
                      support@thinktrekgames.com
                    </a>
                    .
                  </li>
                </ul>

                <h5>Warranties; Disclaimer</h5>
                <p>
                  We provide our services and Platform "as is" and without any
                  warranties, including those related to quality, security, or
                  compatibility.
                </p>
                <p>
                  You agree to follow these Terms of Service and any additional
                  guidelines of our Platform. If any issues arise from your use
                  of the Platform, we will not be held liable.
                </p>
                <p>
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited does not guarantee that files
                  available for download from the internet will be free from
                  viruses, worms, Trojan horses, or other harmful code. You are
                  responsible for maintaining appropriate security measures and
                  backup systems to protect your data.
                </p>
                <p>
                  OUR SERVICES, INCLUDING ALL MATERIALS ON THE WEBSITE AND
                  PLATFORM, ARE PROVIDED "AS IS" AND "AS AVAILABLE."
                  PLAYZHUB.COM OWNED AND OPERATED BY THINKTREK
                  ENTERTAINMENT PRIVATE LIMITEDDISCLAIMS ALL WARRANTIES, EXPRESS
                  OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS
                  FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. PLAYZHUB.COM
                  OWNED AND OPERATED BY THINKTREK ENTERTAINMENT PRIVATE
                  LIMITEDMAKES NO GUARANTEES ABOUT THE ACCURACY, COMPLETENESS,
                  OR SUITABILITY OF ANY MATERIAL ON THE WEBSITE OR PLATFORM. WE
                  DO NOT WARRANT THAT THE WEBSITE OR PLATFORM WILL BE
                  UNINTERRUPTED, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL
                  COMPONENTS.
                </p>
                <h5>Limitation of Liability</h5>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO
                  CIRCUMSTANCES WILL PLAYZHUB.COM OWNED AND OPERATED BY
                  THINKTREK ENTERTAINMENT PRIVATE LIMITEDOR ITS DIRECTORS,
                  EMPLOYEES, PARTNERS, LICENSORS, CONTRACTORS, AGENTS,
                  SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT,
                  INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
                  INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF DATA, USE,
                  GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING FROM
                </p>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE
                    THE PLATFORM; (II) ANY THIRD-PARTY CONDUCT OR CONTENT ON THE
                    PLATFORM;
                  </li>
                  <li>(II) ANY CONTENT OBTAINED FROM THE PLATFORM; </li>
                  <li>
                    (III) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT,
                    TRADEMARK, OR INTELLECTUAL PROPERTY RIGHTS INFRINGEMENT;
                  </li>
                  <li>
                    (IV) ANY DAMAGE CAUSED BY A USER'S COMPUTER, SOFTWARE,
                    HARDWARE, OR SECURITY BREACH, INCLUDING, BUT NOT LIMITED TO,
                    DAMAGE FROM ANY SECURITY BREACH, VIRUS, BUGS, TAMPERING,
                    FRAUD, ERRORS, DELAYS, OR MALFUNCTIONS; AND
                  </li>
                  <li>
                    (V) UNAUTHORIZED ACCESS, USE, OR MODIFICATION OF YOUR
                    TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY,
                    CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL
                    THEORY, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES, AND EVEN IF ANY REMEDY SET
                    FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE.
                  </li>
                </ul>
                <p>
                  CERTAIN JURISDICTIONS PROHIBIT THE DISCLAIMER OF IMPLIED
                  WARRANTIES OR THE LIMITATION OF LIABILITY FOR INCIDENTAL OR
                  CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE LIMITATIONS AND
                  EXCLUSIONS ABOVE MAY NOT APPLY TO YOU IF YOU RESIDE IN ONE OF
                  THOSE JURISDICTIONS.
                </p>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW,
                  THINKTREK GAMES’ MAXIMUM LIABILITY TO YOU ARISING FROM THESE
                  TERMS OF SERVICE WILL NOT EXCEED 100 EUR, CONSIDERING THE
                  PLATFORM IS PROVIDED FREE OF CHARGE. THE ABOVE LIMITATIONS
                  WILL NOT AFFECT YOUR STATUTORY RIGHTS AND DO NOT APPLY TO A
                  PARTY’S LIABILITY ARISING FROM
                </p>
                <ul>
                  <li>FRAUD OR DECEIT,</li>
                  <li>GROSS NEGLIGENCE,</li>
                  <li>DEATH OR PERSONAL INJURY, AND/OR </li>
                  <li>WILLFUL MISCONDUCT.</li>
                </ul>

                <h5>Indemnification</h5>
                <p>
                  If Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited is sued or faces any harm due to
                  your actions, you will be responsible for the associated costs
                  and damages.
                </p>
                <p>
                  You agree to defend, indemnify, and hold harmless Thinktrek
                  Entertainment Private Limited, its subsidiaries, affiliates,
                  licensors, content providers, service providers, employees,
                  agents, officers, directors, and contractors (the "Indemnified
                  Parties") from and against any and all liabilities, losses,
                  damages, costs, or expenses, including but not limited to
                  court costs, attorneys' fees, and any awards or damages caused
                  by or in connection with:
                </p>
                <ul>
                  <li>your use of the Platform; </li>
                  <li>your use of the Website; or </li>
                  <li>any other services offered through the Website.</li>
                </ul>

                <h5>Termination or Deletion of Accounts</h5>
                <p>
                  You can delete your account at any time within the platform.
                  Additionally, we can suspend or terminate your access to the
                  platform if we believe you have violated our terms.
                </p>
                <p>
                  Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited reserves the right to terminate
                  these Terms and end your access to the Website and/or
                  Platform, or temporarily suspend all or part of your access,
                  at any time and without prior notice under the following
                  circumstances:
                </p>
                <p>
                  If we have a reasonable belief that you have violated, or may
                  potentially violate, any term or provision of these Terms;
                </p>
                <p>
                  If we determine that termination or suspension of your access
                  is required by applicable law or legal obligation;
                </p>
                <p>
                  At our sole discretion, for any other reason we deem
                  suspension or termination necessary or appropriate.
                </p>
                <p>
                  We may delete any Account Information or other materials
                  related to your use of the Website and/or Platform from our
                  servers or otherwise in our possession. You acknowledge that
                  we will not be liable to you or any third party for any
                  termination of your access to the Website and/or Platform.
                </p>
                <p>
                  You can delete your account at any time by going to account
                  settings and clicking on "Delete your account," or by
                  requesting us to delete your account via email.
                </p>

                <h5>Information, Complaints & Copyright Claims</h5>
                <p>
                  If you have any concerns or complaints, please email us at{" "}
                  <a href="mailto:legal@thinktrekgames.com">
                    legal@thinktrekgames.com
                  </a>{" "}
                  When you provide us with feedback, we are free to use it at
                  our discretion.
                </p>
                <p>
                  We value your opinion. if you provide us with feedback,
                  including data, variables, comments, suggestions, ideas,
                  notes, drawings, graphics, concepts or other information
                  ("Feedback"), you are providing that Feedback, and all of your
                  rights thereto, to us free of charge, and such Feedback will
                  be treated as non-confidential and non-proprietary and may be
                  used by us for any purpose, without your consent or any
                  compensation to you or anyone else. This applies whether you
                  submit such Feedback to us by email, through a form on the
                  Website, on a bulletin board or otherwise.
                </p>

                <h5>Applicable Law and Competent Court</h5>
                <p>
                  As a India based company, the law governing these terms and
                  our relationship with you is Belgian law. Any disputes will be
                  resolved by the competent courts of India.These Terms will be
                  interpreted in accordance with Indian law, without prejudice
                  to any other mandatory provisions of law that may be more
                  favorable to you under the laws of your country of habitual
                  residence.
                </p>
                <p>
                  The competent courts of India, will have exclusive
                  jurisdiction over any disputes or controversies arising from
                  or related to these Terms or their subject matter. If you are
                  a resident of a Member State or a country or state where this
                  clause is prohibited by local law, this clause will not apply
                  to you, and you will still be entitled to the protection of
                  the mandatory provisions of consumer protection laws in your
                  country. However, Playzhub.com owned and operated by Thinktrek
                  Entertainment Private Limited reserves the right to initiate
                  legal proceedings in any country other than India to protect
                  its interests or enforce its rights, as deemed necessary.
                </p>
                <p>
                  To the extent permitted by law, the Parties agree that all
                  claims and disputes will be made individually, and not as part
                  of any class action, class arbitration, or representative
                  proceeding.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112"></div>
      </div>
    </div>
  );
};

export default Terms;
