export const ACTIVE_TECH = "ACTIVE_TECH";
export const ACTIVE_STATIC = "ACTIVE_STATIC";
export const ACTIVE_CAT = "ACTIVE_CAT";
export const DOB = "DOB";
export const UNAME = "UNAME";
export const RESET = "RESET";
export const PROFILE_DATA = "PROFILE_DATA";
export const GAME_DATA = "GAME_DATA";
export const GENDER = "GENDER";
export const AVATAR_ID = "AVATAR_ID";
export const PROFILE_IMG = "PROFILE_IMG";
export const EXPIRE_TIME = "EXPIRE_TIME";
export const LIKED = "LIKED";
export const DISLIKED = "DISLIKED";
export const CAMERA_FILE = "CAMERA_FILE";
export const GUEST_TOKEN = "GUEST_TOKEN";
export const LOGIN_TOKEN = "LOGIN_TOKEN";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const GAME_STATE = "GAME_STATE";
