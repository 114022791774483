import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import { Input, InputGroup, MaskedInput } from "rsuite";
import Service from "../service/apis";
import HoverVideoPlayer from "react-hover-video-player";
import { Modal, Drawer, Button, HStack, AvatarGroup, Progress } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alert, confirm, prompt } from "@rsuite/interactions";
import {
  Divider,
  Avatar,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  Tooltip,
  Whisper,
} from "rsuite";
import moment from "moment";
import Cookies from "universal-cookie";
import ImageIcon from "@rsuite/icons/Image";
import { IoCamera } from "react-icons/io5";
import Avatar_data from "../avatars.json";
import { TiTick } from "react-icons/ti";
import Flag from "react-world-flags";
import useStore from "../service/zustand";
import Header from "../Header";
import NavigationBar from "../NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { DOB, UNAME, RESET, PROFILE_DATA, CAMERA_FILE } from "../store/Types";

let BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
const Update_Profile_Modal = NiceModal.create(
  ({
    navigate,
    avatar_id,
    full_name,
    gender_update,
    date_of_birth,
    profile_picture,
    file,
    preview,
    select_dob,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);
    const toaster = useToaster();
    const camera_file = useSelector((state) => state.camera_file);
    const { profile_status_load, add_profile_load } = useStore();
    const dob_store = useSelector((state) => state.dateOfBirth);
    const uname_store = useSelector((state) => state.Uname);
    const profile_details = useSelector((state) => state.profile_data);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(profile_details?.profile_picture);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState(full_name);
    const [gender, setGender] = useState(gender_update);
    const [dob, setDob] = useState(date_of_birth);
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(avatar_id);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);
    const [isProfileDelete, setIsProfileDelete] = useState(0);
    const [cameraFile, setCameraFile] = useState(null);
    const [cameraPreview, setCameraPreview] = useState(null);
    //const previewUrl = URL.createObjectURL(file);
    // console.log(previewUrl);
    // Function to handle avatar selection

    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
      setFileInfo(null);
      setFileList(null);
      setIsProfileDelete(1);
      setCameraFile(null);
      setCameraPreview(null);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      console.log(dob_store, select_dob);
      setLoading(true);
      if (!name) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);
      let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
      let form = new FormData();
      form.append("full_name", uname_store);
      form.append(
        "profile_picture",
        fileList || profile_details?.profile_picture
      );
      form.append(
        "date_of_birth",
        !dob_store ? "" : moment(dob_store).format("DD-MM-YYYY") || ""
      );
      form.append("gender", selectedGender || "");
      form.append("avatar_id", selectedAvatar || "");
      form.append("isProfileDelete", isProfileDelete);
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log("Success thread Contact===========>:", data);
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              //    console.log(
              //   "Success thread Contact===========>:",
              //   data
              // );
              modal.hide();
              add_profile_load(true);
              // window.location.reload();
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    // useEffect(() => {
    //   setName(full_name);
    // }, []);

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState(gender_update);

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
      if (file) {
        setFileList(file);
        setFileInfo(preview);
        // setFileInfo(null);
      }
    }, [file]);

    return (
      <Modal
        backdrop="static"
        overflow={false}
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          //NiceModal.show(Profile_edit_Modal);
        }}
        // onExited={() => modal.remove()}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              onClick={() => modal.hide()}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
                overflow: "visible",
              }}
            >
              {!cameraPreview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      navigate,
                      avatar_id,
                      full_name,
                      gender_update,
                      date_of_birth,
                      profile_picture,
                      file,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <>
                  {/* <img
                    onClick={() => {
                      NiceModal.show(Camera_Modal, {
                        avatar_id,
                        full_name,
                        gender_update,
                        date_of_birth,
                        profile_picture,
                        file,
                      });
                      modal.hide();
                    }}
                    src={cameraPreview}
                    className="after-ring"
                    width="100%"
                    height="100%"
                  /> */}
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      top: "30px",
                      left: "35px",
                      width: "13px",
                      height: "13px",
                      borderRadius: "3px",
                      background: cameraFile ? "#2ED573" : "",
                      position: "absolute",
                    }}
                  >
                    {cameraFile && (
                      <TiTick
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      />
                    )}
                  </div>
                </>
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => {
                  setFileList(event[0].blobFile);
                  setSelectedAvatar(12);
                  setIsProfileDelete(0);
                }}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  className="after_ring_main"
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                >
                  {fileInfo ? (
                    <>
                      <img
                        className="after-ring"
                        src={fileInfo}
                        width="100%"
                        height="100%"
                      />
                      <div
                        className="d-flex align-items-center justify-content-center after_ring1"
                        style={{
                          bottom: "11px",
                          right: "0",
                          width: "13px",
                          height: "13px",
                          borderRadius: "3px",
                          background: fileInfo ? "#2ED573" : "",
                          position: "absolute",
                        }}
                      >
                        {fileInfo && (
                          <TiTick
                            style={{ fontSize: "10px" }}
                            className="text-white"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <img
                      height={"15px"}
                      width={"15px"}
                      src={require("../assets/picked.png")}
                      style={{ fontSize: 15, color: "#fff" }}
                    />
                  )}
                </button>
              </Uploader>
              {fileInfo && (
                <span className="fromGallery">
                  <img
                    height={"15px"}
                    width={"15px"}
                    src={require("../assets/picked.png")}
                    style={{ fontSize: 15, color: "#fff" }}
                  />
                </span>
              )}
            </div>

            {Avatar_data?.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={
                      fileInfo
                        ? false
                        : selectedAvatar === item.avatar_id
                        ? true
                        : false
                    }
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item?.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: !fileInfo ? "#2ED573" : "",
                        position: "absolute",
                      }}
                    >
                      {!fileInfo && (
                        <TiTick
                          style={{ fontSize: "10px" }}
                          className="text-white"
                        />
                      )}
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div
            className="editField"
            style={{ marginTop: 8, cursor: "pointer" }}
            onClick={() => {
              NiceModal.show(Username_Modal, {
                navigate,
                full_name: uname_store,
              });
            }}
          >
            <label style={{ cursor: "pointer" }}>Username*</label>
            <Input
              readOnly
              //   disabled={name_status ? true : false}
              value={uname_store}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none", cursor: "pointer" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span className="editLink d-flex align-items-center">Edit</span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 8 }}
            onClick={() => {
              NiceModal.show(Update_Dob_Modal, {
                navigate,
                full_name: uname_store,
                file,
                preview,
                select_dob: dob,
              });
              modal.hide();
            }}
          >
            <label className="d-block" style={{ cursor: "pointer" }}>
              Date of birth
              {/* <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="hover"
                speaker={tooltip}
              >
                <img
                  className="ms-2 iconi"
                  src={require("../assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper> */}
            </label>

            <Input
              readOnly
              defaultValue={
                dob_store ? moment(dob_store).format("DD-MM-YYYY") : null
              }
              disabled={true}
              value={
                //   date_of_birth
                //     ? moment(date_of_birth).format("YYYY-MM-DD")
                //   :
                dob_store ? moment(dob_store).format("DD-MM-YYYY") : null
              }
              placeholder="Not Specified"
              style={{ background: "none", cursor: "pointer" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Update_Dob_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 8, cursor: "pointer" }}
            onClick={toggleDropdown}
          >
            <label className="d-block" style={{ cursor: "pointer" }}>
              Gender
            </label>
            <input
              style={{ cursor: "pointer" }}
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender}
            />

            <div
              className="custom-select-container"
              style={{ cursor: "pointer" }}
            >
              {isOpen && (
                <div style={{ cursor: "pointer" }}>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none", cursor: "pointer" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !name &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                className="guest-btn w-auto"
                onClick={() => {
                  onContactFromSubmit();
                  modal.hide();
                }}
                style={{
                  borderRadius: 20,
                  padding: "10px 25px",
                }}
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
              >
                Save & Continue
              </Button>
            )}
          </div>
          <p
            className="text-white text-center m-0 mt-3 login_para"
            style={{ fontSize: "12px" }}
          >
            Note: If DOB is not declared then you are agreeing to the {""}
            <br />
            <span
              onClick={() => {
                navigate("/terms_and_conditions");
                modal.hide();
              }}
              style={{ cursor: "pointer", color: "#F2641F" }}
            >
              Terms & Conditions
            </span>{" "}
            that you are 13+ years of age. To get the advantages and special
            privilages of partcipating in the Weekly Leaderboard Challenges, you
            need to Login.
          </p>
        </Modal.Body>
      </Modal>
    );
  }
);
const Update_Dob_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    console.log({ select_dob });
    const modal = useModal();
    const dispatch = useDispatch();
    let cookies = new Cookies();
    let date_store = useSelector((state) => state.dateOfBirth);
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);
    const disableDate = (date) => {
      const today = new Date();
      return date > today;
    };
    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Update_Profile_Modal, { navigate });
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Update_Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: select_dob,
                });
              }}
            ></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec d-flex">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div
                className="editField"
                style={{ marginTop: 8, cursor: "pointer" }}
              >
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  shouldDisableDate={disableDate}
                  format="dd-MM-yyyy"
                  editable={false}
                  style={{ cursor: "pointer" }}
                  defaultValue={date_store && new Date(date_store)}
                  // value={dob}
                  placeholder={"dd/mm/yyyy"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100 design_margin"
                  onChange={(e) => {
                    setDob(moment(e).toISOString());

                    // console.log(moment(e).toISOString());
                  }}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none", cursor: "pointer" }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                dispatch({
                  type: DOB,
                  payload: dob,
                });
                NiceModal.show(Update_Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: dob || select_dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Camera_Modal = NiceModal.create(
  ({
    navigate,
    avatar_id,
    full_name,
    gender_update,
    date_of_birth,
    profile_picture,
    file,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    const dispatch = useDispatch();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [cameraErr, setCameraErr] = useState("");
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      // Start the camera when the component mounts
      startCamera();

      // Cleanup the stream on component unmount
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject;
          // const tracks = stream.getTracks();
          // tracks.forEach((track) => track.stop());
        }
      };
    }, [videoRef.current]);

    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStream(stream); // Store the stream
          setCameraErr("");
          setHasPermission(true);
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
        setCameraErr("Camera is not supported.");
        setHasPermission(false);
      }
    };

    const stopCamera = () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop each track
        //setIsCameraOn(false); // Update the camera status
        setHasPermission(false);
      }
    };

    const captureImage = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const context = canvas.getContext("2d");
        // Draw the current frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a Blob (image file)
        canvas.toBlob((blob) => {
          // Create a File from the Blob and append it to FormData
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          const imageURL = canvas.toDataURL("image/png");
          console.log(file);
          // dispatch({
          //   type: CAMERA_FILE,
          //   payload: file,
          // });
          //console.log(imageURL);
          //  const formData = new FormData();
          //  formData.append("image", file);
          stopCamera();
          NiceModal.show(Update_Profile_Modal, {
            navigate,
            avatar_id,
            full_name,
            gender_update,
            date_of_birth,
            profile_picture,
            file: file,
            preview: imageURL,
          });
          modal.hide();

          // Send the FormData to the server (for example, using fetch)
        }, "image/png"); // You can also use 'image/jpeg' for JPEG format
      }
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => modal.hide()}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Camera
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Update_Profile_Modal, { navigate });
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row flex-wrap">
            <div className="w-100">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay
                style={{ background: "#333947" }}
              />

              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width="640"
                height="480"
              />
            </div>
          </div>
          <div className="text-center">
            <p style={{ color: "red" }}>{cameraErr}</p>
          </div>
          <div className="text-center">
            <Button
              disabled={!hasPermission ? true : false}
              onClick={captureImage}
              appearance="primary"
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
              }}
            >
              Capture
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Username_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    const dispatch = useDispatch();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);
    let [msg, setMsg] = useState("");
    let [err_msg, setErr_msg] = useState("");

    const verify_username = (e) => {
      //console.log(e.length);
      setName(e);
      if (e.length >= 3) {
        setName(e);
        let params = {
          user_name: e,
        };
        Service.verify_username(params)
          .then((res) => {
            // console.log(res);
            if (res?.status == 0) {
              setErr_msg("Username available.");
              setMsg("");
            } else if (res?.status == 1) {
              setMsg("Username already exists.");
              setErr_msg("");
            }
          })
          .catch((e) => console.log(e));
      } else if (e.length == 0) {
        setMsg("");
        setErr_msg("");
      }
    };

    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          //   NiceModal.show(Profile_Modal);
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                // NiceModal.show(Profile_Modal);
              }}
            ></i>
            Update Username
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="brthdy_sec"
          style={{ maxHeight: "480px !important" }}
        >
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Username</label>
                <Input
                  defaultValue={full_name}
                  // value={name}
                  placeholder="Fill username"
                  onChange={verify_username}
                  style={{ background: "none" }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    color: !msg ? "#118B50" : "#FA4032",
                  }}
                >
                  {msg || err_msg}
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              disabled={name.length == 0 ? true : false}
              className={name.length == 0 && "guest-btn"}
              onClick={() => {
                NiceModal.show(Update_Profile_Modal, {
                  navigate,
                  full_name: name,
                  file,
                  preview,
                  select_dob: dob,
                });
                dispatch({
                  type: UNAME,
                  payload: name,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: name.length !== 0 && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [stackList, setStackList] = useState([]);
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredTitles, setFilteredTitles] = useState(data);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const [username, setUsername] = useState("");
  const [msg_err, setMsg_err] = useState(0);
  const { profile_status_load, add_profile_load, token } = useStore();
  let profile_details = useSelector((state) => state.profile_data);
  let phone_number = useSelector((state) => state.phone_number);
  let cookies = new Cookies();

  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("Page is being reloaded or closed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    visitor_details();
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  let ACCESS_GUEST_USER = cookies.get("token");

  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [err, setErr] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [country_alias, setCountry_alias] = useState("");
  const [score, setScore] = useState("");

  const [date_of_birth, setDate_of_birth] = useState("");
  const [fav_list, setFav_list] = useState([]);
  const [play_history, setPlay_history] = useState([]);
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    visitor_details();
    fav_games();
  }, [profile_status_load]);

  const log_out = () => {
    cookies.remove("token", { path: "/" });
    cookies.remove("network_details", { path: "/" });
    cookies.remove("login_token", { path: "/" });
    window.localStorage.clear();
    dispatch({
      type: RESET,
    });
    navigate("/");
    /// window.location.reload(true);
  };

  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        let date_of_birth = res?.data?.date_of_birth;

        if (res.status == 1) {
          setName(res?.data?.full_name);
          setDob(date);
          setDate_of_birth(date_of_birth);
          setGender(res?.data?.gender);
          setCountry(res?.data?.country);
          setScore(res?.data?.equivalent_point);
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setPlay_history(res?.data?.playing_history);
          setPhone(res?.data?.phone_number);
          setCountry_code(res?.data?.country_code);
          setCountry_alias(res?.data?.country_code_alias);
        }
        dispatch({
          type: DOB,
          payload: date_of_birth,
        });
        dispatch({
          type: UNAME,
          payload: res?.data?.full_name,
        });

        dispatch({
          type: PROFILE_DATA,
          payload: res?.data,
        });
      })
      .catch((e) => console.log(e));
  };

  const fav_games = () => {
    Service.fav_games()
      .then((res) => {
        setFav_list(res?.data);
      })
      .catch((e) => console.log);
  };

  const fav_status = (id, status) => {
    let params = {
      game_id: id,
      status: status,
    };
    Service.fav_games_status(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 1) {
          fav_games();
        }
      })
      .catch((e) => console.log(e));
  };

  const targetDate = new Date(profile_details?.created_at);
  const currentDate = new Date();
  const timeDifference = currentDate - targetDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );
  return (
    <div>
      <Header onPress={toggleMenu} />

      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="total_side_gap">
          <div className="row ">
            {/* NAVIGATION */}
            <NavigationBar isActive={isActive} username={username} />

            {/* NAVIGATION */}
            {/* PAGE CONTENT */}
            <div className="col mt-3">
              <div className="row d-block d-lg-none">
                <div className="col-12 mb-3 d-flex justify-content-center">
                  {visible && (
                    <div className="custom-picker-popup-mobile">
                      {search_data?.map((item) => {
                        return (
                          <div
                            className="d-flex align-items-center searchItem"
                            onClick={() => {
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              });
                              //window.location.reload();
                            }}
                          >
                            <img
                              style={{
                                borderRadius: 5,
                                margin: 5,
                                objectFit: "cover",
                              }}
                              src={item.cover_picture}
                              height={"40px"}
                              width={"40px"}
                            />
                            <div className="ms-2">
                              {item.title}
                              <div className="d-flex gameInfo">
                                <small>{item?.category_names}</small>&nbsp; |
                                &nbsp;
                                <small>{item?.technology_names}</small>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="row gx-2">
                {/* <div className="catgoryTitle mb-3">{location?.state?.name}</div> */}

                <div className="p-0 mh-100">
                  <div className="row gx-2">
                    <div className="col-12 col-sm-4  mb-3 mb-sm-0 col-md-4 col-xxl-3 col-xl-3 col-lg-3">
                      <div className="dark-bg h-100">
                        <div className="text-white text-center leaderboardTitle mb-3">
                          Profile{" "}
                        </div>
                        <div className="text-center ">
                          {
                            <div className="position-relative profileAvatar">
                              <Avatar
                                alt=""
                                size="xl"
                                circle
                                src={
                                  fileInfo ||
                                  (filteredAvatars.length > 0 &&
                                    require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                                }
                              />
                              <div className="editIcon d-flex">
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    //   modal.hide();
                                    NiceModal.show(Update_Profile_Modal, {
                                      navigate,
                                      avatar_id: profile_details?.avatar_id,
                                      gender_update: profile_details?.gender,
                                      date_of_birth:
                                        profile_details?.date_of_birth,
                                      full_name: profile_details?.full_name,
                                      profile_picture:
                                        profile_details?.profile_picture,
                                    });
                                  }}
                                  src={require("../assets/edit.png")}
                                  alt=""
                                />
                              </div>
                            </div>
                          }
                        </div>

                        <div className="profileProgress mt-2">
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-white m-0 d-flex justify-content-between align-items-center"
                          >
                            {`Your profile is ${percent}% complete`}
                            <span
                              onClick={() => {
                                // if (percent !== 100) {
                                //   modal.hide();
                                NiceModal.show(Update_Profile_Modal, {
                                  navigate,
                                  avatar_id: profile_details?.avatar_id,
                                  gender_update: profile_details?.gender,
                                  date_of_birth: profile_details?.date_of_birth,
                                  full_name: profile_details?.full_name,
                                  profile_picture:
                                    profile_details?.profile_picture,
                                });
                                //}
                              }}
                              style={{
                                color: "#F2641F",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {profile_details?.profile_completion_percentage ==
                              100
                                ? `edit profile`
                                : `complete now`}
                              <img
                                className="ms-1"
                                src={require("../assets/right-arrow.png")}
                                style={{ width: "5px" }}
                                alt=""
                              />
                            </span>
                          </p>
                          <Progress.Line
                            className="text-white p-0 mt-2"
                            percent={
                              profile_details?.profile_completion_percentage
                            }
                            status={
                              profile_details?.profile_completion_percentage ==
                              100
                                ? "success"
                                : "fail"
                            }
                            strokeColor={
                              profile_details?.profile_completion_percentage ==
                              100
                                ? "green"
                                : "#F2641F"
                            }
                            strokeWidth={5}
                            trailColor={"#212530"}
                            showInfo={false}
                          />
                        </div>

                        <div className="profileDetails">
                          <div>
                            <p className="detailTitle">User Name</p>
                            <p className="text-white mb-2">
                              {profile_details?.full_name}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <p className="detailTitle">Phone Number</p>
                              <p className="text-white mb-2">{`${profile_details?.country_code} ${phone_number}`}</p>
                            </div>
                            <div className="col-12 col-sm-12">
                              <p className="detailTitle">Country</p>
                              <p className="text-white mb-2">
                                {profile_details?.country}
                                <Flag
                                  height={"20px"}
                                  width={"20px"}
                                  className="ms-2"
                                  code={profile_details?.country_code_alias}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <p className="detailTitle">Member Since</p>
                              <p className="text-white mb-2">
                                {daysDifference == 0 ? "Today" : daysDifference}{" "}
                                <span>
                                  {daysDifference == 0
                                    ? ""
                                    : daysDifference > 1
                                    ? "Days"
                                    : "Day"}
                                </span>
                              </p>
                            </div>
                            <div className="col-12 col-sm-6">
                              <p className="detailTitle">Your Score</p>
                              <p className="text-white d-flex align-items-center mb-2">
                                <img
                                  className="me-1"
                                  src={require("../assets/star.png")}
                                  style={{ width: "15px" }}
                                  alt=""
                                />
                                {profile_details?.equivalent_point}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            appearance="primary"
                            onClick={() => {
                              navigate("/playing_history", {
                                state: {
                                  history: play_history,
                                },
                              });
                              //   modal.hide();
                            }}
                            style={{
                              borderRadius: 20,
                              background: "#F2641F",
                              padding: "10px 25px",
                              color: "#fff",
                            }}
                            className="me-lg-3 mb-lg-0 me-3 mb-sm-0 "
                          >
                            Game History
                          </Button>
                          <Button
                            onClick={log_out}
                            appearance="default"
                            style={{
                              borderRadius: 20,
                            }}
                            className="guest-btn "
                          >
                            Logout
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-xxl-9 col-xl-9 col-lg-9">
                      <div className="dark-bg h-100 p-3">
                        <div className="text-white text-start leaderboardTitle">
                          My Favourite Games
                        </div>
                        <div
                          className="row gx-2 mt-3 scrollBox"
                          style={{ paddingTop: "5px" }}
                        >
                          {fav_list.map((item, index) => {
                            return (
                              <>
                                <div className="col-6 col-md-3 position-relative">
                                  <div className="gameItem">
                                    <HoverVideoPlayer
                                      onClick={() => {
                                        //   modal.hide();
                                        navigate(
                                          `/game/${item?.id}/${item?.title}`,
                                          {
                                            state: {
                                              datas: item,
                                              flag: "home",
                                            },
                                          }
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="connect_the_ball_ek11 gameThumb"
                                      restartOnPaused
                                      overlayTransitionDuration={250}
                                      videoSrc={
                                        item?.video_url || item?.video_upload
                                      }
                                      pausedOverlay={
                                        <img
                                          src={item?.cover_picture}
                                          alt=""
                                          className="connect_the_ball_ek11 gameThumb"
                                        />
                                      }
                                      loadingOverlay={
                                        <div className="loading-overlay">
                                          <div className="loading-spinner" />
                                        </div>
                                      }
                                    />

                                    <div className="hoverText">
                                      {item?.title?.replace(/-/g, " ")}
                                    </div>
                                    <div
                                      className="deleteGame"
                                      onClick={async () => {
                                        const options = {
                                          okButtonText: "Yes",
                                          cancelButtonText: "No",
                                        };
                                        const result = await confirm(
                                          "Do you want to remove this game from your favorite game list ?",
                                          options
                                        );

                                        if (result) {
                                          fav_status(item?.id, 0);
                                        }
                                      }}
                                    >
                                      <img
                                        src={require("../assets/close.png")}
                                        style={{ width: 15 }}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  {item.tag == "new" && (
                                    <div className="new-badge">New</div>
                                  )}
                                  {item.last_updated && (
                                    <div className="update-badge">Updated</div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {fav_list.length == 0 && (
                          <div className="row">
                            <div className="col">
                              <div className="empty_list">
                                <img src={require("../assets/empty.png")} />
                                <p className="text-center ">
                                  Opps! There is no games in your Favourites
                                  List. Start playing.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112"></div>
      </div>
    </div>
  );
};

export default Profile;
