import React, { useState, useEffect, useRef } from "react";
// import "./Game.css";
import HoverVideoPlayer from "react-hover-video-player";
import { useLocation, useNavigate } from "react-router-dom";
import Iframe from "react-iframe";
import moment from "moment";
import Service from "../service/apis";
import { Input, InputGroup, MaskedInput } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Logo from "../assets/Playzhub.png";
import star from "../assets/star.png";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Scrollbar, Navigation } from "swiper/modules";
import { InputPicker, Avatar } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Modal, Drawer, Button, HStack, AvatarGroup, Progress } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alert, confirm, prompt } from "@rsuite/interactions";
import DOMPurify from "dompurify";
import {
  Divider,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  Tooltip,
  Whisper,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import PhoneInput from "react-phone-input-2";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import axios from "axios";
import OtpInput from "react-otp-input";
import Avatar_data from "../avatars.json";
import { TiTick } from "react-icons/ti";
import { FaCommentsDollar, FaHeart } from "react-icons/fa";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Flag from "react-world-flags";
import useStore from "../service/zustand";
import Countdown, { zeroPad } from "react-countdown";
import Header from "../Header";
import Nostr from "../assets/nostr.png";
import Custom_ads from "../Custom_ads";
import calculateAspectRatio from "calculate-aspect-ratio";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";
import NavigationBar from "../NavigationBar";
import { useSelector, useDispatch } from "react-redux";
import {
  UNAME,
  DOB,
  GENDER,
  PROFILE_IMG,
  AVATAR_ID,
  LIKED,
  DISLIKED,
  GUEST_TOKEN,
  LOGIN_TOKEN,
  EXPIRE_TIME,
  PHONE_NUMBER,
  GAME_STATE,
  GAME_DATA,
} from "../store/Types";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from "react-share";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/Animation_Button.json";
import PngSequenceAnimation from "../PngSequenceAnimation";
import { useFullscreen, useToggle } from "react-use";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Helmet } from "react-helmet";

let BASE_URL = process.env.REACT_APP_BASE_URL_PROD;

const Profile_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    let name_store = useSelector((state) => state.Uname);
    let date_store = useSelector((state) => state.dateOfBirth);
    let gender_store = useSelector((state) => state.gender);
    let avatar_store = useSelector((state) => state.avatar_id);
    let profile_img = useSelector((state) => state.profile_img);
    const { profile_status_load, add_profile_load, add_dislike_load } =
      useStore();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(profile_img);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(avatar_store);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);
    const [isProfileDelete, setIsProfileDelete] = useState(0);
    const [cameraFile, setCameraFile] = useState(null);
    const [cameraPreview, setCameraPreview] = useState(null);

    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    // Function to handle avatar selection
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
      setFileInfo(null);
      setFileList(null);
      setIsProfileDelete(1);
      setCameraFile(null);
      setCameraPreview(null);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      setLoading(true);
      if (!name_store) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);

      let form = new FormData();
      form.append("full_name", name_store);
      form.append("profile_picture", fileList);
      form.append(
        "date_of_birth",
        date_store ? moment(date_store).format("DD-MM-YYYY") : "" || ""
      );
      form.append("gender", gender_store || selectedGender || "");
      form.append("avatar_id", selectedAvatar);
      form.append("isProfileDelete", isProfileDelete);
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              modal.hide();
              add_profile_load(true);
              // window.location.reload(true);
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    useEffect(() => {
      setName(full_name);
    }, []);

    const genders = [
      "Male",
      "Female",
      "Non-Binary",
      "Other",
      "Prefer not to say",
    ];

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState("");

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      console.log(gender);
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
      if (file) {
        setFileList(file);
        setFileInfo(preview);
        // setFileInfo(null);
        setSelectedAvatar(12);
      }
    }, [file]);

    return (
      <Modal
        backdrop="static"
        overflow={false}
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            {/* <i class="fa-solid fa-xmark" style={{ cursor: "pointer" }}></i> */}
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap  pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
                overflow: "visible",
              }}
            >
              {!cameraPreview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      navigate,
                      full_name,
                      file,
                      preview,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <>
                  {/* <img
                    style={{ borderRadius: 35, width: 40, height: 40 }}
                    onClick={() => {
                      NiceModal.show(Camera_Modal, {
                        full_name,
                        file,
                        preview,
                      });
                      modal.hide();
                    }}
                    src={cameraPreview}
                    className="after-ring"
                    width="100%"
                    height="100%"
                  /> */}
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      top: "30px",
                      left: "35px",
                      width: "13px",
                      height: "13px",
                      borderRadius: "3px",
                      background: cameraFile ? "#2ED573" : "",
                      position: "absolute",
                    }}
                  >
                    {cameraFile && (
                      <TiTick
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      />
                    )}
                  </div>
                </>
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => {
                  setFileList(event[0].blobFile);
                  setIsProfileDelete(0);
                }}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {fileInfo ? (
                    <>
                      <img
                        className="after-ring"
                        src={fileInfo}
                        width="100%"
                        height="100%"
                      />
                      <div
                        className="d-flex align-items-center justify-content-center after_ring1"
                        style={{
                          bottom: "11px",
                          right: "0",
                          width: "13px",
                          height: "13px",
                          borderRadius: "3px",
                          background: fileInfo ? "#2ED573" : "",
                          position: "absolute",
                        }}
                      >
                        {fileInfo && (
                          <TiTick
                            style={{ fontSize: "10px" }}
                            className="text-white"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <img
                      height={"15px"}
                      width={"15px"}
                      src={require("../assets/picked.png")}
                      style={{ fontSize: 15, color: "#fff" }}
                    />
                  )}
                </button>
              </Uploader>
              <span className="fromGallery">
                <img
                  height={"15px"}
                  width={"15px"}
                  src={require("../assets/picked.png")}
                  style={{ fontSize: 15, color: "#fff" }}
                />
              </span>
            </div>
            {Avatar_data.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={
                      fileInfo
                        ? false
                        : selectedAvatar === item?.avatar_id
                        ? true
                        : false
                    }
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: !fileInfo ? "#2ED573" : "",
                        position: "absolute",
                      }}
                    >
                      {!fileInfo && (
                        <TiTick
                          style={{ fontSize: "10px" }}
                          className="text-white"
                        />
                      )}
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div
            className="editField"
            style={{ marginTop: 8, cursor: "pointer" }}
            onClick={() => {
              NiceModal.show(Username_Modal, {
                navigate,
                full_name: name_store,
              });
            }}
          >
            <label className="text-white" style={{ cursor: "pointer" }}>
              Username*
            </label>
            <Input
              // disabled={name_status ? true : false}
              value={name_store}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none", cursor: "pointer" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Username_Modal, {
                  navigate,
                  full_name: name_store,
                });
              }}
            >
              Edit
            </span>
          </div>
          <div className="editField" style={{ marginTop: 8 }}>
            <label className="text-white d-block">
              Date of birth
              {/* <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="click"
                speaker={tooltip}
              >
                <img
                  className="ms-2"
                  src={require("../src/assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper> */}
            </label>
            {/* <DatePicker
            placeholder={"Not specified"}
            disabled={dob_status ? true : false}
            oneTap
            placement="top"
            className="w-100"
            onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
          /> */}
            <Input
              disabled={true}
              value={date_store && moment(date_store).format("DD-MM-YYYY")}
              placeholder="Not Specified"
              style={{ background: "none" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Dob_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 8 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Gender</label>
            <input
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender || gender_store}
            />

            <div className="custom-select-container">
              {isOpen && (
                <>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                onClick={() => {
                  onContactFromSubmit();
                  // modal.hide();
                }}
                className="guest-btn w-auto"
                style={{ borderRadius: 20, padding: "10px 25px" }}
                block
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
                block
              >
                Save & Continue
              </Button>
            )}
          </div>
          <p
            className="text-white text-center m-0 mt-3 login_para"
            style={{ fontSize: "12px" }}
          >
            Note: If DOB is not declared then you are agreeing to the {""}
            <br />
            <span
              onClick={() => {
                navigate("/terms_and_conditions");
                modal.hide();
              }}
              style={{ cursor: "pointer", color: "#F2641F" }}
            >
              Terms & Conditions
            </span>{" "}
            that you are 13+ years of age. To get the advantages and special
            privilages of partcipating in the Weekly Leaderboard Challenges, you
            need to Login.
          </p>
        </Modal.Body>
      </Modal>
    );
  }
);

const Username_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    const dispatch = useDispatch();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);
    let [msg, setMsg] = useState("");
    let [err_msg, setErr_msg] = useState("");

    const verify_username = (e) => {
      //console.log(e.length);
      setName(e);
      if (e.length >= 3) {
        setName(e);
        let params = {
          user_name: e,
        };
        Service.verify_username(params)
          .then((res) => {
            // console.log(res);
            if (res?.status == 0) {
              setErr_msg("Username available.");
              setMsg("");
            } else if (res?.status == 1) {
              setMsg("Username already exists.");
              setErr_msg("");
            }
          })
          .catch((e) => console.log(e));
      } else if (e.length == 0) {
        setMsg("");
        setErr_msg("");
      }
    };

    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Profile_Modal, { navigate });
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
            ></i>
            Update Username
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="brthdy_sec"
          style={{ maxHeight: "480px !important" }}
        >
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Username</label>
                <Input
                  defaultValue={full_name}
                  // value={name}
                  placeholder="Fill username"
                  onChange={verify_username}
                  style={{ background: "none" }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    color: !msg ? "#118B50" : "#FA4032",
                  }}
                >
                  {msg || err_msg}
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              disabled={name.length == 0 ? true : false}
              className={name.length == 0 && "guest-btn"}
              onClick={() => {
                NiceModal.show(Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                dispatch({
                  type: UNAME,
                  payload: name,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: name.length !== 0 && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Dob_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    const dispatch = useDispatch();
    let date_store = useSelector((state) => state.dateOfBirth);
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);

    const disableDate = (date) => {
      const today = new Date();
      return date > today;
    };
    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Profile_Modal, { navigate });
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
            ></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  editable={false}
                  shouldDisableDate={disableDate}
                  format="dd-MM-yyyy"
                  // value={dob}
                  defaultValue={date_store && new Date(date_store)}
                  placeholder={"dd/mm/yyyy"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100"
                  onChange={(e) => {
                    setDob(moment(e).toISOString());
                  }}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none" }}
                >
                  <img
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                dispatch({
                  type: DOB,
                  payload: dob,
                });
                NiceModal.show(Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Phone_Modal = NiceModal.create(({ navigate }) => {
  const modal = useModal();
  let cookies = new Cookies();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let validate_users = useSelector((state) => state.guest_token);
  let IP_URL = process.env.REACT_APP_IP_URL;
  const { profile_status_load, add_profile_load, add_guest_token } = useStore();
  let [offline_data, setOffline_data] = useState({
    ip: "122.176.24.171",
    network: "122.176.24.0/24",
    version: "IPv4",
    city: "Kolkata",
    region: "West Bengal",
    region_code: "WB",
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso3: "IND",
    country_capital: "New Delhi",
    country_tld: ".in",
    continent_code: "AS",
    in_eu: false,
    postal: "700054",
    latitude: 22.518,
    longitude: 88.3832,
    timezone: "Asia/Kolkata",
    utc_offset: "+0530",
    country_calling_code: "+91",
    currency: "INR",
    currency_name: "Rupee",
    languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    country_area: 3287590,
    country_population: 1352617328,
    asn: "AS24560",
    org: "Bharti Airtel Ltd., Telemedia Services",
  });

  const guest_user = () => {
    if (!validate_users) {
      fetch_user_details();
    }
    ///window.location.reload(true);
  };
  const fetch_user_details = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        console.log({ res });
        cookies.set("network_details", res.data, { path: "/" });
        let data = res.data;
        validate_user(data);
      })
      .catch((e) => {
        console.log(e.config.data);
        if (e.config.data === undefined) {
          validate_user(offline_data);
        }
      });
  };

  const validate_user = (data) => {
    let params = {
      ip_address: data.ip,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
      additional_details: JSON.stringify(data),
    };
    Service.login_register_visitor(params)
      .then((res) => {
        // console.log(res);
        logEvent(analytics, "guest_user_count", {
          country: data?.country_name,
          city: data?.city,
        });
        cookies.set("token", res.token, { path: "/" });
        window.localStorage.setItem("guest_token", res?.token);
        dispatch({
          type: GUEST_TOKEN,
          payload: res?.token,
        });
        add_guest_token(res.token);
        // window.location.reload(true);
      })
      .catch((e) => console.log(e.message));
  };
  const fetch_user_network = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        cookies.set("network_details", res.data, { path: "/" });
      })
      .catch((e) => {
        // console.log(e.config.data)
        if (e.config.data === undefined) {
          // validate_user(offline_data)
        }
      });
  };
  const handleInputChange = (value, data) => {
    let code = `+${data.dialCode}`;
    setDial(code);
    let number = value.toString().slice(data.dialCode.length);
    setPhone(number);
  };
  let network = cookies.get("network_details");

  const onContinue = async (e) => {
    // e.preventDefault();
    setLoading(true);
    let params = {
      phone_number: phone,
      country_code: dial,
    };
    await Service.login_number(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 0) {
          setLoading(false);
        }
        if (res.status == 1) {
          if (!network) {
            fetch_user_network();
          }
          setLoading(false);
          modal.hide();
          dispatch({
            type: EXPIRE_TIME,
            payload: res?.otp_expires,
          });
          NiceModal.show(Otp_Modal, {
            navigate,
            number: phone,
            otp_code: res?.otp,
            dial_code: dial,
            expire: res?.otp_expires,
          });
        }
      })
      .catch((e) => console.log(e.message));
  };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      onContinue(e);
    }
  };
  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-4 fw-normal">Log In</h4>

          <p className="mb-0" style={{ fontSize: "16px" }}>
            Welcome to Playzhub,
          </p>
          <p className="mb-1" style={{ fontSize: "16px" }}>
            Unleash the Fun, Win Big!
          </p>
          <i
            onClick={() => {
              guest_user();
              modal.hide();
            }}
            class="fa-solid fa-xmark"
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <PhoneInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onContinue();
            }
          }}
          className="phone"
          copyNumbersOnly={true}
          //autoFormat={false}
          prefix="+"
          countryCodeEditable={false}
          placeholder="Fill mobile number"
          country={"in"}
          //  value={phone}
          onChange={(value, data, event, formattedValue) =>
            handleInputChange(value, data)
          }
        />
        <Button
          // onKeyDown={handleKeyDown}
          className="my-4"
          loading={loading}
          onClick={() => {
            onContinue();
          }}
          disabled={phone.length >= 8 ? false : true}
          appearance="primary"
          style={{
            width: "40%",
            marginTop: 10,
            borderRadius: 20,
            background: phone.length >= 8 ? "#F2641F" : "#818A97",
            padding: "10px 22px",
          }}
          block
        >
          Login
        </Button>
        <p
          className="text-secondary"
          style={{ justifyItems: "flex-end", flex: 1 }}
        >
          OR
        </p>
        <div style={{ marginTop: 10 }}></div>
        <Button
          className="guest-btn"
          onClick={() => {
            guest_user();
            modal.hide();
            // window.location.reload(true);
          }}
          // appearance="ghost"
          style={{ marginTop: 10, borderRadius: 20, width: "auto" }}
          block
        >
          Continue as Guest
        </Button>
        <hr />
        <p
          className="text-white text-center m-0 login_para"
          style={{ fontSize: "12px" }}
        >
          Note: As a guest you are agreeing to the {""}
          <span
            onClick={() => {
              navigate("/terms_and_conditions");
              modal.hide();
            }}
            style={{ cursor: "pointer", color: "#F2641F" }}
          >
            Terms & Conditions
          </span>{" "}
          that you are 13+ years of age. To get the advantages and special
          privilages of partcipating in the Weekly Leaderboard Challenges, you
          need to Login.
        </p>
      </Modal.Body>
    </Modal>
  );
});

const Otp_Modal = NiceModal.create(
  ({ navigate, number, otp_code, dial_code, expire }) => {
    const modal = useModal();
    let cookies = new Cookies();
    const expire_times = useSelector((state) => state.expire_time);
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    const [dial, setDial] = useState("");
    const [show, setShow] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [expire_time, setExpire_time] = useState("");
    let [msg, setMsg] = useState("");
    const { profile_status_load, add_profile_load } = useStore();
    let network = cookies.get("network_details");

    const verifyCode = async () => {
      setLoading(true);
      let params = {
        phone_number: number,
        otp: otp,
        ip_address: network?.ip,
        country: network?.country_name,
        country_code: dial_code,
        city: network?.city,
        latitude: network?.latitude,
        longitude: network?.longitude,
      };
      await Service.verify_otp(params)
        .then((res) => {
          // console.log(res?.data);
          if (res.status == 0) {
            setMsg(res?.message);
            setLoading(false);
          }
          if (res.status == 1) {
            // if (!res?.data?.visitorDetails?.full_name) {
            if (res?.data?.profile_completion_status === false) {
              dispatch({
                type: PHONE_NUMBER,
                payload: res?.data?.visitorDetails?.phone_number,
              });
              dispatch({
                type: UNAME,
                payload: res?.data?.visitorDetails?.full_name,
              });
              dispatch({
                type: DOB,
                payload: res?.data?.visitorDetails?.date_of_birth,
              });
              dispatch({
                type: GENDER,
                payload: res?.data?.visitorDetails?.gender,
              });
              dispatch({
                type: AVATAR_ID,
                payload:
                  res?.data?.visitorDetails?.avatar_id == null
                    ? 1
                    : res?.data?.visitorDetails?.avatar_id,
              });
              dispatch({
                type: PROFILE_IMG,
                payload: res?.data?.visitorDetails?.profile_picture,
              });
              logEvent(analytics, "registered_user_count", {
                country: network?.country_name,
                city: network?.city,
              });
              cookies.set("login_token", res.token, { path: "/" });
              window.localStorage.setItem("login_token", res?.token);
              dispatch({
                type: LOGIN_TOKEN,
                payload: res?.token,
              });
              modal.hide();
              NiceModal.show(Profile_Modal, {
                navigate,
                full_name: res?.data?.visitorDetails?.full_name,
              });

              dispatch({
                type: UNAME,
                payload: res?.data?.visitorDetails?.full_name,
              });

              setLoading(false);
            }
            add_profile_load(true);
            dispatch({
              type: PHONE_NUMBER,
              payload: res?.data?.visitorDetails?.phone_number,
            });
            cookies.set("login_token", res.token, { path: "/" });
            window.localStorage.setItem("login_token", res?.token);
            dispatch({
              type: LOGIN_TOKEN,
              payload: res?.token,
            });
            modal.hide();
            setMsg("");
            ///window.location.reload();
            //NiceModal.show(Profile_Modal, { id: 'game_id', })
            setLoading(false);
          }
        })
        .catch((e) => console.log(e.message));
    };

    const maskPhoneNumber = (number) => {
      const countryCode = number.slice(0, 4); // +91 and space
      const firstTwoDigits = number.slice(0, 2); // First two digits after country code
      const lastThreeDigits = number.slice(-2); // Last three digits
      const maskedMiddle = "*".repeat(6); // Replace 5 middle digits with *

      return `${firstTwoDigits}${maskedMiddle}${lastThreeDigits}`;
    };

    const onContinue = async () => {
      // setLoading(true);
      let params = {
        phone_number: number,
        country_code: dial_code,
      };
      await Service.login_number(params)
        .then((res) => {
          //console.log(res);
          if (res.status == 0) {
            //setLoading(false);
          }
          if (res.status == 1) {
            restartCountdown();
            setCode(res?.otp);
            dispatch({
              type: EXPIRE_TIME,
              payload: res?.otp_expires,
            });
            setExpire_time(res?.otp_expires);
          }
        })
        .catch((e) => console.log(e.message));
    };
    const [key, setKey] = useState(0); // Key to reset the countdown

    // Function to handle restart of the countdown
    const restartCountdown = () => {
      setKey((prev) => prev + 1); // Changing key will re-render the Countdown component
    };
    const Resend = () => (
      <div
        className="text-end text-capitalize"
        style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
      >
        <span
          style={{
            cursor: "pointer",
            color: "#F2641F",
            textDecorationLine: "underline",
          }}
          onClick={() => {
            onContinue();
          }}
        >
          Resend
        </span>
      </div>
    );

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed, started }) => {
      if (started) {
        console.log("function start");
      }

      if (completed) {
        // Render a completed state
        // restartCountdown();
        return <Resend />;
      } else {
        // Render a countdown
        return (
          <div
            className="text-center"
            style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
          >
            <span className="text-white">
              Not received the code?{" "}
              <span style={{ color: "#F2641F" }}>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
            </span>
          </div>
        );
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        console.log("entr");
        verifyCode();
      }
    };

    const maskedNumber = maskPhoneNumber(number);

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Phone_Modal, { navigate });
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
            <h4 className="mb-4 fw-normal">Verify</h4>
            <p
              className="mb-0"
              style={{ fontSize: "16px", whiteSpace: "normal" }}
            >
              {/* {`Please enter your verification code sent to ${dial_code}${number}`} */}
              Please enter your verification code sent to
              <br />
              <span>{`${dial_code} ${maskedNumber}`}</span>
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p style={{ color: "red" }}>{msg}</p>
          <OtpInput
            inputType="tel"
            value={otp}
            onChange={setOtp}
            numInputs={4}
            containerStyle={{
              margin: "5px",
              justifyContent: "center",
            }}
            inputStyle={{
              height: "40px",
              width: "40px",
              margin: "5px",
              background: "#333947",
              color: "#F5F9FF",
              borderRadius: 5,
              border: "none",
            }}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                autoComplete="one-time-code"
                {...props}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
          />

          <Countdown
            onStart={(e) => {
              console.log("Started", e);
            }}
            onMount={(e) => console.log("Mounted", e)}
            onComplete={(e) => {
              console.log("Completed", e);
            }}
            key={key}
            autoStart={true}
            // intervalDelay={0}
            // precision={1}
            // date={Date.now() + 10000}
            date={new Date(expire_times)}
            renderer={renderer}
          >
            <Resend />
          </Countdown>
          <p className="text-white m-0">{code || otp_code}</p>
          <Button
            disabled={otp.length == 4 ? false : true}
            className="text-white"
            loading={loading}
            onClick={() => {
              verifyCode();
              // modal.hide()
              // NiceModal.show(Profile_Modal)
            }}
            //#818A97
            appearance="primary"
            style={{
              marginTop: 25,
              borderRadius: 20,
              padding: "10px 40px",
              background: otp.length == 4 ? "#F2641F" : "#818A97",
              color: otp.length == 4 ? "#fff" : "#ffffff47",
            }}
          >
            Verify
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
);

const Profile_edit_Modal = NiceModal.create(({ navigate, select_dob }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  let network_details = cookies.get("network_details");
  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [err, setErr] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [country_alias, setCountry_alias] = useState("");
  const [score, setScore] = useState("");
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [date_of_birth, setDate_of_birth] = useState("");
  const [fav_list, setFav_list] = useState([]);
  const [play_history, setPlay_history] = useState([]);
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    ///visitor_details();
    fav_games();
  }, []);

  const log_out = () => {
    cookies.remove("token", { path: "/" });
    cookies.remove("network_details", { path: "/" });
    cookies.remove("login_token", { path: "/" });
    navigate("/");
    window.location.reload(true);
  };
  const tooltip = (
    <Tooltip>
      This is a help <i>tooltip</i> .
    </Tooltip>
  );
  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        let date_of_birth = res?.data?.date_of_birth;

        if (res.status == 1) {
          setName(res?.data?.full_name);
          setDob(date);
          setDate_of_birth(date_of_birth);
          setGender(res?.data?.gender);
          setCountry(res?.data?.country);
          setScore(res?.data?.equivalent_point);
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setPlay_history(res?.data?.playing_history);
          setPhone(res?.data?.phone_number);
          setCountry_code(res?.data?.country_code);
          setCountry_alias(res?.data?.country_code_alias);
        }
      })
      .catch((e) => console.log(e));
  };

  const onContactFromSubmit = async () => {
    setLoading(true);
    if (!name) {
      setErr(true);
      setLoading(false);
      return;
    }
    setErr(false);
    let form = new FormData();
    form.append("full_name", name);
    form.append("profile_picture", fileList);
    form.append("date_of_birth", moment(dob).format("YYYY-MM-DD"));
    form.append("gender", gender);
    try {
      await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
        method: "POST",
        body: form,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status == 0) {
            setLoading(false);
          }
          if (data.status == 1) {
            setLoading(false);
            modal.hide();
          }
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const fav_games = () => {
    Service.fav_games()
      .then((res) => {
        setFav_list(res?.data);
      })
      .catch((e) => console.log);
  };

  const fav_status = (id, status) => {
    let params = {
      game_id: id,
      status: status,
    };
    Service.fav_games_status(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 1) {
          fav_games();
        }
      })
      .catch((e) => console.log(e));
  };

  const targetDate = new Date(dob);
  const currentDate = new Date();
  const timeDifference = currentDate - targetDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );

  return (
    <>
      <Modal
        onBackdropClick={() => modal.hide()}
        // dialogStyle={{
        //   width: "100%",
        //   // marginLeft: "-50%",
        //   height: "100%",
        //   marginTop: "5%",
        // }}
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        className="profileModal"
      >
        {/* <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Profile{" "}
          </Modal.Title>
        </Modal.Header> */}

        <Modal.Body className="p-0 mh-100">
          <div className="row gx-2">
            <div className="col-12 col-sm-3  mb-3 mb-lg-0">
              <div className="dark-bg h-100">
                <Modal.Title className="text-white text-center mb-4">
                  Profile{" "}
                </Modal.Title>
                <div className="text-center ">
                  {
                    <div className="position-relative profileAvatar">
                      <Avatar
                        alt=""
                        size="xl"
                        circle
                        src={
                          fileInfo ||
                          (filteredAvatars.length > 0 &&
                            require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                        }
                      />
                      <div className="editIcon d-flex">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            modal.hide();
                            NiceModal.show(Update_Profile_Modal, {
                              avatar_id: avatar_id,
                              gender_update: gender,
                              date_of_birth: date_of_birth,
                              full_name: name,
                              profile_picture: fileInfo,
                            });
                          }}
                          src={require("../assets/edit.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  }
                </div>

                <div className="profileProgress mt-2">
                  <p
                    style={{ fontSize: "12px" }}
                    className="text-white m-0 d-flex justify-content-between align-items-center"
                  >
                    {`Your Profile is ${percent}% complete`}
                    <span
                      onClick={() => {
                        // if (percent !== 100) {
                        modal.hide();
                        NiceModal.show(Update_Profile_Modal, {
                          avatar_id: avatar_id,
                          gender_update: gender,
                          date_of_birth: date_of_birth,
                          full_name: name,
                          profile_picture: fileInfo,
                          select_dob,
                        });
                        //}
                      }}
                      style={{
                        color: "#F2641F",
                        cursor: "pointer",
                      }}
                    >
                      {percent == 100 ? `edit profile` : `complete now`}
                      <img
                        className="ms-1"
                        src={require("../assets/right-arrow.png")}
                        style={{ width: "5px" }}
                        alt=""
                      />
                    </span>
                  </p>
                  <Progress.Line
                    className="text-white p-0 mt-2"
                    percent={percent}
                    status={percent == 100 && "success"}
                    strokeColor={percent == 100 ? "green" : "#F2641F"}
                    strokeWidth={5}
                    trailColor={"#212530"}
                    showInfo={false}
                  />
                </div>

                <div className="profileDetails">
                  <div>
                    <p className="detailTitle">Your Score</p>
                    <p className="text-white d-flex align-items-center">
                      <img
                        className="me-1"
                        src={require("../assets/star.png")}
                        style={{ width: "15px" }}
                        alt=""
                      />
                      {score}
                    </p>
                  </div>
                  <div>
                    <p className="detailTitle">User Name</p>
                    <p className="text-white">{name}</p>
                  </div>
                  <div>
                    <p className="detailTitle">Country</p>
                    <p className="text-white">
                      {country}
                      <Flag
                        height={"20px"}
                        width={"20px"}
                        className="ms-2"
                        code={country_alias}
                      />
                    </p>
                  </div>
                  <div>
                    <p className="detailTitle">Phone Number</p>
                    <p className="text-white">{`${country_code} ${phone}`}</p>
                  </div>
                  <div>
                    <p className="detailTitle">Member Since</p>
                    <p className="text-white">
                      {daysDifference == 0 ? "Today" : daysDifference}{" "}
                      <span>
                        {daysDifference == 0
                          ? ""
                          : daysDifference > 1
                          ? "Days"
                          : "Day"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      navigate("/playing_history", {
                        state: {
                          history: play_history,
                        },
                      });
                      modal.hide();
                    }}
                    style={{
                      borderRadius: 20,
                      background: "#F2641F",
                      padding: "10px 25px",
                      color: "#fff",
                    }}
                    className="me-3"
                  >
                    Game History
                  </Button>
                  <Button
                    onClick={log_out}
                    appearance="primary"
                    style={{
                      borderRadius: 20,
                    }}
                    className="guest-btn "
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="dark-bg h-100 p-3">
                <Modal.Title className="text-white">
                  <i
                    class="fa-solid fa-xmark"
                    style={{ cursor: "pointer" }}
                  ></i>
                  My Favourite Games
                </Modal.Title>
                <div className="row gx-2 mt-3 scrollBox">
                  {fav_list.map((item, index) => {
                    return (
                      <>
                        <div className="col-6 col-md-3 position-relative">
                          <div className="gameItem">
                            <HoverVideoPlayer
                              onClick={() => {
                                modal.hide();
                                navigate(`/game/${item?.id}/${item?.title}`, {
                                  state: {
                                    datas: item,
                                    flag: "home",
                                  },
                                });
                              }}
                              style={{ cursor: "pointer" }}
                              className="connect_the_ball_ek11 gameThumb"
                              restartOnPaused
                              overlayTransitionDuration={250}
                              videoSrc={item?.video_url || item?.video_upload}
                              pausedOverlay={
                                <img
                                  src={item?.cover_picture}
                                  alt=""
                                  className="connect_the_ball_ek11 gameThumb"
                                />
                              }
                              loadingOverlay={
                                <div className="loading-overlay">
                                  <div className="loading-spinner" />
                                </div>
                              }
                            />

                            <div className="hoverText">{item?.title}</div>
                            <div className="deleteGame">
                              <img
                                onClick={async () => {
                                  const options = {
                                    okButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                  };
                                  const result = await confirm(
                                    "Are you sure? You want to remove this game from your favourite games list",
                                    options
                                  );

                                  if (result) {
                                    fav_status(item?.id, 0);
                                  }
                                }}
                                src={require("../assets/close.png")}
                                style={{ width: 15 }}
                                alt=""
                              />
                            </div>
                          </div>
                          {item.tag == "new" && (
                            <div className="new-badge">New</div>
                          )}
                          {item.last_updated && (
                            <div className="update-badge">Updated</div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                {fav_list.length == 0 && (
                  <div className="row">
                    <div className="col">
                      <h5 className="text-center text-white">
                        Sorry! Thre is no games in your Favourites List. Start
                        playing.
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

const Update_Profile_Modal = NiceModal.create(
  ({
    avatar_id,
    full_name,
    gender_update,
    date_of_birth,
    profile_picture,
    file,
    preview,
    select_dob,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(profile_picture);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState(full_name);
    const [gender, setGender] = useState(gender_update);
    const [dob, setDob] = useState(date_of_birth);
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(avatar_id);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);
    //const previewUrl = URL.createObjectURL(file);
    // console.log(previewUrl);
    // Function to handle avatar selection
    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
      setFileInfo(null);
      setFileList(null);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      setLoading(true);
      if (!name) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);
      let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
      let form = new FormData();
      form.append("full_name", name);
      form.append(
        "profile_picture",
        (selectedAvatar < 10 && "") || file || fileList
      );
      form.append(
        "date_of_birth",
        moment(dob).format("YYYY-MM-DD") || select_dob || ""
      );
      form.append("gender", selectedGender || "");
      form.append("avatar_id", selectedAvatar || "");
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log("Success thread Contact===========>:", data);
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              //    console.log(
              //   "Success thread Contact===========>:",
              //   data
              // );
              modal.hide();
              window.location.reload();
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    const generateRandomUsername = () => {
      const adjectives = [
        "Swift",
        "Mighty",
        "Epic",
        "Mystic",
        "Viking",
        "Fearless",
        "Shadow",
        "Blazing",
      ];
      const nouns = [
        "Warrior",
        "Knight",
        "Dragon",
        "Hunter",
        "Champion",
        "Ninja",
        "Phoenix",
        "Titan",
      ];
      const randomAdjective =
        adjectives[Math.floor(Math.random() * adjectives.length)];
      const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
      const randomNumber = Math.floor(Math.random() * 10000); // Optional: Add a random number at the end
      return `${randomAdjective}${randomNoun}${randomNumber}`;
    };

    useEffect(() => {
      setName(full_name);
    }, []);

    const genders = [
      "Male",
      "Female",
      "Non-Binary",
      "Other",
      "Prefer not to say",
    ];

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState(gender_update);

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    return (
      <Modal
        backdrop="static"
        overflow={false}
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Profile_edit_Modal);
        }}
        // onExited={() => modal.remove()}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_edit_Modal);
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
                overflow: "visible",
              }}
            >
              {!preview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      avatar_id,
                      full_name,
                      gender_update,
                      date_of_birth,
                      profile_picture,
                      file,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <img
                  style={{ borderRadius: 35, width: 40, height: 40 }}
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      avatar_id,
                      full_name,
                      gender_update,
                      date_of_birth,
                      profile_picture,
                      file,
                    });
                    modal.hide();
                  }}
                  src={preview}
                  width="100%"
                  height="100%"
                />
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => setFileList(event[0].blobFile)}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  className="after_ring_main"
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                >
                  {fileInfo ? (
                    <>
                      <img
                        className="after-ring"
                        src={fileInfo}
                        width="100%"
                        height="100%"
                      />
                      <div
                        className="d-flex align-items-center justify-content-center after_ring1"
                        style={{
                          bottom: "11px",
                          right: "0",
                          width: "13px",
                          height: "13px",
                          borderRadius: "3px",
                          background: fileInfo ? "#2ED573" : "",
                          position: "absolute",
                        }}
                      >
                        {fileInfo && (
                          <TiTick
                            style={{ fontSize: "10px" }}
                            className="text-white"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
                  )}
                </button>
              </Uploader>
              {fileInfo && (
                <span className="fromGallery">
                  <ImageIcon style={{ fontSize: 10, color: "#fff" }} />
                </span>
              )}
            </div>

            {Avatar_data?.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={
                      fileInfo
                        ? false
                        : selectedAvatar === item.avatar_id
                        ? true
                        : false
                    }
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: !fileInfo ? "#2ED573" : "",
                        position: "absolute",
                      }}
                    >
                      {!fileInfo && (
                        <TiTick
                          style={{ fontSize: "10px" }}
                          className="text-white"
                        />
                      )}
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div className="editField" style={{ marginTop: 8 }}>
            <label>Username*</label>
            <Input
              disabled={name_status ? true : false}
              value={name}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span
              className="editLink d-flex align-items-center"
              onClick={() => setName_status(false)}
            >
              Edit
            </span>
          </div>
          <div className="editField" style={{ marginTop: 8 }}>
            <label className="d-block">
              Date of birth
              {/* <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="click"
                speaker={tooltip}
              >
                <img
                  className="ms-2"
                  src={require("../src/assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper> */}
            </label>
            {/* <DatePicker
              placeholder="Not specified"
              disabled={dob_status ? true : false}
              oneTap
              placement="top"
              className="w-100"
              defaultValue={date_of_birth ? new Date(date_of_birth) : null}
              onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => setDob_status(false)}
            >
              Edit
            </span> */}
            <Input
              // defaultValue={
              //   date_of_birth
              //     ? moment(date_of_birth).format("YYYY-MM-DD")
              //     : null
              // }
              disabled={true}
              value={
                date_of_birth
                  ? moment(date_of_birth).format("YYYY-MM-DD")
                  : moment(select_dob).format("YYYY-MM-DD")
              }
              placeholder="Not Specified"
              style={{ background: "none" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Update_Dob_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob: date_of_birth,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 8 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Gender</label>
            <input
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender}
            />

            <div className="custom-select-container">
              {isOpen && (
                <>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !name &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                className="guest-btn w-auto"
                onClick={() => {
                  onContactFromSubmit();
                  modal.hide();
                }}
                style={{
                  borderRadius: 20,
                  padding: "10px 25px",
                }}
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
              >
                Save & Continue
              </Button>
            )}
          </div>
          <p
            className="text-white text-center m-0 mt-3 login_para"
            style={{ fontSize: "12px" }}
          >
            Note: If DOB is not declared then you are agreeing to the terms that
            you are 13+ years of age. To get the advantages and special
            privilages of partcipating in the Weekly Leaderboard Challenges, you
            need to Login.
          </p>
        </Modal.Body>
      </Modal>
    );
  }
);
const Update_Dob_Modal = NiceModal.create(
  ({ full_name, file, preview, select_dob }) => {
    console.log({ select_dob });
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);

    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Update_Profile_Modal);
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Update_Profile_Modal);
              }}
            ></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec d-flex">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  defaultValue={new Date(select_dob)}
                  // value={dob}
                  placeholder={"DD/MM/YYYY"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100"
                  onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none" }}
                >
                  <img
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                NiceModal.show(Update_Profile_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob: dob || select_dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Camera_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      // Start the camera when the component mounts
      startCamera();

      // Cleanup the stream on component unmount
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject;
          // const tracks = stream.getTracks();
          // tracks.forEach((track) => track.stop());
        }
      };
    }, [videoRef.current]);

    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStream(stream); // Store the stream
          setHasPermission(true);
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
        setHasPermission(false);
      }
    };

    const stopCamera = () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop each track
        // setIsCameraOn(false); // Update the camera status
      }
      setHasPermission(false);
    };

    const captureImage = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const context = canvas.getContext("2d");
        // Draw the current frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a Blob (image file)
        canvas.toBlob((blob) => {
          // Create a File from the Blob and append it to FormData
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          const imageURL = canvas.toDataURL("image/png");
          //console.log(imageURL);
          //  const formData = new FormData();
          //  formData.append("image", file);

          NiceModal.show(Profile_Modal, {
            navigate,
            full_name,
            file: file,
            preview: imageURL,
          });
          modal.hide();
          stopCamera();
          // Send the FormData to the server (for example, using fetch)
        }, "image/png"); // You can also use 'image/jpeg' for JPEG format
      }
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => modal.hide()}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Camera
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row flex-wrap">
            <div className="w-100">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay
                style={{ background: "#333947" }}
              />

              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width="640"
                height="480"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              onClick={captureImage}
              appearance="primary"
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
              }}
            >
              Capture
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Share_Modal = NiceModal.create(({ id, game_name, game_link }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);
  console.log(game_link);
  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  // const shareUrl = window.location.href; // or location.href in some cases

  const title = `Hey! I'm playing ${game_name.replace(
    /-/g,
    " "
  )} on Playzhub. Don't miss out on the excitement – check it out now:`;
  const page = `game`;
  const shareUrl = `https://playzhub.com/${page}/${id}/${game_name}`;
  return (
    <Modal
      backdrop="static"
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <i
            style={{ cursor: "pointer" }}
            class="fa-solid fa-xmark"
            onClick={() => {
              modal.hide();
            }}
          ></i>
          Share This Game
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row flex-wrap">
          <div
            className="w-100 text-center d-flex j"
            style={{
              justifyContent: "space-evenly",
            }}
          >
            <FacebookShareButton
              style={{
                background: "#1877F2",
                borderRadius: "25px",
                height: "50px",
                width: "50px",
              }}
              url={shareUrl}
              quote={title}
              hashtag="#playzhub"
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "30px",
                  borderRadius: "20px",
                }}
                class="fa-brands fa-facebook-f"
              ></i>
            </FacebookShareButton>

            <TwitterShareButton
              style={{
                background: "#000",
                borderRadius: "25px",
                height: "50px",
                width: "50px",
              }}
              url={shareUrl}
              title={title}
              // via="YourTwitterHandle"
              hashtags={["playzhub"]}
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "30px",
                  borderRadius: "20px",
                }}
                class="fa-brands fa-x-twitter"
              ></i>
            </TwitterShareButton>

            <LinkedinShareButton
              style={{
                background: "#0077B5",
                borderRadius: "25px",
                height: "50px",
                width: "50px",
              }}
              url={shareUrl}
              title={title}
              summary={title}
              // source="YourWebsite"
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "30px",
                  borderRadius: "20px",
                }}
                class="fa-brands fa-linkedin-in"
              ></i>
            </LinkedinShareButton>
            <WhatsappShareButton
              style={{
                background: "#25D366",
                borderRadius: "25px",
                height: "50px",
                width: "50px",
              }}
              url={shareUrl}
              title={title}
              summary={title}

              // source="YourWebsite"
            >
              <i
                style={{
                  color: "	#fff",
                  fontSize: "30px",
                  borderRadius: "20px",
                }}
                class="fa-brands fa-whatsapp"
              ></i>
            </WhatsappShareButton>
            {/* <RedditShareButton
              style={{
                background: "#FF4500",
                borderRadius: "25px",
                height: "50px",
                width: "50px",
              }}
              url={shareUrl}
              title={title}
              summary={title}
              // source="YourWebsite"
            >
              <i
                style={{
                  color: "#fff",
                  fontSize: "30px",
                  borderRadius: "20px",
                }}
                class="fa-brands fa-reddit-alien"
              ></i>
            </RedditShareButton> */}
          </div>
        </div>
        <div className="text-center editField mt-3">
          <Input
            readOnly
            ref={inputRef}
            type="text"
            defaultValue={`https://playzhub.com/game/${id}/${game_name}`}
            style={{
              height: "35px",
              width: "253px",
              color: "#818A97",
              fontSize: "15px",
              paddingRight: "10px",
            }}
          />
          <span className="editLink d-flex align-items-center">
            <Button
              onClick={handleCopy}
              appearance="primary"
              style={{
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
                height: "30px",
                cursor: "pointer",
              }}
            >
              {copied ? "copied" : "Copy"}
            </Button>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
});

const Embed_Modal = NiceModal.create(({ game_name }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const [copied, setCopied] = useState(false);
  const inputRef = useRef(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  return (
    <Modal
      backdrop="static"
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <i
            style={{ cursor: "pointer" }}
            class="fa-solid fa-xmark"
            onClick={() => {
              modal.hide();
            }}
          ></i>
          {`Embed ${game_name?.replace(/-/g, " ")}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center editField mt-3">
          <Input
            readOnly
            ref={inputRef}
            type="text"
            defaultValue={`<iframe src="https://playzhub.com/embed/${game_name?.replace(
              /-/g,
              ""
            )}" style="width: 100%; height: 100%;" frameborder="0"></iframe>`}
            style={{
              height: "35px",
              width: "253px",
              color: "#818A97",
              fontSize: "15px",
              paddingRight: "10px",
            }}
          />
          <span className="editLink d-flex align-items-center">
            <Button
              onClick={handleCopy}
              appearance="primary"
              style={{
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
                height: "30px",
                cursor: "pointer",
              }}
            >
              {copied ? "copied" : "Copy"}
            </Button>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
});

const Feedback_Profile_Modal = NiceModal.create(
  ({
    game_id,
    game_name,
    game_img,
    game_category,
    game_technology,
    game_platform,
    country,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    const dispatch = useDispatch();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const profile_data = useSelector((state) => state.profile_data);
    const { add_dislike_load } = useStore();
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    let [issue_list, setIssue_list] = useState([]);
    const [phone, setPhone] = useState(
      !profile_data?.phone_number ? "" : profile_data?.phone_number
    );
    const [query, setQuery] = useState("");
    const [feedback_id, setFeedback_id] = useState("");
    const [selectedGender, setSelectedGender] = useState("");

    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (item) => {
      setFeedback_id(item?.id);
      setSelectedGender(item);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
      get_issue_list();
    }, []);

    const get_issue_list = () => {
      Service.issue_list()
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            setIssue_list(res.data);
          }
        })
        .catch((e) => console.log(e));
    };

    const submit_feedback = () => {
      setLoading(true);
      var regExp = new RegExp("^\\d+$");
      if (!regExp.test(phone)) {
        setErr(true);
        setLoading(false);
        return;
      }
      let params = {
        game_id: game_id,
        phone_number: phone,
        feedback_id: feedback_id,
        visitor_feedback: query,
        isDisliked: true,
      };
      Service.feedback_register(params)
        .then((res) => {
          if (res?.status == 1) {
            logEvent(analytics, "games_unliked_count", {
              game_id: game_id,
              game_title: game_name?.replace(/-/g, " "),
              game_technology: game_technology,
              game_category: game_category,
              game_platform: game_platform,
              country: country,
            });
            setErr(false);
            setLoading(false);
            game_details();
            /// add_dislike_load(true);
            modal.hide();
            NiceModal.show(Thank_Modal);
          }
        })
        .catch((e) => console.log(e.message));
    };

    const game_details = () => {
      let params = {
        gameId: game_id,
      };
      Service.game_details(params)
        .then((res) => {
          //console.log(res);
          dispatch({
            type: GAME_DATA,
            payload: res?.data,
          });
          dispatch({
            type: LIKED,
            payload: res?.data?.liked,
          });
          dispatch({
            type: DISLIKED,
            payload: res?.data?.disliked,
          });

          //setCategory(res?.data?.categories);
        })
        .catch((e) => console.log(e.message));
    };

    return (
      <Modal
        backdrop="static"
        overflow={false}
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => modal.hide()}
        onExited={() => modal.remove()}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              onClick={modal.hide}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
            <h4 className="mb-3 fw-normal">Don't like this game?</h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              <img
                src={game_img}
                classname="d-flex"
                style={{ height: "25%", width: "25%", borderRadius: "10px" }}
              />
              <br />
              {game_name?.replace(/-/g, " ")}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className="editField"
            style={{ marginTop: 8 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Feedback</label>
            <input
              value={selectedGender?.feedbacks}
              readOnly
              type="text"
              placeholder="Not specified"
            />

            <div className="custom-select-container-feedback ">
              {isOpen && (
                <div className="custom-select-container-feedback ">
                  {isOpen && (
                    <>
                      {/* <div className="custom-select-header">{headerText}</div> */}
                      <ul className="custom-select-dropdown">
                        {issue_list?.map((item, index) => {
                          return (
                            <li key={index} onClick={() => handleSelect(item)}>
                              {item?.feedbacks}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="editField" style={{ marginTop: 8 }}>
            <label>Your Phone Number</label>
            <Input
              defaultValue={profile_data?.phone_number}
              minLength={8}
              maxLength={15}
              onChange={(e) => setPhone(e)}
              placeholder="Fill phone number"
              style={{ background: "none" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Invalid mobile number
              </span>
            )}
          </div>

          <div className="editField" style={{ marginTop: 8 }}>
            <label className="d-block">Your Message</label>

            <Input
              onChange={(e) => setQuery(e)}
              className="text-white"
              rows={3}
              as="textarea"
              placeholder="Fill your message"
              style={{ background: "none", borderColor: "#333947" }}
            />
          </div>

          <div className="text-center">
            {!selectedGender || !phone || !query ? (
              <Button
                className="guest-btn w-auto"
                style={{
                  borderRadius: 20,
                  padding: "10px 25px",
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={submit_feedback}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
              >
                Submit
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Thank_Modal = NiceModal.create(() => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const toaster = useToaster();

  return (
    <Modal
      backdrop="static"
      overflow={false}
      className="rs-modal-xs"
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <i
            onClick={modal.hide}
            class="fa-solid fa-xmark"
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="text-center">
          <img
            className="mb-3"
            src={require("../assets/thankyou.png")}
            width={150}
            alt=""
          />
          <p className="py-4" style={{ color: "#FCFDFF", fontWeight: "300" }}>
            We appreciate you taking the time to help <br />
            us improve your gaming experience.
            <br /> Happy gaming!
          </p>
          <Button
            className=""
            onClick={modal.hide}
            appearance="primary"
            style={{
              width: "144px",
              borderRadius: 20,
              background: "#F2641F",
              padding: "10px 25px",
            }}
          >
            Okay
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});
const AnimationManager = {
  frameIndex: 0,
  interval: null,
  startAnimation: (frameCount, frameRate, onFrameChange) => {
    // Prevent starting a new interval if already running
    if (AnimationManager.interval) return;

    AnimationManager.interval = setInterval(() => {
      // Check if we're on the last frame; if so, stop the animation
      if (AnimationManager.frameIndex + 1 === frameCount) {
        clearInterval(AnimationManager.interval); // Stop the animation
        AnimationManager.interval = null;
      } else {
        AnimationManager.frameIndex += 1;
        onFrameChange(AnimationManager.frameIndex); // Update frame
      }
    }, 1000 / frameRate);
  },

  stopAnimation: () => {
    clearInterval(AnimationManager.interval);
    AnimationManager.interval = null;
  },

  resetAnimation: () => {
    AnimationManager.frameIndex = 0;
  },
};

const Rules_Modal = NiceModal.create(({ navigate }) => {
  const modal = useModal();

  return (
    <Modal
      size={"md"}
      className="rs-modal-xl"
      open={modal.visible}
      onClose={() => modal.hide()}
      // onExited={modal.remove}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          Leaderboard Rules
          <i
            onClick={() => {
              modal.hide();
            }}
            class="fa-solid fa-xmark"
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column" style={{ color: "#818A97" }}>
          <h6>Weekly Leaderboard:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Dive into our thrilling games on the Playzhub platform and compete
              with other players to climb the leaderboard.
            </li>
            <li>The weekly leaderboard runs from Sunday to Saturday.</li>
            <li>
              At the end of each week, the leaderboard resets, giving everyone a
              fresh start with new challenges and competition.
            </li>
          </ul>
          <h6>Playzhub Coins:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Play and complete games to earn Playzhub coins based on your
              highest scores in each game.
            </li>
            <li>
              The highest Playzhub coins earned from a game will be used to
              feature your rank on the weekly leaderboard.
            </li>
            <li>
              Your total leaderboard position is determined by the sum of the
              highest Playzhub coins earned across all games during the week.
            </li>
            <li>
              Keep track of your progress through the “Playing History” section.
            </li>
          </ul>
          <h6>Points Calculation:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Playzhub coins are awarded based on the scores you achieve in each
              game.
            </li>
            <li>
              The conversion of scores to Playzhub coins is outlined in the
              "Points Conversion" chart, available in the gameplay description
              of every game.
            </li>
            <li>
              These conversions determine your scores for the weekly leaderboard
              rankings.
            </li>
          </ul>
        </div>
        <div className="text-center">
          <Button
            onClick={() => modal.hide()}
            appearance="primary"
            style={{
              marginTop: 10,
              borderRadius: 20,
              background: "#F2641F",
              padding: "10px 25px",
            }}
          >
            Got It
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});
const Game_screen = () => {
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  let cookies = new Cookies();
  let username = useSelector((state) => state.Uname);
  let game_datas = useSelector((state) => state.game_data);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [play, setPlay] = useState(false);
  const [stackList, setStackList] = useState([]);
  const [game_data, setGame_data] = useState({});
  const [like_dislike_data, setLikeDisklike] = useState([]);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const [status, setStatus] = useState(0);
  const [score_conversion, setScore_conversion] = useState([]);
  const [all_game, setAll_game] = useState([]);
  const { token, status_guest, add_guest_status, dislike_load } = useStore();
  let IP_URL = process.env.REACT_APP_IP_URL;
  let [ids, setId] = useState("");
  let [game_score, setGame_score] = useState("");
  const { profile_status_load, add_profile_load, add_guest_token } = useStore();
  const [isIosScreen, setIsIosScreen] = useState(false);
  let [offline_data, setOffline_data] = useState({
    ip: "122.176.24.171",
    network: "122.176.24.0/24",
    version: "IPv4",
    city: "Kolkata",
    region: "West Bengal",
    region_code: "WB",
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso3: "IND",
    country_capital: "New Delhi",
    country_tld: ".in",
    continent_code: "AS",
    in_eu: false,
    postal: "700054",
    latitude: 22.518,
    longitude: 88.3832,
    timezone: "Asia/Kolkata",
    utc_offset: "+0530",
    country_calling_code: "+91",
    currency: "INR",
    currency_name: "Rupee",
    languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    country_area: 3287590,
    country_population: 1352617328,
    asn: "AS24560",
    org: "Bharti Airtel Ltd., Telemedia Services",
  });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const network_details = cookies.get("network_details");
  let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);

  let validate_users = useSelector((state) => state.guest_token);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimatingfav, setIsAnimatingfav] = useState(false);

  // Lottie options
  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleButtonClick = () => {
    setIsAnimating(true);
    // Set a timer to stop the animation after a certain period (if needed)
    setTimeout(() => {
      setIsAnimating(false);
    }, 3000); // Animation runs for 3 seconds
  };

  const favButtonClick = () => {
    setIsAnimatingfav(true);
    // Set a timer to stop the animation after a certain period (if needed)
    setTimeout(() => {
      setIsAnimatingfav(false);
    }, 3000); // Animation runs for 3 seconds
  };

  const guest_user = () => {
    if (!validate_users) {
      fetch_user_details();
    }
    ///window.location.reload(true);
  };
  const fetch_user_details = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        console.log({ res });
        cookies.set("network_details", res.data, { path: "/" });
        let data = res.data;
        validate_user(data);
      })
      .catch((e) => {
        console.log(e.config.data);
        if (e.config.data === undefined) {
          validate_user(offline_data);
        }
      });
  };

  const validate_user = (data) => {
    let params = {
      ip_address: data.ip,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
      additional_details: JSON.stringify(data),
    };
    Service.login_register_visitor(params)
      .then((res) => {
        // console.log(res);
        logEvent(analytics, "guest_user_count", {
          country: data?.country_name,
          city: data?.city,
        });
        cookies.set("token", res.token, { path: "/" });
        window.localStorage.setItem("guest_token", res?.token);
        dispatch({
          type: GUEST_TOKEN,
          payload: res?.token,
        });
        game_details(id);
        add_guest_token(res.token);
        // window.location.reload(true);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    guest_user();
  }, [id]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
        }
      }

      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const myRef = useRef();
  useEffect(() => {
    logEvent(analytics, "games_most_categories_visited_notplayed", {
      game_id: location?.state?.datas?.id,
      game_title: location?.state?.datas?.title,
      game_category: location?.state?.datas?.category,
      game_technology: location?.state?.datas?.technology,
      country: network_details?.country_name,
    });
    logEvent(analytics, "games_technologies_visited_notplayed", {
      game_id: location?.state?.datas?.id,
      game_title: location?.state?.datas?.title,
      game_category: location?.state?.datas?.category,
      game_technology: location?.state?.datas?.technology,
      country: network_details?.country_name,
    });
    myRef?.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    suggest_list(location?.state?.datas?.id || id);
  }, [location?.state?.datas]);

  useEffect(() => {
    game_details();
    all_game_list();
    // update_game_state();
  }, [location?.state?.datas?.id, id]);

  const suggest_list = (id) => {
    Service.suggest_game_list(id)
      .then((res) => {
        // console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const all_game_list = (params) => {
    Service.game_list(params)
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setAll_game([]);
        }
        // console.log(res.data);
        setAll_game(res.data);
        //checkDateDifference();
      })
      .catch((e) => e.message);
  };
  let liked_store = useSelector((state) => state.liked);
  let disliked_store = useSelector((state) => state.disliked);
  const game_details = () => {
    let params = {
      gameId: id,
    };
    Service.game_details(params)
      .then((res) => {
        //console.log(res);
        if (res?.status == 1) {
          //console.log(res?.data?.liked);
          dispatch({
            type: GAME_DATA,
            payload: res?.data,
          });
          dispatch({
            type: LIKED,
            payload: res?.data?.liked,
          });
          dispatch({
            type: DISLIKED,
            payload: res?.data?.disliked,
          });
          setStatus(res?.status);
          setGame_data(res?.data);
          setScore_conversion(res?.data?.score_conversion);
        }

        //setCategory(res?.data?.categories);
      })
      .catch((e) => console.log(e.message));
  };

  const score_Session = (id, score) => {
    let params = {
      game_id: id,
      game_score: score,
    };
    Service.score_update(params)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  let login_token = useSelector((state) => state.login_token);
  let [guest_status, setGuest_status] = useState(status_guest);

  useEffect(() => {
    if (login_token) {
      if (game_score) {
        score_Session(id, game_score);
        setGuest_status(false);
      }
    }
  }, [login_token, game_score]);

  //#region Game Event js bridge

  useEffect(() => {
    const handleMessage = (event) => {
      // if (event.origin !== game_data.game_url) return;

      // console.log(
      //   "EVENT JSBRIDGE REACTSIDE===>",
      //   JSON.parse(event.data.jsonMessage)
      // );

      console.log("EVENT JSBRIDGE REACTSIDE===>", event?.data);

      if (event?.data?.eventName == "GameFrequency") {
        let id = event.data?.data?.game_id;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "gameplayed_count", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_category: location?.state?.datas?.category,
        });
        logEvent(analytics, "games_most_categories_visited_played", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_category: location?.state?.datas?.category,
        });
        logEvent(analytics, "games_most_technologies_visited_played", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_technology: location?.state?.datas?.technology,
        });
      } else if (event?.data?.eventName == "request-game-state") {
        sendResponseToIframe();
      } else if (event?.data?.eventName == "game-state") {
        let data = event?.data?.data;
        // console.log("game-state", event?.data?.data);
        dispatch({
          type: GAME_STATE,
          payload: event?.data?.data,
        });
        set_game_state(data);
      } else if (event?.data?.eventName == "GameOver") {
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_completed", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
          exitFullScreen();
          setIsIosScreen(false);
        } else if (login_token) {
          score_Session(id, score);
        }
      } else if (event?.data?.eventName == "GameQuit") {
        //console.log(event.data.eventName);
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_notcompleted", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
          exitFullScreen();
          setIsIosScreen(false);
        } else if (login_token) {
          // score_Session(id, score);
        }
      } else if (event?.data?.eventName == "GameCompleted") {
        //console.log(event.data.eventName);
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_completed", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
          exitFullScreen();
          setIsIosScreen(false);
        } else if (login_token) {
          score_Session(id, score);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [game_data?.game_url, login_token]);

  //#endregion

  const set_game_state = (data) => {
    let params = {
      game_id: game_data?.id,
      game_state: data,
    };
    Service.Set_game_state(params)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => e.message);
  };

  const like_dislike = (action) => {
    let params = {
      gameId: location?.state?.datas?.id,
      action: action,
    };
    Service.like_dislike_action(params)
      .then((res) => {
        if (res?.status == 1) {
          handleButtonClick();
          setLikeDisklike(res?.data);
          game_details();
        }

        //console.log("details", res?.data[0]);
      })
      .catch((e) => console.log(e.message));
  };

  const enterFullScreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const fullscreen = () => {
    // check if fullscreen mode is available
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    ) {
      // which element will be fullscreen
      var iframe = document.querySelector("iframe");

      // Do fullscreen
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    } else {
      document.querySelector(".error").innerHTML =
        "Your browser is not supported";
    }
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const fav_status = (status) => {
    let params = {
      game_id: game_data?.id,
      status: status,
    };
    Service.fav_games_status(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 1) {
          game_details();
        }
      })
      .catch((e) => console.log(e));
  };

  let [ad_data_portrait, setAd_data_portrait] = useState([]);
  let [ad_data_landscape, setAd_data_landscape] = useState([]);
  let [ad_data_rectangle, setAd_data_rectangle] = useState([]);

  useEffect(() => {
    ads_fetch();
  }, []);
  const ads_fetch = () => {
    let params = {
      category_name: "",
    };
    Service.custom_ads(params)
      .then((res) => {
        // setData(res.data);
        const filteredPortraitAds = res?.data?.filter(
          (ad) => ad?.orientation === "portrait"
        );
        const filteredLandscapeAds = res?.data?.filter(
          (ad) => ad?.orientation === "landscape"
        );
        const filteredRectangleAds = res?.data?.filter(
          (ad) => ad?.orientation === "rectangular"
        );
        console.log({ filteredRectangleAds });
        setAd_data_portrait(filteredPortraitAds);
        setAd_data_landscape(filteredLandscapeAds);
        setAd_data_rectangle(filteredRectangleAds);
      })
      .catch((e) => console.log(e));
  };
  const handleShare = async () => {
    try {
      if (navigator.share) {
        // Using the Web Share API to share product info
        await navigator.share({
          title: `Hey! I'm playing ${game_data?.title?.replace(
            /-/g,
            " "
          )} on Playzhub. Don't miss out on the excitement – check it out now:`,
          text: `Hey! I'm playing ${game_data?.title?.replace(
            /-/g,
            " "
          )} on Playzhub. Don't miss out on the excitement – check it out now: ${
            game_data?.cover_picture
          }`,
          url: `https://playzhub.com/${game_data?.id}/${game_data?.title}`,
          files: [
            new File(
              [await fetch(game_data?.cover_picture).then((res) => res.blob())],
              "game_image.jpg",
              { type: "image/jpeg" }
            ),
          ],
        });
        console.log("Shared successfully!");
      } else {
        alert("Share API is not supported on your browser.");
      }
    } catch (error) {
      console.error("Error sharing the product: ", error);
    }
  };
  const handleShares = () => {
    const url = encodeURIComponent("https://example.com"); // URL to share
    const title = encodeURIComponent("Awesome Post!"); // Title
    const summary = encodeURIComponent(
      "This is an amazing article you need to read!"
    ); // Summary
    const hashtag = encodeURIComponent("#awesome"); // Hashtag

    // Construct message content
    const message = `${title}\n\n${summary}\n\nRead more here: ${url} ${hashtag}`;

    // WhatsApp sharing URL
    const whatsappUrl = `https://wa.me/?text=${message}`;

    // Open WhatsApp sharing window
    window.open(whatsappUrl, "_blank", "width=600,height=400");
  };
  //#region Game-State

  const update_game_state = () => {
    let params = {
      game_id: game_data?.id,
    };
    Service.Get_game_state(params)
      .then((res) => {
        if (res?.status == 0) {
          dispatch({
            type: GAME_STATE,
            payload: null,
          });
        } else {
          dispatch({
            type: GAME_STATE,
            payload: res?.data?.game_state,
          });
        }
      })
      .catch((e) => e.message);
  };

  const Game_state = useSelector((state) => state.game_state);

  useEffect(() => {
    if (iframeLoaded) {
      sendMessageToIframe();
    }
  }, [iframeLoaded]);

  const sendMessageToIframe = () => {
    let params = {
      game_id: game_data?.id,
    };
    Service.Get_game_state(params)
      .then((res) => {
        if (res?.status == 0) {
          const iframe = iframeRef.current;
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
              {
                event: "game-state",
                data: null,
              },
              "*"
            );
          } else {
            console.error("Iframe not loaded or not accessible.");
          }
        } else {
          const iframe = iframeRef.current;
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
              {
                event: "game-state",
                data: res?.data?.game_state,
              },
              "*"
            );
          } else {
            console.error("Iframe not loaded or not accessible.");
          }
        }
      })
      .catch((e) => e.message);
  };

  const sendResponseToIframe = () => {
    let params = {
      game_id: game_data?.id,
    };
    Service.Get_game_state(params)
      .then((res) => {
        console.log(res);
        if (res?.status == 0) {
          dispatch({
            type: GAME_STATE,
            payload: null,
          });
          console.log(Game_state == {} ? true : false);
          const iframe = iframeRef.current;
          if (iframe && iframe.contentWindow) {
            console.log(" Game state received on Platform ", Game_state);

            iframe.contentWindow.postMessage(
              {
                eventName: "response-game-state",
                data: null,
              },
              "*"
            );
          } else {
            console.error("Iframe not loaded or not accessible.");
          }
        } else {
          dispatch({
            type: GAME_STATE,
            payload: res?.data?.game_state,
          });

          const iframe = iframeRef.current;
          if (iframe && iframe.contentWindow) {
            console.log(" Game state received on Platform else", Game_state);
            iframe.contentWindow.postMessage(
              {
                eventName: "response-game-state",
                data: res?.data?.game_state,
              },
              "*"
            );
          } else {
            console.error("Iframe not loaded or not accessible.");
          }
        }
      })
      .catch((e) => e.message);
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };
  //#endregion
  const cleanDescription = DOMPurify.sanitize(game_data?.description);
  const [showButton, setShowButton] = useState(false);
  //#region Animation register
  const [frameIndex, setFrameIndex] = useState(0);

  useEffect(() => {
    const frameCount = 53; // Total number of frames in your sequence
    const frameRate = 24; // Frames per second (FPS)
    AnimationManager.resetAnimation();
    // Start the animation when the component mounts
    AnimationManager.startAnimation(frameCount, frameRate, (newFrameIndex) => {
      setFrameIndex(newFrameIndex); // Update the frame index in state
    });

    // Cleanup the animation when the component unmounts
    return () => {
      AnimationManager.stopAnimation();
      // setShowButton(true);
    };
  }, []); // Empty dependency array to run only once on mount

  // Construct the image name based on the frame index
  const imageName = `Playzhub_05_8_forAnimation-Type_1_${String(
    frameIndex + 1
  ).padStart(2, "0")}.png`;

  //endregion
  const handleDataFromChild = (data) => {
    console.log("Data received from child:", data);
    setShowButton(true);
  };

  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(myRef, show, {
    onClose: () => toggle(false),
  });
  const handle = useFullScreenHandle();

  return (
    <>
      {isIosScreen ? (
        <div style={{ display: "flex" }}>
          <iframe
            allow="fullscreen *"
            id="iframe"
            onLoad={handleIframeLoad}
            ref={iframeRef}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            msallowfullscreen="true"
            allowfullscreen="true"
            // className={"icon_003_landscape"}
            src={`${game_data?.game_url}?game_id=${game_data?.id}`}
            // allowfullscreen="true"
            style={{
              aspectRatio: "16/9",
            }}
            scrolling="no"
          />
        </div>
      ) : (
        <div ref={myRef} className="scroll_issue">
          <Header onPress={toggleMenu} />
          <div>
            <Helmet>
              <meta charSet="utf-8" />
              <meta
                name="description"
                content={`${game_data?.title?.replace(
                  /-/g,
                  " "
                )} is an exciting action game. Help this sly fox escape with an ostrich egg as he carries it on his cart, keep in mind the egg is fragile and the roads equally bumpy. Keep the egg safe and try to escape the ostrich as it chases you.`}
              />
              <title>
                {`Playing ${game_data?.title?.replace(
                  /-/g,
                  " "
                )} Game | Play ${game_data?.title?.replace(
                  /-/g,
                  " "
                )} Free Online Action
                Games on PlayzHub`}
              </title>
            </Helmet>
          </div>
          <div className="container-fluid pl-80 d-flex flex-column mh-100vh">
            <div className="row">
              {/* NAVIGATION */}
              <NavigationBar isActive={isActive} username={username} />
              {/* NAVIGATION */}
              {/* PAGE CONTENT */}

              <div className="col-12 col-lg-12 col-xl-12 mt-lg-3 ">
                <div className="row justify-content-between mb-0 mb-lg-0">
                  <div
                    className={
                      // game_data?.game_orientation == "portrait"
                      //   ? "col-12 col-md-2 col-lg-3 d-none d-md-block "
                      //   :
                      "col-12 col-md-2 col-lg-3 d-none"
                    }
                  >
                    {/* <Custom_ads
                    ads={ad_data_portrait}
                    classname={"screenshot_2024_09_25_191006_portrait w-100"}
                    rotationInterval={5000}
                  /> */}
                  </div>
                  <div
                    className={
                      // game_data?.game_orientation == "portrait"
                      //   ? "col-12 col-md-8 col-lg-4 mb-5 mb-sm-0 d-flex justify-content-center position-relative "
                      //   :

                      "col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 mb-3 mb-sm-0 d-flex justify-content-start position-relative flex-column"
                    }
                  >
                    <div className="game_lt_panel">
                      {!play && (
                        <>
                          <div
                            className="gameOverlay d-flex align-items-center justify-content-center flex-column"
                            // onClick={() => {
                            //   setPlay(true);
                            // }}
                          >
                            {/* {!showButton && (
                          <img
                            src={require("../assets/Playzhub_05_8_forAnimation-Type_1_v02.gif")}
                            alt="Animated GIF"
                            className="gif"
                            onLoad={handleGifLoad}
                          />
                        )} */}
                            <div className="text-center position-relative w-100">
                              {/* <img
                            src={require(`/src/assets/png/${imageName}`)}
                            alt="PNG Sequence"
                            style={{
                              alignItems: "start",
                            }}
                          /> */}
                              <PngSequenceAnimation
                                frameCount={53}
                                frameRate={60}
                                props={handleDataFromChild}
                              />
                            </div>
                            <div
                              className="text-center d-flex flex-column align-items-center"
                              style={{ minHeight: "150px" }}
                            >
                              {showButton && (
                                <div className="gameButtonBox d-flex flex-column align-items-center">
                                  <h2>
                                    {game_data?.title?.replace(/-/g, " ")}
                                  </h2>
                                  <div
                                    className="playBtn d-flex align-items-center justify-content-between"
                                    onClick={() => {
                                      if (isAndroid) {
                                        fullscreen();
                                      } else if (isIOS) {
                                        setIsIosScreen(true);
                                      }

                                      setPlay(true);
                                    }}
                                  >
                                    Play Now
                                    <img
                                      src={require("./skins1/play_button.png")}
                                      className={
                                        // game_data?.game_orientation == "portrait"
                                        //   ? "play_button"
                                        //   :
                                        "play_button_landscape"
                                      }
                                    />
                                  </div>
                                  {!login_token ? (
                                    <p className="mt-2">
                                      Don't lose your progress!{" "}
                                      <span
                                        onClick={() => {
                                          NiceModal.show(Phone_Modal, {
                                            navigate,
                                          });
                                        }}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                          color: "#F2641F",
                                        }}
                                      >
                                        Sign in Now.
                                      </span>
                                    </p>
                                  ) : (
                                    <p className="mt-2">
                                      Your progress will be saved and displayed
                                      on the{" "}
                                      <span
                                        style={{
                                          color: "#2ED573",
                                        }}
                                      >
                                        weekly leaderboard.
                                      </span>
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {guest_status && (
                        <div className="gameOverlay d-flex align-items-center justify-content-center flex-column">
                          <img style={{ width: 100 }} src={Nostr} alt="" />

                          <p
                            className="text-center"
                            style={{
                              padding: "0 10px",
                              marginTop: 30,
                              fontSize: 14,
                            }}
                          >
                            By participating as a guest, your score will not be
                            <br />
                            updated on the leaderboard, and you might miss the
                            <br />
                            opportunity to earn rewards.
                          </p>

                          <div className="text-center">
                            <Button
                              // onKeyDown={handleKeyDown}
                              className="my-4"
                              // loading={loading}
                              onClick={() => {
                                NiceModal.show(Phone_Modal, {
                                  navigate,
                                  status: true,
                                });
                              }}
                              // disabled={!phone ? true : false}
                              appearance="primary"
                              style={{
                                width: "144px",
                                marginTop: 10,
                                borderRadius: 20,
                                background: "#F2641F",
                                padding: "10px 22px",
                              }}
                            >
                              Login
                            </Button>
                            <p
                              className="text-secondary"
                              style={{ justifyItems: "flex-end", flex: 1 }}
                            >
                              OR
                            </p>
                            <div style={{ marginTop: 10 }}></div>
                            <Button
                              className="guest-btn"
                              onClick={() => {
                                setGuest_status(false);
                              }}
                              style={{
                                marginTop: 10,
                                borderRadius: 20,
                                width: "auto",
                              }}
                            >
                              Continue as Guest
                            </Button>
                          </div>
                        </div>
                      )}
                      {status == 1 ? (
                        <iframe
                          allow="fullscreen *"
                          id="iframe"
                          onLoad={handleIframeLoad}
                          ref={iframeRef}
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          msallowfullscreen="true"
                          allowfullscreen="true"
                          className={"icon_003_landscape"}
                          src={`${game_data?.game_url}?game_id=${game_data?.id}`}
                          //src={`https://staginglocal.redappletech.com/playzhub/MatchItRight/`}
                          // allowfullscreen="true"
                          style={{
                            aspectRatio: "16/9",
                          }}
                          scrolling="no"
                        />
                      ) : // <embed
                      //   id="iframe"
                      //   onLoad={handleIframeLoad}
                      //   ref={iframeRef}
                      //   allowfullscreen="true"
                      //   src={`${game_data?.game_url}?game_id=${game_data?.id}`}
                      //   style={{
                      //     aspectRatio: "16/9",
                      //   }}
                      //   scrolling="no"
                      // />
                      null}

                      <div className="iframeCtrl d-flex align-items-center justify-content-between">
                        <div className="gameName">
                          {game_data?.title?.replace(/-/g, " ")}
                        </div>
                        <div className="d-flex align-items-center">
                          <span
                            className="me-2"
                            style={{
                              color: "#f2641f",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                          >
                            {game_data?.visitor_played}{" "}
                            <span className="text-white">Playing</span>
                          </span>

                          <span className="me-2 me-sm-2 me-lg-3 position-relative">
                            {isAnimatingfav && (
                              <div
                                style={{
                                  position: "absolute",
                                  // top: "50%",
                                  // left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: 1,
                                  top: "5px",
                                  left: "10px",
                                  width: "100px", // Adjust based on the size of your animation
                                  height: "100px", // Adjust based on the size of your animation
                                }}
                              >
                                <Lottie
                                  options={options}
                                  height={100}
                                  width={100}
                                />
                              </div>
                            )}
                            {game_data?.is_favourite == 1 ? (
                              <FaHeart
                                onClick={() => {
                                  if (ACCESS_TOKEN_USER) {
                                    fav_status(0);
                                  } else if (!ACCESS_TOKEN_USER) {
                                    NiceModal.show(Phone_Modal, { navigate });
                                  }
                                }}
                                style={{
                                  color: "#F2641F",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <FaHeart
                                onClick={() => {
                                  if (ACCESS_TOKEN_USER) {
                                    logEvent(
                                      analytics,
                                      "games_favourites_count",
                                      {
                                        game_id: game_data?.id,
                                        game_title: game_data?.title?.replace(
                                          /-/g,
                                          " "
                                        ),
                                        game_technology:
                                          game_data?.technologies,
                                        game_category: game_data?.categories,
                                        game_platform: game_data?.platforms,
                                        country: network_details?.country_name,
                                      }
                                    );
                                    fav_status(1);
                                    favButtonClick();
                                  } else if (!ACCESS_TOKEN_USER) {
                                    NiceModal.show(Phone_Modal, { navigate });
                                  }
                                }}
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </span>

                          <span
                            className="me-2 me-sm-2 me-lg-3"
                            style={{
                              color:
                                // like_dislike_data[0]?.total_liked.length == 1
                                //   ? "#2ED573"
                                //:
                                "#fff",
                              position: "relative",
                            }}
                          >
                            {isAnimating && (
                              <div
                                style={{
                                  position: "absolute",
                                  // top: "50%",
                                  // left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: 1,
                                  top: "10px",
                                  left: "15px",
                                  width: "100px", // Adjust based on the size of your animation
                                  height: "100px", // Adjust based on the size of your animation
                                }}
                              >
                                <Lottie
                                  options={options}
                                  height={100}
                                  width={100}
                                />
                              </div>
                            )}
                            <svg
                              className="me-1"
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                fill:
                                  game_datas?.liked == 1 ? "#2ED573" : "#fff",
                              }}
                              onClick={() => {
                                logEvent(analytics, "games_liked_count", {
                                  game_id: game_data?.id,
                                  game_title: game_data?.title?.replace(
                                    /-/g,
                                    " "
                                  ),
                                  game_technology: game_data?.technologies,
                                  game_category: game_data?.categories,
                                  game_platform: game_data?.platforms,
                                  country: network_details?.country_name,
                                });
                                like_dislike("like");

                                // handleButtonClick();
                                //NiceModal.show(Thank_Modal);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2l144 0c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48l-97.5 0c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3l0-38.3 0-48 0-24.9c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192l64 0c17.7 0 32 14.3 32 32l0 224c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32L0 224c0-17.7 14.3-32 32-32z" />
                            </svg>{" "}
                            {/* <img
                        onClick={() => like_dislike("like")}
                        style={{ cursor: "pointer", width: "16px" }}
                        src={require("./skins1/thumbs_up.png")}
                        className="me-1"
                      /> */}
                            {/* {game_data?.total_likes} */}
                            {game_datas?.total_likes}
                          </span>
                          <span className="me-2 me-sm-2 me-lg-4">
                            <svg
                              className="me-1"
                              onClick={() => {
                                //like_dislike("dislike");
                                if (!ACCESS_TOKEN_USER) {
                                  NiceModal.show(Phone_Modal, { navigate });
                                } else {
                                  NiceModal.show(Feedback_Profile_Modal, {
                                    game_id: game_data?.id,
                                    game_name: game_data?.title,
                                    game_img: game_data?.cover_picture,
                                    game_technology: game_data?.technologies,
                                    game_category: game_data?.categories,
                                    game_platform: game_data?.platforms,
                                    country: network_details?.country_name,
                                  });
                                }
                              }}
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                fill:
                                  game_datas?.disliked == 1
                                    ? "#FB4141"
                                    : "#fff",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2l144 0c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48l-97.5 0c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7l0 38.3 0 48 0 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32L32 96C14.3 96 0 110.3 0 128L0 352c0 17.7 14.3 32 32 32z" />
                            </svg>{" "}
                            {/* <img
                        onClick={() => like_dislike("dislike")}
                        style={{ cursor: "pointer", width: "16px" }}
                        src={require("./skins1/thumbs_down.png")}
                        className="me-1"
                      /> */}
                            {/* {game_data?.total_dislikes} */}
                            {game_datas?.total_dislikes}
                          </span>
                          <span>
                            <img
                              style={{
                                cursor: "pointer",
                                opacity: !play && 0.3,
                              }}
                              onClick={() => {
                                if (isAndroid) {
                                  if (play) {
                                    //fullscreen();
                                    enterFullScreen();
                                  }
                                } else if (isIOS) {
                                  setIsIosScreen(true);
                                } else {
                                  if (play) {
                                    //fullscreen();
                                    enterFullScreen();
                                  }
                                }
                              }}
                              src={require("./skins1/path_26439.png")}
                              className={
                                // game_data?.game_orientation == "portrait"
                                //   ? "path_26439"
                                //   :
                                "path_26439_landscape"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 pt-3 left_add">
                      <Custom_ads
                        ads={ad_data_landscape}
                        rotationInterval={5000}
                        classname={"w-100"}
                      />
                    </div>

                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <div className="embed_sec ">
                          {game_data?.is_embed == 1 && (
                            <button
                              onClick={() => {
                                NiceModal.show(Embed_Modal, {
                                  game_name: game_data?.title,
                                });
                                // console.log(game_data?.title?.replace(/-/g, ""));
                                // navigate(
                                //   `/embed/${game_data?.title?.replace(/-/g, "")}`
                                // );
                              }}
                            >
                              <i class="fa-solid fa-code"></i> Embed
                            </button>
                          )}
                          {game_data?.is_share == 1 && (
                            <button
                              className="ms-2"
                              onClick={() => {
                                if (isMobile) {
                                  navigator
                                    .share({
                                      title: `Hey! I'm playing ${game_data?.title?.replace(
                                        /-/g,
                                        " "
                                      )} on Playzhub. Don't miss out on the excitement – check it out now:`,
                                      text: `Hey! I'm playing ${game_data?.title?.replace(
                                        /-/g,
                                        " "
                                      )} on Playzhub. Don't miss out on the excitement – check it out now:`,
                                      url: `https://playzhub.com/${game_data?.id}/${game_data?.title}`,
                                    })
                                    .then(() =>
                                      console.log("Successful share! 🎉")
                                    )
                                    .catch((err) => console.error(err));
                                } else {
                                  NiceModal.show(Share_Modal, {
                                    id: game_data?.id,
                                    game_name: game_data?.title,
                                  });
                                }
                              }}
                            >
                              <i class="fa-solid fa-share-nodes"></i> Share
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-12 gameInfo mb-4 mb-lg-0 ">
                        <h2>{game_data?.title?.replace(/-/g, " ")}</h2>
                      </div>
                      <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-5 col-md-6 gameInfo mt-0">
                        <table className="">
                          {/* <tr>
                        <td>Score 0-10</td>
                        <td>Get <img style={{ padding: "0 6px" }} src={star} alt="" /> 2</td>
                      </tr> */}
                          <tr>
                            <td>Rating:</td>
                            <td>{`${game_data?.admin_rating} (${
                              game_data?.total_likes + game_data?.total_dislikes
                            } votes)`}</td>
                          </tr>
                          <tr>
                            <td>Developer:</td>
                            <td>{game_data?.developer}</td>
                          </tr>
                          <tr>
                            <td>Released:</td>
                            <td>
                              {moment(game_data?.released).format("MMMM YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td>Technology:</td>
                            <td>{game_data?.technologies}</td>
                          </tr>
                          <tr>
                            <td>Platform:</td>
                            <td>{game_data?.platforms}</td>
                          </tr>
                          <tr>
                            <td>Graphics:</td>
                            <td>{game_data?.graphic}</td>
                          </tr>
                          {/* <tr>
                        <td>Preview:</td>
                        <td>
                          <ReactPlayer
                            className="videoThumb"
                            playing={true}
                            //controls
                            volume={0}
                            loop={true}
                            muted={true}
                            url={game_data?.video_upload}
                          />
                        </td>
                      </tr> */}
                          <tr>
                            <td>Points Conversion:</td>
                            <td>
                              <div
                                className="expandTable"
                                style={{ height: isExpanded ? "auto" : "75px" }}
                              >
                                <table className="m-0">
                                  <tr>
                                    <td>Score</td>
                                    <td>Point</td>
                                  </tr>
                                  {score_conversion.map((item, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {`${item?.min_value} - ${item?.max_value} `}
                                          </td>
                                          <td>{item?.equivalent_point}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </table>
                              </div>
                              <p
                                style={{
                                  color: "#f2641f",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={toggleExpand}
                              >
                                {isExpanded ? "View Less" : "View More"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Leaderboard Rules:</td>
                            <td
                              onClick={() =>
                                NiceModal.show(Rules_Modal, { navigate })
                              }
                              style={{
                                textDecoration: "underline",
                                color: "#f2641f",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              View Rules
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-5 col-md-5 gameInfo">
                        <h6 style={{ color: "#9698a7" }}>Game Preview:</h6>
                        <p>
                          <ReactPlayer
                            className="videoThumb"
                            playing={true}
                            //controls
                            volume={0}
                            loop={true}
                            muted={true}
                            url={game_data?.video_upload}
                            playsinline
                          />
                        </p>
                      </div>
                      <div className="col-12 gameInfo mb-2 mb-lg-0 mt-3">
                        <div
                          className="gameDes"
                          dangerouslySetInnerHTML={{
                            __html: cleanDescription,
                          }}
                        ></div>
                      </div>
                      <div className="col-12 mt-0">
                        <div className="row mt-lg-2 pt-lg-2">
                          <div
                            className={
                              // game_data?.game_orientation == "portrait"
                              //   ? "col-12 d-none"
                              //   :

                              "col-12 col-md-8 mb-3 d-block"
                            }
                          ></div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="catgoryTitle">Related Games</div>
                          </div>
                          <div className="col-12 mb-3 mob_swipe">
                            <Swiper
                              navigation={true}
                              modules={[Scrollbar, Navigation]}
                              spaceBetween={10}
                              slidesPerView={6}
                              // scrollbar={{ draggable: true }}
                              onSlideChange={() => console.log("slide change")}
                              onSwiper={(swiper) => console.log(swiper)}
                              breakpoints={{
                                320: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                480: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 6,
                                  spaceBetween: 10,
                                },
                              }}
                              className="featureSwiper"
                            >
                              {data?.map((item, index) => {
                                let is30DaysOrMore = false;
                                const targetDate = new Date(item?.last_updated);
                                const currentDate = new Date();
                                const timeDifference = currentDate - targetDate;
                                const daysDifference =
                                  timeDifference / (1000 * 3600 * 24);
                                if (daysDifference >= 30) {
                                  is30DaysOrMore = true;
                                } else {
                                  is30DaysOrMore = false;
                                }
                                //console.log(item);
                                return (
                                  <SwiperSlide>
                                    {item.tag == "new" && (
                                      <div className="new-badge">New</div>
                                    )}
                                    {!is30DaysOrMore ? (
                                      <div className="update-badge">
                                        Updated
                                      </div>
                                    ) : null}
                                    <div className="gameItem">
                                      <HoverVideoPlayer
                                        onClick={() =>
                                          navigate(
                                            `/game/${item?.id}/${item?.title}`,
                                            {
                                              state: {
                                                datas: item,
                                              },
                                            }
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                        className="connect_the_ball_ek11 gameThumb"
                                        // restartOnPaused
                                        overlayTransitionDuration={250}
                                        // videoSrc={
                                        //   item?.video_url || item?.video_upload
                                        // }
                                        pausedOverlay={
                                          <img
                                            src={item?.cover_picture}
                                            alt=""
                                            className="connect_the_ball_ek11 w-100 h-100"
                                          />
                                        }
                                        loadingOverlay={
                                          <div className="loading-overlay">
                                            <div className="loading-spinner" />
                                          </div>
                                        }
                                      />
                                      <div className="hoverText">
                                        {item?.title?.replace(/-/g, " ")}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* add section */}

                  <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3 position-relative">
                    <div className="col mb-3 related_game_sec">
                      <div className="row gx-2">
                        {all_game?.slice(0, 6).map((item, index) => {
                          //console.log(item);
                          let is30DaysOrMore = false;
                          const targetDate = new Date(item?.last_updated);
                          const currentDate = new Date();
                          const timeDifference = currentDate - targetDate;
                          const daysDifference =
                            timeDifference / (1000 * 3600 * 24);
                          if (daysDifference >= 30) {
                            is30DaysOrMore = true;
                          } else {
                            is30DaysOrMore = false;
                          }
                          return (
                            <div className="col-sm-6">
                              <div className="gameItem">
                                <HoverVideoPlayer
                                  onClick={() =>
                                    navigate(
                                      `/game/${item?.id}/${item?.title}`,
                                      {
                                        state: {
                                          datas: item,
                                        },
                                      }
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="connect_the_ball_ek11 gameThumb"
                                  // restartOnPaused
                                  overlayTransitionDuration={250}
                                  // videoSrc={item?.video_url || item?.video_upload}
                                  pausedOverlay={
                                    <img
                                      src={item?.cover_picture}
                                      alt=""
                                      className="connect_the_ball_ek11 w-100 h-100"
                                    />
                                  }
                                  loadingOverlay={
                                    <div className="loading-overlay">
                                      <div className="loading-spinner" />
                                    </div>
                                  }
                                />
                                {item.tag == "new" && (
                                  <div className="new-badge">New</div>
                                )}
                                {!is30DaysOrMore ? (
                                  <div className="update-badge">Updated</div>
                                ) : null}
                                <div className="hoverText">
                                  {item?.title?.replace(/-/g, " ")}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {!isMobile && (
                      <div className="add1">
                        <Custom_ads
                          ads={ad_data_portrait}
                          rotationInterval={5000}
                          classname={"screenshot_2024_09_25_191006 w-100"}
                        />
                      </div>
                    )}

                    <div className="col mb-3 related_game_sec">
                      <div className="row gx-2">
                        {all_game?.slice(6).map((item, index) => {
                          //console.log(item);
                          return (
                            <div className="col-sm-6">
                              <div className="gameItem">
                                <HoverVideoPlayer
                                  onClick={() =>
                                    navigate(
                                      `/game/${item?.id}/${item?.title}`,
                                      {
                                        state: {
                                          datas: item,
                                        },
                                      }
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="connect_the_ball_ek11 gameThumb"
                                  // restartOnPaused
                                  overlayTransitionDuration={250}
                                  // videoSrc={item?.video_url || item?.video_upload}
                                  pausedOverlay={
                                    <img
                                      src={item?.cover_picture}
                                      alt=""
                                      className="connect_the_ball_ek11 w-100 h-100"
                                    />
                                  }
                                  loadingOverlay={
                                    <div className="loading-overlay">
                                      <div className="loading-spinner" />
                                    </div>
                                  }
                                />
                                {item.tag == "new" && (
                                  <div className="new-badge">New</div>
                                )}
                                {item.last_updated && (
                                  <div className="update-badge">Updated</div>
                                )}
                                <div className="hoverText">
                                  {item?.title?.replace(/-/g, " ")}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="border_top" style={{ padding: "10px 0" }}>
                <div className="container-fluid ">
                  <div className="row">
                    <div className="col-12 text-center">
                      <p style={{ margin: 0 }}>
                        Copyright &copy;{" "}
                        <a
                          className="text-white"
                          href="https://thinktrekent.in/"
                          target="_blank"
                        >
                          Thinktrek Entertainment
                        </a>
                      </p>
                      <ul className="footerlist">
                        <li
                          onClick={() =>
                            navigate("/terms_and_conditions", {
                              state: {
                                flag: "t&c",
                                name: "Terms and Conditions",
                              },
                            })
                          }
                        >
                          <span>Terms & Conditions</span>
                        </li>
                        <li
                          onClick={() =>
                            navigate("/privacy_policy", {
                              state: {
                                flag: "policy",
                                name: "Privacy Policy",
                              },
                            })
                          }
                        >
                          <span>Privacy</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
              {/* PAGE CONTENT */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Game_screen;
