import React, { useState, useEffect } from "react";
import Service from "./service/apis";
// const Custom_ads = () => {
//   const [currentAd, setCurrentAd] = useState(null);
//   const [data, setData] = useState([]);
//   const [portraitAds, setPortraitAds] = useState([]);
//   const [currentAdIndex, setCurrentAdIndex] = useState(0);
//   useEffect(() => {
//     ads_fetch();
//   }, []);

//   const ads_fetch = () => {
//     let params = {
//       category_name: "",
//     };
//     Service.custom_ads(params)
//       .then((res) => {
//         // setData(res.data);
//         const filteredPortraitAds = res.data?.filter(
//           (ad) => ad.orientation === "landscape"
//         );
//         console.log(filteredPortraitAds);
//         setData(filteredPortraitAds);
//       })
//       .catch((e) => console.log(e));
//   };

//   const getRandomAd = () => {
//     return data[Math.floor(Math.random() * data.length)];
//   };

//   useEffect(() => {
//     // Set the initial ad
//     setCurrentAd(getRandomAd());

//     // Rotate the ad every 5 seconds (5000 ms)
//     const interval = setInterval(() => {
//       setCurrentAd(getRandomAd());
//     }, 3000); // Change ad every 5 seconds

//     // Cleanup on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   if (!currentAd) {
//     return <div>Loading ad...</div>;
//   }

//   return (
//     <div>
//       <a href={currentAd.url} target="_blank" rel="noopener noreferrer">
//         <img
//           className="screenshot_2024_09_25_191006 w-100"
//           src={currentAd.thumbnail}
//           alt="Rotating Ad"
//         />

//         {/* {orientation == "landscape" && (
//           <img
//             className="screenshot_2024_09_25_191006 w-100"
//             src={currentAd.thumbnail}
//             alt="Rotating Ad"
//           />
//         )} */}
//         {/* {orientation == "rectangular" && (
//           <img
//             className="screenshot_2024_09_25_191006 w-100"
//             src={currentAd.thumbnail}
//             alt="Rotating Ad"
//           />
//         )} */}
//       </a>
//     </div>
//   );
// };

// export default Custom_ads;

const Custom_ads = ({ ads, rotationInterval, classname }) => {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  // Rotate ads at regular intervals
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads?.length);
    }, rotationInterval);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [ads, rotationInterval]);

  const currentAd = ads?.[currentAdIndex];
  // if (!currentAd) {
  //   return <div>Loading ad...</div>;
  // }
  return (
    <div>
      {currentAd?.type == "image" && (
        <a
          href={currentAd?.website_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ cursor: "pointer" }}
            className={classname}
            src={currentAd?.thumbnail}
            alt={currentAd?.website_url || "Ad"}
          />
        </a>
      )}
      {currentAd?.type == "video" && (
        <a
          href={currentAd?.website_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <video className={classname} autoPlay muted loop playsInline>
            <source src={currentAd?.url} type="video/mp4" />
          </video>
        </a>
      )}
    </div>
  );
};

export default Custom_ads;
