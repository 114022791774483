// import React, { useState, useEffect, useRef } from "react";
// import "./ThumbnailVideoPlayer.css"; // Import your CSS
// import { Link, useNavigate } from "react-router-dom";

// const ThumbnailVideoPlayer = ({ thumbnails }) => {
//   const navigate = useNavigate();
//   const [visibleVideos, setVisibleVideos] = useState({});
//   const videoRefs = useRef([]);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
//   const [hoveredVideoIndex, setHoveredVideoIndex] = useState(null);
//   const [turnOnAutoVideoPlay, setTunOnAutoVideoPlay] = useState(1);
//   const [is30DaysOrMore, setIs30DaysOrMore] = useState(false);

//   const handleIntersection = (entries) => {
//     entries.forEach((entry) => {
//       const index = entry.target.dataset.index;

//       if (entry.isIntersecting) {
//         // Thumbnail is visible, play the video
//         setVisibleVideos((prev) => ({ ...prev, [index]: true }));
//       } else {
//         // Thumbnail is out of view, pause the video
//         setVisibleVideos((prev) => ({ ...prev, [index]: false }));
//       }
//     });
//   };
//   const playRandomVideo = () => {
//     console.log("Play Random Video");
//     const visibleIndexes = Object.keys(visibleVideos).filter(
//       (index) => visibleVideos[index]
//     );
//     if (visibleIndexes.length > 0) {
//       const randomIndex =
//         visibleIndexes[Math.floor(Math.random() * visibleIndexes.length)];
//       setCurrentVideoIndex(randomIndex); // Set the new video to play
//     }
//   };

//   const handleMouseEnter = (index) => {
//     console.log("Mouse Enter");
//     setCurrentVideoIndex(null);
//     setTunOnAutoVideoPlay(0);
//     setHoveredVideoIndex(index); // Set the hovered video to be played
//   };

//   const handleMouseLeave = () => {
//     console.log("Mouse Leave");
//     setHoveredVideoIndex(null); // Reset hovered video
//     setTunOnAutoVideoPlay(1);
//   };
//   //   useEffect(() => {
//   //     const interval = setInterval(() => {
//   //       const randomIndex = Math.floor(Math.random() * thumbnails.length);
//   //       setCurrentVideoIndex(randomIndex);
//   //     }, 5000); // Change video every 5 seconds

//   //     return () => clearInterval(interval); // Cleanup on component unmount
//   //   }, [thumbnails.length]);

//   useEffect(() => {
//     const observer = new IntersectionObserver(handleIntersection, {
//       root: null, // relative to the viewport
//       rootMargin: "0px", // Margin around the viewport to trigger early detection
//       threshold: 0.5, // Trigger when 50% of the thumbnail is visible
//     });

//     // Observe each thumbnail
//     videoRefs.current.forEach((ref) => observer.observe(ref));

//     return () => {
//       // Cleanup observers on unmount
//       observer.disconnect();
//     };
//   }, [thumbnails]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       console.log("Auto Video Play Status ", turnOnAutoVideoPlay);
//       if (turnOnAutoVideoPlay === 1) {
//         console.log("Auto Video Play turned on...Play next");
//         playRandomVideo();
//       } else {
//         console.log("Auto video play turned off");
//       }
//     }, 5000); // Change video every 5 seconds (or adjust as needed)

//     return () => clearInterval(interval);
//   }, [visibleVideos, turnOnAutoVideoPlay]);

//   // console.log("Visible Thumbnails", visibleVideos);

//   const checkDateDifference = (date) => {
//     // The date to compare with (2024-11-07)
//     const targetDate = new Date(date);
//     // Get the current date
//     const currentDate = new Date();
//     // Calculate the difference in time (in milliseconds)
//     const timeDifference = currentDate - targetDate;
//     // Convert the time difference from milliseconds to days
//     const daysDifference = timeDifference / (1000 * 3600 * 24);
//     // Check if the difference is 30 days or more
//     if (daysDifference >= 30) {
//       setIs30DaysOrMore(true);
//     } else {
//       setIs30DaysOrMore(false);
//     }
//   };

//   return (
//     <div className="row gx-3 mb-3 mt-3">
//       {thumbnails?.map((thumbnail, index) => {
//         let is30DaysOrMore = false;
//         const targetDate = new Date(thumbnail?.last_updated);
//         const currentDate = new Date();
//         const timeDifference = currentDate - targetDate;
//         const daysDifference = timeDifference / (1000 * 3600 * 24);
//         if (daysDifference >= 30) {
//           is30DaysOrMore = true;
//         } else {
//           is30DaysOrMore = false;
//         }
//         // console.log(is30DaysOrMore);
//         return (
//           <div className="col-6 col-sm-2 position-relative">
//             <div
//               className="gameItem"
//               key={index}
//               onMouseEnter={() => handleMouseEnter(index)} // Trigger on hover
//               onMouseLeave={handleMouseLeave}
//             >
//               <img
//                 style={{ cursor: "pointer" }}
//                 onClick={() =>
//                   navigate(`/game/${thumbnail?.id}/${thumbnail?.title}`, {
//                     state: {
//                       datas: thumbnail,
//                       flag: "home",
//                     },
//                   })
//                 }
//                 src={thumbnail.cover_picture}
//                 alt={`Thumbnail ${index}`}
//                 className="connect_the_ball_ek11 gameThumb"
//               />

//               <div
//                 ref={(el) => (videoRefs.current[index] = el)}
//                 data-index={index}
//               >
//                 {visibleVideos[index] &&
//                   (currentVideoIndex === String(index) ||
//                     hoveredVideoIndex === index) && (
//                     <video
//                       src={thumbnail?.video_url || thumbnail?.video_upload}
//                       autoPlay
//                       muted
//                       loop
//                       className="thumbnail-video"
//                     />
//                   )}
//               </div>
//               <div className="hoverText">{thumbnail?.title}</div>
//             </div>

//             {thumbnail?.tag == "new" && <div className="new-badge">New</div>}
//             {!is30DaysOrMore ? (
//               <div className="update-badge">Updated</div>
//             ) : null}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default ThumbnailVideoPlayer;

// 60-70% autoplay
// import React, { useState, useEffect, useRef } from "react";
// import "./ThumbnailVideoPlayer.css"; // Import your CSS
// import { Link, useNavigate } from "react-router-dom";

// const ThumbnailVideoPlayer = ({ thumbnails }) => {
//   const navigate = useNavigate();
//   const [visibleVideos, setVisibleVideos] = useState({});
//   const videoRefs = useRef([]);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
//   const [hoveredVideoIndex, setHoveredVideoIndex] = useState(null);
//   const [turnOnAutoVideoPlay, setTunOnAutoVideoPlay] = useState(1);
//   const [is30DaysOrMore, setIs30DaysOrMore] = useState(false);

//   const handleIntersection = (entries) => {
//     entries.forEach((entry) => {
//       const index = entry.target.dataset.index;

//       if (entry.isIntersecting) {
//         // Thumbnail is visible, play the video
//         setVisibleVideos((prev) => ({ ...prev, [index]: true }));
//       } else {
//         // Thumbnail is out of view, pause the video
//         setVisibleVideos((prev) => ({ ...prev, [index]: false }));
//       }
//     });
//   };

//   const playRandomVideo = () => {
//     const visibleIndexes = Object.keys(visibleVideos).filter(
//       (index) => visibleVideos[index]
//     );
//     if (visibleIndexes.length > 0) {
//       const randomIndex =
//         visibleIndexes[Math.floor(Math.random() * visibleIndexes.length)];
//       setCurrentVideoIndex(randomIndex); // Set the new video to play
//     }
//   };

//   const handleMouseEnter = (index) => {
//     setCurrentVideoIndex(null);
//     setTunOnAutoVideoPlay(0);
//     setHoveredVideoIndex(index); // Set the hovered video to be played
//   };

//   const handleMouseLeave = () => {
//     setHoveredVideoIndex(null); // Reset hovered video
//     setTunOnAutoVideoPlay(1);
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(handleIntersection, {
//       root: null, // relative to the viewport
//       rootMargin: "0px", // Margin around the viewport to trigger early detection
//       threshold: 0.5, // Trigger when 50% of the thumbnail is visible
//     });

//     // Observe each thumbnail
//     videoRefs.current.forEach((ref) => observer.observe(ref));

//     return () => {
//       // Cleanup observers on unmount
//       observer.disconnect();
//     };
//   }, [thumbnails]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (turnOnAutoVideoPlay === 1) {
//         playRandomVideo();
//       }
//     }, 5000); // Change video every 5 seconds (or adjust as needed)

//     return () => clearInterval(interval);
//   }, [visibleVideos, turnOnAutoVideoPlay]);

//   // Function to move to the next video after 60-70% of the current video duration
//   const handleVideoTimeUpdate = (index, videoElement) => {
//     if (videoElement) {
//       const videoDuration = videoElement.duration;
//       const videoCurrentTime = videoElement.currentTime;

//       // Check if 60-70% of the video duration has passed
//       if (videoCurrentTime >= videoDuration * 0.6) {
//         // After 60-70% duration, move to next video
//         const nextIndex = (parseInt(index) + 1) % thumbnails.length; // Loop to first video after the last one
//         setCurrentVideoIndex(nextIndex.toString());
//       }
//     }
//   };

//   return (
//     <div className="row gx-3 mb-3 mt-3">
//       {thumbnails?.map((thumbnail, index) => {
//         let is30DaysOrMore = false;
//         const targetDate = new Date(thumbnail?.last_updated);
//         const currentDate = new Date();
//         const timeDifference = currentDate - targetDate;
//         const daysDifference = timeDifference / (1000 * 3600 * 24);
//         if (daysDifference >= 30) {
//           is30DaysOrMore = true;
//         } else {
//           is30DaysOrMore = false;
//         }

//         return (
//           <div className="col-6 col-sm-2 position-relative" key={index}>
//             <div
//               className="gameItem"
//               onMouseEnter={() => handleMouseEnter(index)} // Trigger on hover
//               onMouseLeave={handleMouseLeave}
//             >
//               <img
//                 style={{ cursor: "pointer" }}
//                 onClick={() =>
//                   navigate(`/game/${thumbnail?.id}/${thumbnail?.title}`, {
//                     state: {
//                       datas: thumbnail,
//                       flag: "home",
//                     },
//                   })
//                 }
//                 src={thumbnail.cover_picture}
//                 alt={`Thumbnail ${index}`}
//                 className="connect_the_ball_ek11 gameThumb"
//               />

//               <div
//                 ref={(el) => (videoRefs.current[index] = el)}
//                 data-index={index}
//               >
//                 {visibleVideos[index] &&
//                   (currentVideoIndex === String(index) ||
//                     hoveredVideoIndex === index) && (
//                     <video
//                       src={thumbnail?.video_url || thumbnail?.video_upload}
//                       autoPlay
//                       muted
//                       loop
//                       className="thumbnail-video"
//                       onTimeUpdate={(e) =>
//                         handleVideoTimeUpdate(index, e.target)
//                       }
//                     />
//                   )}
//               </div>
//               <div className="hoverText">{thumbnail?.title}</div>
//             </div>

//             {thumbnail?.tag == "new" && <div className="new-badge">New</div>}
//             {!is30DaysOrMore ? (
//               <div className="update-badge">Updated</div>
//             ) : null}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default ThumbnailVideoPlayer;

import React, { useState, useEffect, useRef } from "react";
import "./ThumbnailVideoPlayer.css"; // Import your CSS
import { Link, useNavigate } from "react-router-dom";

const ThumbnailVideoPlayer = ({ thumbnails }) => {
  const navigate = useNavigate();
  const [visibleVideos, setVisibleVideos] = useState({});
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [hoveredVideoIndex, setHoveredVideoIndex] = useState(null);
  const [turnOnAutoVideoPlay, setTunOnAutoVideoPlay] = useState(1);
  const [is30DaysOrMore, setIs30DaysOrMore] = useState(false);
  const [playedVideos, setPlayedVideos] = useState([]); // New state to track played videos

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const index = entry.target.dataset.index;

      if (entry.isIntersecting) {
        // Thumbnail is visible, play the video
        setVisibleVideos((prev) => ({ ...prev, [index]: true }));
      } else {
        // Thumbnail is out of view, pause the video
        setVisibleVideos((prev) => ({ ...prev, [index]: false }));
      }
    });
  };

  const playRandomVideo = () => {
    const visibleIndexes = Object.keys(visibleVideos).filter(
      (index) => visibleVideos[index] && !playedVideos.includes(index) // Exclude played videos
    );
    if (visibleIndexes.length > 0) {
      const randomIndex =
        visibleIndexes[Math.floor(Math.random() * visibleIndexes.length)];
      setCurrentVideoIndex(randomIndex); // Set the new video to play
      setPlayedVideos((prev) => [...prev, randomIndex]); // Add the video to the played list
    }
  };

  const handleMouseEnter = (index) => {
    setCurrentVideoIndex(null);
    setTunOnAutoVideoPlay(0);
    setHoveredVideoIndex(index); // Set the hovered video to be played
  };

  const handleMouseLeave = () => {
    setHoveredVideoIndex(null); // Reset hovered video
    setTunOnAutoVideoPlay(1);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // relative to the viewport
      rootMargin: "0px", // Margin around the viewport to trigger early detection
      threshold: 0.5, // Trigger when 50% of the thumbnail is visible
    });

    // Observe each thumbnail
    videoRefs.current.forEach((ref) => observer.observe(ref));

    return () => {
      // Cleanup observers on unmount
      observer.disconnect();
    };
  }, [thumbnails]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (turnOnAutoVideoPlay === 1) {
        playRandomVideo();
      }
    }, 2000); // Change video every 5 seconds (or adjust as needed)

    return () => clearInterval(interval);
  }, [visibleVideos, turnOnAutoVideoPlay, playedVideos]);

  // Function to move to the next video after 60-70% of the current video duration
  const handleVideoTimeUpdate = (index, videoElement) => {
    if (videoElement) {
      const videoDuration = videoElement.duration;
      const videoCurrentTime = videoElement.currentTime;

      // Check if 60-70% of the video duration has passed
      if (videoCurrentTime >= videoDuration * 0.6) {
        // After 60-70% duration, move to next video
        const nextIndex = (parseInt(index) + 1) % thumbnails.length; // Loop to first video after the last one
        setCurrentVideoIndex(nextIndex.toString());
      }
    }
  };

  return (
    <div className="row gx-2 mb-3 mt-1">
      {thumbnails?.map((thumbnail, index) => {
        let is30DaysOrMore = false;
        const targetDate = new Date(thumbnail?.last_updated);
        const currentDate = new Date();
        const timeDifference = currentDate - targetDate;
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        if (daysDifference >= 30) {
          is30DaysOrMore = true;
        } else {
          is30DaysOrMore = false;
        }

        return (
          <div className="col-6 col-sm-2 position-relative" key={index}>
            <div
              className="gameItem"
              onMouseEnter={() => handleMouseEnter(index)} // Trigger on hover
              onMouseLeave={handleMouseLeave}
            >
              <img
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/game/${thumbnail?.id}/${thumbnail?.title}`, {
                    state: {
                      datas: thumbnail,
                      flag: "home",
                    },
                  })
                }
                src={thumbnail.cover_picture}
                alt={`Thumbnail ${index}`}
                className="connect_the_ball_ek11 gameThumb"
              />

              <div
                ref={(el) => (videoRefs.current[index] = el)}
                data-index={index}
              >
                {visibleVideos[index] &&
                  (currentVideoIndex === String(index) ||
                    hoveredVideoIndex === index) && (
                    <video
                      src={thumbnail?.video_url || thumbnail?.video_upload}
                      autoPlay
                      muted
                      loop
                      className="thumbnail-video"
                      onTimeUpdate={(e) =>
                        handleVideoTimeUpdate(index, e.target)
                      }
                    />
                  )}
              </div>
              <div className="hoverText">
                {thumbnail?.title?.replace(/-/g, " ")}
              </div>
            </div>

            {thumbnail?.tag == "new" && <div className="new-badge">New</div>}
            {!is30DaysOrMore ? (
              <div className="update-badge">Updated</div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ThumbnailVideoPlayer;
