import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Service from "./service/apis";
import {
  Progress,
  Divider,
  Avatar,
  AvatarGroup,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  Tooltip,
  Whisper,
} from "rsuite";
import { analytics } from "../src/firebase";
import { logEvent } from "firebase/analytics";
import { useSelector, useDispatch } from "react-redux";
import {
  ACTIVE_CAT,
  ACTIVE_INDEX,
  ACTIVE_STATIC,
  ACTIVE_TECH,
} from "./store/Types";
import buildInfo from "./buildInfo";
import Avatar_data from "./avatars.json";

const buildDate = new Date(buildInfo.buildDate);
const NavigationBar = ({ isActive, username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let active_tech = useSelector((state) => state.activeTechnology);
  let active_cat = useSelector((state) => state.activeCategory);
  let active_static = useSelector((state) => state.activeStatic);
  let profile_data = useSelector((state) => state.profile_data);
  let cookies = new Cookies();
  const network_details = cookies.get("network_details");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [stackList, setStackList] = useState([]);
  const [all_game, setAll_Game] = useState([]);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [score, setScore] = useState("");
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const wrapperRef = useRef(null);
  // const [username, setUsername] = useState("");

  useEffect(() => {
    category_techology_list();
    return () => {
      category_techology_list();
    };
  }, []);

  const category_techology_list = () => {
    Service.category_technology_list()
      .then((res) => {
        //console.log(res.data);
        if (res?.status == 0) {
          setStackList([]);
        } else {
          setStackList(res.data);
        }
      })
      .catch((e) => console.log(e.message));
  };

  // const [Active, setIsActive] = useState(isActive);
  // console.log(Active);
  // const toggleMenu = () => {
  //   setIsActive(!Active);
  // };
  const [activeIndex, setActiveIndex] = useState(null); // State to track active list item
  const [activeColor, setActiveColor] = useState("home");
  const [activeTech, setActiveTech] = useState(null);

  const handleClick = (index) => {
    dispatch({
      type: ACTIVE_CAT,
      payload: index,
    });
    dispatch({
      type: ACTIVE_TECH,
      payload: null,
    });
    dispatch({
      type: ACTIVE_STATIC,
      payload: "",
    });
  };

  const handleActiveTech = (index) => {
    dispatch({
      type: ACTIVE_TECH,
      payload: index,
    });
    dispatch({
      type: ACTIVE_CAT,
      payload: null,
    });
    dispatch({
      type: ACTIVE_STATIC,
      payload: "",
    });
  };

  const handleIndex = (name) => {
    dispatch({
      type: ACTIVE_STATIC,
      payload: name,
    });
    dispatch({
      type: ACTIVE_CAT,
      payload: null,
    });
    dispatch({
      type: ACTIVE_TECH,
      payload: null,
    });
  };

  let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);
  let ACCESS_GUEST_USER = useSelector((state) => state.guest_token);

  const tooltip = (
    <Tooltip>
      As a guest you are agreeing to the terms that you are 13+ years of age. To
      get the advantages and special privilages of partcipating in the Weekly
      Leaderboard Challenges, you need to Login.
    </Tooltip>
  );

  return (
    <div className={`navigation ${isActive ? "active" : ""}`}>
      <p
        className="m-2 ps-5 d-flex align-items-center d-md-none"
        style={{ color: "#f2641f" }}
      >
        {ACCESS_TOKEN_USER && (
          <img
            onClick={() => navigate("/profile")}
            src={
              !profile_data?.profile_picture
                ? profile_data?.avatar_id
                  ? require(`../src/assets/avatars/${
                      Avatar_data[profile_data?.avatar_id - 1]?.avatar_icon
                    }`)
                  : ""
                : profile_data?.profile_picture
            }
            style={{ height: "25px", width: "25px", borderRadius: "15px" }}
          />
        )}
        {ACCESS_TOKEN_USER && (
          <span onClick={() => navigate("/profile")} className="text-white">
            Hi! &nbsp;
          </span>
        )}
        {ACCESS_TOKEN_USER
          ? profile_data?.full_name?.length > 17
            ? profile_data?.full_name?.substring(0, 17 - 1) + ""
            : profile_data?.full_name
          : ""}
        {ACCESS_TOKEN_USER && (
          <i
            onClick={() => navigate("/profile")}
            class="fa-solid fa-angle-right"
          ></i>
        )}
        {/* {!ACCESS_TOKEN_USER && (
          <Whisper
            placement="bottom"
            controlId="control-id-click"
            trigger="click"
            speaker={tooltip}
          >
            <img
              className="ms-2"
              src={require("../src/assets/info-icon.png")}
              width={15}
              alt=""
            />
          </Whisper>
        )} */}
      </p>
      <ul>
        <li
          className={active_static === "home" && "active_sidebar"}
          style={{
            cursor: "pointer",
            // background: active_static === "home" ? "#F2641F" : "",
          }}
          onClick={() => {
            navigate("/");
            handleIndex("home");
          }}
        >
          <span className="icon">
            <img
              // style={{ cursor: "pointer" }}
              src={require("../src/home_screen/skins/home.png")}
            />
          </span>
          <span
            className="title"
            style={{
              color: active_static === "home" ? "#F2641F" : "",
            }}
          >
            Home
          </span>
        </li>
        <li
          className={active_static === "new_game" && "active_sidebar"}
          style={{
            cursor: "pointer",
            //background: active_static === "new_game" ? "#F2641F" : "",
          }}
          onClick={() => {
            handleIndex("new_game");
            navigate("/new_games", {
              state: {
                flag: "new_game",
                name: "New Game",
              },
            });
          }}
        >
          <span className="icon">
            <img
              style={{ cursor: "pointer" }}
              src={require("../src/home_screen/skins/star.png")}
            />
          </span>
          <span
            className="title"
            style={{
              color: active_static === "new_game" ? "#F2641F" : "",
            }}
          >
            New Games
          </span>
        </li>
        <li
          className={active_static === "recent" && "active_sidebar"}
          style={{
            cursor: "pointer",
            // background: active_static === "recent" ? "#F2641F" : "",
          }}
          onClick={() => {
            handleIndex("recent");

            navigate("/recent_games", {
              state: {
                flag: "recent_game",
                name: "Recently Played",
              },
            });
          }}
        >
          <span className="icon">
            <img
              style={{ cursor: "pointer" }}
              src={require("../src/home_screen/skins/recent.png")}
            />
          </span>
          <span
            className="title"
            style={{
              color: active_static === "recent" ? "#F2641F" : "",
            }}
          >
            Recently Played
          </span>
        </li>
        <li
          className={active_static === "updated" && "active_sidebar"}
          style={{
            cursor: "pointer",
            //background: active_static === "updated" ? "#F2641F" : "",
          }}
          onClick={() => {
            handleIndex("updated");

            navigate("/updated", {
              state: {
                flag: "updated",
                name: "Updated",
              },
            });
          }}
        >
          <span className="icon">
            <img
              style={{ cursor: "pointer" }}
              src={require("../src/home_screen/skins/updated.png")}
            />
          </span>
          <span
            className="title"
            style={{
              color: active_static === "updated" ? "#F2641F" : "",
            }}
          >
            Updated
          </span>
        </li>
      </ul>

      <ul className="mt-2">
        {stackList?.categories?.map((item, index) => {
          //console.log(item.game_img);
          return (
            <>
              {item?.status == "active" && (
                <li
                  key={index}
                  className={active_cat === index && "active_sidebar"}
                  style={{
                    cursor: "pointer",
                    //background: active_cat === index ? "#F2641F" : "",
                  }}
                  onClick={() => {
                    logEvent(analytics, "games_most_categories_visited", {
                      category_id: item?.category_id_,
                      category_name: item?.category_name,
                    });
                    logEvent(analytics, "mostgames_categories_countries", {
                      category_name: item?.category_name,
                      country: network_details?.country_name,
                      city: network_details?.city,
                    });
                    navigate(
                      `/category/${item?.category_id}/${item?.category_name}`,
                      {
                        state: {
                          datas: item,
                          name: item?.category_name,
                          flag: "category",
                        },
                      }
                    );

                    handleClick(index);
                  }}
                >
                  <span className="icon">
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      src={item?.icon}
                      className="color1"
                    />
                  </span>
                  <span
                    className="title"
                    style={{
                      color: active_cat === index ? "#F2641F" : "",
                    }}
                  >
                    {item?.category_name}
                  </span>
                </li>
              )}
            </>
          );
        })}
      </ul>

      <ul className="mt-2">
        {stackList?.technologies?.map((item, index) => {
          //console.log(item.game_img);
          return (
            <>
              {item?.status == "active" && (
                <li
                  className={active_tech === index && "active_sidebar"}
                  style={{
                    cursor: "pointer",
                    //background: active_tech === index ? "#F2641F" : "",
                  }}
                  onClick={() => {
                    logEvent(analytics, "games_most_technologies_visited", {
                      technology_id: item?.technology_id,
                      technology_name: item?.technology_name,
                    });
                    logEvent(analytics, "mostgames_technology_countries", {
                      technology_name: item?.technology_name,
                      country: network_details?.country_name,
                      city: network_details?.city,
                    });
                    navigate(
                      `/technology/${item?.technology_id}/${item?.technology_name}`,
                      {
                        state: {
                          datas: item,
                          name: item?.technology_name,
                          flag: "technology",
                        },
                      }
                    );

                    handleActiveTech(index);
                  }}
                >
                  <span className="icon">
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      src={item?.icon}
                      className="color1"
                    />
                  </span>
                  <span
                    className="title"
                    style={{
                      color: active_tech === index ? "#F2641F" : "",
                    }}
                  >
                    {item?.technology_name}
                  </span>
                </li>
              )}
            </>
          );
        })}
      </ul>

      <ul className="otherLink">
        <li
          onClick={() =>
            navigate("/terms_and_conditions", {
              state: {
                flag: "t&c",
                name: "Terms and Conditions",
              },
            })
          }
        >
          <span>Terms & Conditions</span>
        </li>
        <li
          onClick={() =>
            navigate("/privacy_policy", {
              state: {
                flag: "policy",
                name: "Privacy Policy",
              },
            })
          }
        >
          <span>Privacy</span>
        </li>
        <li>
          <span
            style={{ color: "#3d4063" }}
          >{`V${buildInfo.buildVersion}`}</span>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
