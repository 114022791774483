import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home_screen/home.jsx";
import Game from "./game_screen/game_screen.jsx";
import Game_stack from "./game_stack/game_stack.jsx";
import "rsuite/dist/rsuite-no-reset.min.css";
import Cookies from "universal-cookie";
import axios from "axios";
import Service from "./service/apis.js";
import Cookie_reset from "./cookie_reset.jsx";
import Policy from "./t&c/policy.jsx";
import Terms from "./t&c/terms.jsx";
import Leaderboard from "./leaderboard/leaderboard.jsx";
import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Divider, Input, Avatar } from "rsuite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Radio, RadioGroup } from "rsuite";
import moment from "moment";
import {
  Uploader,
  Message,
  Loader,
  useToaster,
  SelectPicker,
  AvatarGroup,
  DatePicker,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { alert, confirm, prompt } from "@rsuite/interactions";
import OtpInput from "react-otp-input";
import Avatar_data from "./avatars.json";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import { TiTick } from "react-icons/ti";
import { analytics } from "./firebase.js";
import { logEvent } from "firebase/analytics";
import useStore from "./service/zustand.js";
import History from "./history/history.jsx";
import Search_game from "./search_game/search_game.jsx";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "../src/store/Store.js";
import Profile from "./profile/Profile.jsx";
import Embed from "./embed/Embed.jsx";
import reduxStore from "./store/Store.js";

function App() {
  const { store, persistor } = reduxStore();
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NiceModal.Provider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} index />
              <Route path="/game/:id/:name" element={<Game />} />
              <Route path="/:name/:id/:name" element={<Game_stack />} />
              <Route path="/new_games" element={<Game_stack />} />
              <Route path="/recent_games" element={<Game_stack />} />
              <Route path="/updated" element={<Game_stack />} />
              <Route path="/reset" element={<Cookie_reset />} />
              <Route path="/terms_and_conditions" element={<Terms />} />
              <Route path="/privacy_policy" element={<Policy />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/playing_history" element={<History />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/embed/:name" element={<Embed />} />
              <Route path="/search" element={<Search_game />} />
            </Routes>
          </BrowserRouter>
        </NiceModal.Provider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
