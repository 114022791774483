import React, { useState } from "react";
import { useParams } from "react-router-dom";

const Embed = () => {
  const { name } = useParams();
  const [play, setPlay] = useState(false);
  return (
    <>
      <div className="scroll_issue h-100" style={{overflow:'hidden'}}>
        <div className=" h-100">
          <div className="row  h-100">
            <div className=" h-100">
                
                  <div className="game_lt_panel  h-100">
                    {!play && (
                      <div className="gameOverlay d-flex align-items-center justify-content-center flex-column">
                        <h2>{name}</h2>
                        <div
                          onClick={() => {
                            setPlay(true);
                          }}
                          className="playBtn d-flex align-items-center justify-content-between"
                        >
                          Play Now
                          <img
                            src={require("../game_screen/skins1/play_button.png")}
                            className={"play_button_landscape"}
                          />
                        </div>
                      </div>
                    )}

                    <iframe
                      //   className={"icon_003_landscape"}
                      src={`https://game.redappletech.info/ThinkTrek_Games/${name}`}
                      allowFullScreen
                      style={{
                        aspectRatio: "16/9",
                        width: "100%",
                        height: "100%",
                      }}
                      scrolling="no"
                    />

                    <div className="iframeCtrl d-flex align-items-center justify-content-between"
                      style={{bottom: "0"}}
                    >
                      <div className="gameName">
                        {/* {game_data?.title?.replace(/-/g, " ")} */}
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="me-2 me-sm-2 me-lg-4"></span>

                        <span className="me-2 me-sm-2 me-lg-4"></span>
                        <span className="me-2 me-sm-2 me-lg-4"></span>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            // onClick={fullscreen}
                            src={require("../game_screen/skins1/path_26439.png")}
                            className={
                              // game_data?.game_orientation == "portrait"
                              //   ? "path_26439"
                              //   :
                              "path_26439_landscape"
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                <div className="col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-3 position-relative">
                  <div className="col mb-3 related_game_sec">
                    <div className="row gx-2"></div>
                  </div>

                  <div className="col mb-3 related_game_sec">
                    <div className="row gx-2"></div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Embed;
