// PngSequenceAnimation.js
import React, { useState, useEffect, useCallback } from "react";

const PngSequenceAnimation = ({ frameCount, frameRate, props }) => {
  const [frameIndex, setFrameIndex] = useState(0);

  useEffect(() => {
    // Set the interval to update the frameIndex at the specified frameRate
    const interval = setInterval(() => {
      // Check if we're on the last frame; if so, stop the animation
      if (frameIndex + 1 === frameCount) {
        clearInterval(interval); // Stop the animation
        props(true);
      } else {
        setFrameIndex((prevIndex) => prevIndex + 1);
      }
    }, 1000 / frameRate);

    return () => {
      clearInterval(interval);
      // props(frameCount);
    }; // Clean up the interval when the component unmounts
  }, [frameCount, frameRate, frameIndex]);

  // Construct the image name based on the frame index
  const imageName = `Playzhub_05_8_forAnimation-Type_1_${String(
    frameIndex + 1
  ).padStart(2, "0")}.png`;

  return (
    <div className="logoAnimation">
      <img
        src={require(`/src/assets/png/${imageName}`)}
        alt="PNG Sequence"
        className="w-100"
      />
    </div>
  );
};
// App.js
// import React, { useState, useEffect } from 'react';

// const AnimationManager = {
//   frameIndex: 0,
//   interval: null,
//   startAnimation: (frameCount, frameRate, onFrameChange) => {
//     // Prevent starting a new interval if already running
//     if (AnimationManager.interval) return;

//     AnimationManager.interval = setInterval(() => {
//       // Check if we're on the last frame; if so, stop the animation
//       if (AnimationManager.frameIndex + 1 === frameCount) {
//         clearInterval(AnimationManager.interval); // Stop the animation
//         AnimationManager.interval = null;
//       } else {
//         AnimationManager.frameIndex += 1;
//         onFrameChange(AnimationManager.frameIndex); // Update frame
//       }
//     }, 1000 / frameRate);
//   },

//   stopAnimation: () => {
//     clearInterval(AnimationManager.interval);
//     AnimationManager.interval = null;
//   },

//   resetAnimation: () => {
//     AnimationManager.frameIndex = 0;
//   }
// };

// function App() {
//   const [frameIndex, setFrameIndex] = useState(0);

//   useEffect(() => {
//     const frameCount = 30; // Total number of frames in your sequence
//     const frameRate = 24;  // Frames per second (FPS)

//     // Reset animation every time the page is navigated to or component mounts
//     AnimationManager.resetAnimation();
//     AnimationManager.startAnimation(frameCount, frameRate, (newFrameIndex) => {
//       setFrameIndex(newFrameIndex); // Update the frame index in state
//     });

//     // Cleanup the animation when the component unmounts
//     return () => {
//       AnimationManager.stopAnimation();
//     };
//   }, []); // Empty dependency array ensures this effect runs only on mount

//   // Construct the image name based on the frame index
//   const imageName = `Playzhub_05_8_forAnimation-Type_1_${String(frameIndex + 1).padStart(2, '0')}.png`;

//   return (
//     <div className="App">
//       <h1>PNG Sequence Animation</h1>
//       <img
//         src={`images/${imageName}`}
//         alt="PNG Sequence"
//       />
//     </div>
//   );
// }

// export default App;

export default PngSequenceAnimation;
