import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import Cookies from "universal-cookie";
import { Scrollbar, Navigation, Grid } from "swiper/modules";
import { Input, InputGroup, MaskedInput, Loader } from "rsuite";
import Service from "../service/apis";
import Avatar_data from "../avatars.json";
import useStore from "../service/zustand";
import Header from "../Header";
import ThumbnailVideoPlayer from "../ThumbnailVideoPlayer";
import NavigationBar from "../NavigationBar";
import { useSelector } from "react-redux";
import Suggest_word from "../words.json";
import { Helmet } from "react-helmet";
const Home = () => {
  const navigate = useNavigate();
  let cookies = new Cookies();
  let username = useSelector((state) => state.Uname);
  const [data, setData] = useState([]);
  const [all_game, setAll_Game] = useState([]);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);

  const { profile_status_load, add_profile_load, token } = useStore();

  useOutsideAlerter(wrapperRef);

  // useEffect(() => {
  //   visitor_details();
  // }, [profile_status_load]);

  // const visitor_details = () => {
  //   Service.visitor_details()
  //     .then((res) => {
  //       let date = res?.data?.created_at;
  //       if (res.status == 1) {
  //         setFileInfo(res?.data?.profile_picture);
  //         setPercent(res?.data?.profile_completion_percentage);
  //         setAvatar_id(res?.data?.avatar_id);
  //         setUsername(res?.data?.full_name);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  // const handleInputChange = (event) => {
  //   // setSearch(event);
  //   console.log("home");
  //   //console.log(event.target);
  //   if (!event) {
  //     setVisible(false);
  //     // on_game_list();
  //   } else if (event) {
  //     setVisible(true);
  //   }
  //   let params = {
  //     query: event,
  //   };
  //   on_search(params);
  // };

  // const on_search = (params) => {
  //   Service.search(params)
  //     .then((res) => {
  //       // console.log(res.data);
  //       if (res?.status == 0) {
  //         setSearch_data([]);
  //       } else {
  //         setSearch_data(res?.data);
  //       }
  //     })
  //     .catch((e) => e.message);
  // };

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGames, setFilteredGames] = useState(all_game);
  const [filteredSearch, setFilteredSearch] = useState([]);

  const normalizeText = (text) => {
    return text.toLowerCase().replace(/[-\s]/g, "");
  };

  const handleInputChange = (event) => {
    const query = event;

    if (!event) {
      setVisible(false);
    } else if (event) {
      setVisible(true);
    }

    setSearchQuery(query);

    const queryTerms = query
      .toLowerCase()
      .split(",")
      .map((term) => term.trim())
      .filter((term) => term);

    const filtered_suggest = Suggest_word.filter((game) => {
      return queryTerms.some((term) => {
        return normalizeText(game.suggestions).includes(normalizeText(term));
      });
    });
    setFilteredSearch(filtered_suggest);

    const filtered = all_game.filter((game) => {
      return queryTerms.some((term) => {
        return (
          normalizeText(game.title).includes(normalizeText(term)) ||
          normalizeText(game.platform).includes(normalizeText(term)) ||
          normalizeText(game.technology).includes(normalizeText(term)) ||
          normalizeText(game.category).includes(normalizeText(term))
        );
      });
    });
    setFilteredGames(filtered);
  };

  useEffect(() => {
    on_game_list();
    all_game_list();
    // category_techology_list();
  }, []);

  const on_game_list = (params) => {
    setLoading(true);
    Service.category_wise_game_list(params)
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setData([]);
          setLoading(false);
        }
        // console.log(res.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((e) => e.message);
  };

  const all_game_list = (params) => {
    setLoading(true);
    Service.game_list(params)
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setAll_Game([]);
          setLoading(false);
        }
        // console.log(res.data);
        setAll_Game(res.data);
        setLoading(false);
        //checkDateDifference();
      })
      .catch((e) => e.message);
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const [visibleVideos, setVisibleVideos] = useState({});
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [hoveredVideoIndex, setHoveredVideoIndex] = useState(null);
  const [turnOnAutoVideoPlay, setTunOnAutoVideoPlay] = useState(1);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const index = entry.target.dataset.index;

      if (entry.isIntersecting) {
        // Thumbnail is visible, play the video
        setVisibleVideos((prev) => ({ ...prev, [index]: true }));
      } else {
        // Thumbnail is out of view, pause the video
        setVisibleVideos((prev) => ({ ...prev, [index]: false }));
      }
    });
  };
  const playRandomVideo = () => {
    console.log("Play Random Video");
    const visibleIndexes = Object.keys(visibleVideos).filter(
      (index) => visibleVideos[index]
    );
    if (visibleIndexes.length > 0) {
      const randomIndex =
        visibleIndexes[Math.floor(Math.random() * visibleIndexes.length)];
      setCurrentVideoIndex(randomIndex); // Set the new video to play
    }
  };

  const handleMouseEnter = (index) => {
    console.log("Mouse Enter");
    setCurrentVideoIndex(null);
    setTunOnAutoVideoPlay(0);
    setHoveredVideoIndex(index); // Set the hovered video to be played
  };

  const handleMouseLeave = () => {
    console.log("Mouse Leave");
    setHoveredVideoIndex(null); // Reset hovered video
    setTunOnAutoVideoPlay(1);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // relative to the viewport
      rootMargin: "0px", // Margin around the viewport to trigger early detection
      threshold: 0.5, // Trigger when 50% of the thumbnail is visible
    });

    // Observe each thumbnail
    videoRefs.current.forEach((ref) => observer.observe(ref));

    return () => {
      // Cleanup observers on unmount
      observer.disconnect();
    };
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Auto Video Play Status ", turnOnAutoVideoPlay);
      if (turnOnAutoVideoPlay === 1) {
        console.log("Auto Video Play turned on...Play next");
        playRandomVideo();
      } else {
        console.log("Auto video play turned off");
      }
    }, 5000); // Change video every 5 seconds (or adjust as needed)

    return () => clearInterval(interval);
  }, [visibleVideos, turnOnAutoVideoPlay]);

  return (
    // <NiceModal.Provider>
    <div className="scroll_mid_sec">
      <Header onPress={toggleMenu} status={isActive} />
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="PlayzHub is one of the most popular FREE Online Gaming Platforms that allows users to play free online games on PC / Computer, Mobile, Tablet etc. NO INSTALLATION, NO DOWNLOAD, PLAY FOR FREE NOW!"
          />
          <title>
            Play Free Games Online on PlayzHub | Free To Play Games for PC,
            Mobile & Tablet
          </title>
        </Helmet>
      </div>
      <div className="container-fluid pl-80 d-flex flex-column mh-100vh">
        <div className="row">
          {/* NAVIGATION */}

          <NavigationBar isActive={isActive} username={username} />
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-2">
            <div className="row d-block d-lg-none">
              <div
                className="col-12 d-flex justify-content-center"
                ref={wrapperRef}
              >
                <InputGroup>
                  <Input
                    value={searchQuery}
                    onChange={handleInputChange}
                    className=" search-box"
                    placeholder={"Search Game"}
                  />
                  <InputGroup.Addon style={{ top: "0px" }}>
                    <img src={require("./skins/search.png")} alt="" />
                  </InputGroup.Addon>
                </InputGroup>
                {visible && (
                  <div className="custom-picker-popup-mobile">
                    <div className="p-2">
                      {filteredSearch.slice(0, 4).map((item, index) => {
                        return (
                          <span
                            onClick={() => {
                              navigate("/search", {
                                state: {
                                  search: item.suggestions,
                                },
                              });
                            }}
                            style={{
                              display: "inline-block",
                              border: "1px solid #818A97",
                              borderRadius: "10px",
                              padding: "1px 4px",
                              margin: "2px",
                              cursor: "pointer",
                              color: "#818A97",
                            }}
                          >
                            {item?.suggestions}
                          </span>
                        );
                      })}
                    </div>
                    {filteredGames?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem"
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            //window.location.reload();
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item?.title?.replace(/-/g, " ")}
                            <div className="d-flex gameInfo">
                              <small>{item?.category}</small>&nbsp; | &nbsp;
                              <small>{item?.technology}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {data?.map((item, index) => {
              let banner = item.category_name;
              return (
                <>
                  <div className="catgoryTitle">
                    {/* {item.games.length == 0 ? null : item.category_name} */}
                    {item.category_name == "Featured" && item.category_name}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <>
                        {item.category_name == "Featured" && (
                          <Swiper
                            navigation={true}
                            modules={[Scrollbar, Navigation]}
                            spaceBetween={8}
                            slidesPerView={4}
                            // scrollbar={{ draggable: true }}
                            onSlideChange={() => console.log("slide change")}
                            onSwiper={(swiper) => console.log(swiper)}
                            breakpoints={{
                              320: {
                                slidesPerView: 1,
                                spaceBetween: 8,
                              },
                              480: {
                                slidesPerView: 1,
                                spaceBetween: 8,
                              },
                              640: {
                                slidesPerView: 1,
                                spaceBetween: 8,
                              },
                              768: {
                                slidesPerView: 3,
                                spaceBetween: 8,
                              },
                              1024: {
                                slidesPerView: 4,
                                spaceBetween: 8,
                              },
                              1600: {
                                slidesPerView: 5,
                                spaceBetween: 8,
                              },
                            }}
                            className="featureSwiper"
                          >
                            {loading ? (
                              <Loader
                                center
                                size="md"
                                content="Please wait..."
                              />
                            ) : (
                              item?.games?.map((item, index) => {
                                let is30DaysOrMore = false;
                                const targetDate = new Date(item?.last_updated);
                                const currentDate = new Date();
                                const timeDifference = currentDate - targetDate;
                                const daysDifference =
                                  timeDifference / (1000 * 3600 * 24);
                                if (daysDifference >= 30) {
                                  is30DaysOrMore = true;
                                } else {
                                  is30DaysOrMore = false;
                                }
                                return (
                                  <SwiperSlide>
                                    {item?.tag == "new" && (
                                      <div className="new-badge">New</div>
                                    )}
                                    {!is30DaysOrMore ? (
                                      <div className="update-badge">
                                        Updated
                                      </div>
                                    ) : null}
                                    <div
                                      className="gameItem"
                                      key={index}
                                      onMouseEnter={() =>
                                        handleMouseEnter(index)
                                      } // Trigger on hover
                                      onMouseLeave={handleMouseLeave}
                                    >
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigate(
                                            `/game/${item?.id}/${item?.title}`,
                                            {
                                              state: {
                                                datas: item,
                                                flag: "home",
                                              },
                                            }
                                          )
                                        }
                                        src={item.cover_picture}
                                        alt={`Thumbnail ${index}`}
                                        className="connect_the_ball_ek11 gameThumb"
                                      />

                                      <div
                                        ref={(el) =>
                                          (videoRefs.current[index] = el)
                                        }
                                        data-index={index}
                                      >
                                        {visibleVideos[index] &&
                                          (currentVideoIndex ===
                                            String(index) ||
                                            hoveredVideoIndex === index) && (
                                            <video
                                              src={
                                                item?.video_url ||
                                                item?.video_upload
                                              }
                                              autoPlay
                                              muted
                                              loop
                                              className="thumbnail-video"
                                            />
                                          )}
                                      </div>

                                      <div className="hoverText">
                                        {item?.title?.replace(/-/g, " ")}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                );
                              })
                            )}
                          </Swiper>
                        )}
                      </>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="catgoryTitle mt-3">{"All Games"}</div>
            <div className="row gx-2 mb-3 mt-1">
              {loading ? (
                <Loader center size="md" content="Please wait..." />
              ) : (
                <ThumbnailVideoPlayer thumbnails={all_game} />
              )}
            </div>
          </div>
          {/* PAGE CONTENT */}
        </div>

        <footer>
          <div className="container-fluid border_top">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                  <ul className="footerlist">
                    <li
                      onClick={() =>
                        navigate("/terms_and_conditions", {
                          state: {
                            flag: "t&c",
                            name: "Terms and Conditions",
                          },
                        })
                      }
                    >
                      <span>Terms & Conditions</span>
                    </li>
                    <li
                      onClick={() =>
                        navigate("/privacy_policy", {
                          state: {
                            flag: "policy",
                            name: "Privacy Policy",
                          },
                        })
                      }
                    >
                      <span>Privacy</span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    // </NiceModal.Provider>
  );
};

export default Home;
