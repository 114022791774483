import { create } from "zustand";

const useStore = create((set) => ({
  profile_status_load: false,
  token: null,
  status_guest: false,
  start_week_store: null,
  end_week_store: null,
  dislike_load: null,

  add_profile_load: (profile_status_load) => {
    set((state) => ({
      profile_status_load: {
        ...state.profile_status_load,
        profile_status_load,
      },
    }));
  },

  add_dislike_load: (dislike_load) => {
    set((state) => ({
      profile_status_load: {
        ...state.dislike_load,
        dislike_load,
      },
    }));
  },

  add_guest_token: (token) => {
    set((state) => ({
      token: {
        ...state.token,
        token,
      },
    }));
  },
  add_guest_status: (status_guest) => {
    set((state) => ({
      status_guest: {
        ...state.status_guest,
        status_guest,
      },
    }));
  },
  add_start_week: (start_week_store) => {
    set((state) => ({
      start_week_store: {
        ...state.start_week_store,
        start_week_store,
      },
    }));
  },
  add_end_week: (end_week_store) => {
    set((state) => ({
      end_week_store: {
        ...state.end_week_store,
        end_week_store,
      },
    }));
  },
}));

export default useStore;
