import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import NavigationBar from "../NavigationBar";
import Header from "../Header";
import { useSelector } from "react-redux";

const Policy = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const username = useSelector((state) => state.Uname);
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="scroll_issue">
      <Header onPress={toggleMenu} />
      <div className="container-fluid pl-80 d-flex flex-column mh-100vh">
        <div className="row scroll-section">
          {/* NAVIGATION */}
          <NavigationBar isActive={isActive} username={username} />
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center"></div>
            </div>
            <div className="row gx-2 terms_sec">
              <div className="catgoryTitle mb-3">
                <h3>{location?.state?.name}</h3>
                <p>Last updated: November 2024</p>
              </div>
              <div className="col text-white">
                <h4>Who we are</h4>
                <p>
                  We are{" "}
                  <b>
                    Playzhub.com, owned and operated by Thinktrek Entertainment
                    Private Limited
                  </b>
                  . We offer a free online casual gaming platform where you can
                  discover and enjoy a variety of web games in an easy and
                  accessible way. You can access our Platform via an account
                  (“Account”) or without.
                </p>
                <h5>Legal information about Playzhub</h5>
                <p>
                  We recognize the trust you place in us and take our
                  responsibility to protect your privacy seriously. This Privacy
                  Policy (this “Policy”) provides important details about how we
                  collect, process, disclose, retain, and protect your personal
                  data. Please review it carefully to understand your rights and
                  our obligations regarding your information.
                </p>
                <p>
                  The Platform is intended for visitors and users who are
                  thirteen (13) years of age (or the applicable minimum age in
                  your country) or older. If you are under thirteen (13) years
                  of age (or the applicable minimum age in your country), this
                  Platform is not intended for you. We do not knowingly collect
                  or solicit personal information from children under the age of
                  thirteen (13) (or the applicable minimum age in your country)
                  through our Platform. Playzhub.com does not allow personalised
                  advertising.
                </p>
                <p>
                  For information about the terms upon which we do business, you
                  should also read our Terms of Service.
                </p>

                <h4 className="mb-4">Office Location</h4>
                <h5>Playzhub located at:</h5>
                <p>
                  Thinktrek Entertainment Private Limited, 22/748, DDA Flats,
                  Kalkaji, New Delhi- 110019
                </p>

                <h4>What is Personal Data?</h4>
                <p>
                  Personal Data refers to any information about you that can be
                  used to identify you. This includes, for example, your name,
                  phone number, or any other information that can be linked to
                  you personally. It can also include data about the games you
                  play if we are able to associate it with your account on our
                  Platform (“Account”).
                </p>
                <p>
                  Playzhub.com, owned and operated by Thinktrek Entertainment
                  Private Limited strongly recommends that you do not use your
                  real name or share any other personal data while playing games
                  on our Platform, including but not limited to when
                  participating in multiplayer games.
                </p>
                <h4>From whom do we collect personal data?</h4>
                <p>
                  To operate our Platform, we may collect data from users and
                  visitors of the Platform, game developers, individuals who
                  provide us with their contact details, and people who reach
                  out to us via email or other means. As mentioned earlier in
                  Section 1.3, we do not collect personal data from children
                  under the age of thirteen (13) or the applicable minimum age
                  in your country. However, we may collect data from users who
                  are thirteen (13) years old or older, as well as from
                  developers and other individuals interacting with our
                  Platform.
                </p>
                <h4>What personal data does Playzhub.com process and why?</h4>
                <p>
                  Playzhub.com, owned and operated by Thinktrek Entertainment
                  Private Limited may collect the following Personal Data from
                  you:
                </p>

                <h5>
                  To register and authenticate your Account on the Platform
                </h5>
                <ul>
                  <li>
                    Personal Data: Phone number, name, age, gender, external
                    account, username, and password
                  </li>
                  <li>
                    Basis: The prior, express, free, specific, and informed
                    consent of you.
                  </li>
                  <li>Personalization of your game experience (Account)</li>
                  <li>
                    Personal Data: Username, device and connection data,
                    selected interests, gaming behavior, and feedback
                  </li>
                  <li>
                    Basis: The prior, express, free, specific, and informed
                    consent of you.
                  </li>
                </ul>

                <h5>
                  To respond to your questions or complaints, or to assist with
                  technical issues
                </h5>
                <ul>
                  <li>
                    Personal Data: Name, Phone Number, and any other information
                    you provide to us
                  </li>
                  <li>
                    Basis: Necessary for the exercise of our legitimate
                    interests, particularly to enhance the quality of our
                    Platform.
                  </li>
                </ul>

                <h5>To inform you of new functionalities of our Platform</h5>
                <ul>
                  <li>Personal Data: Username and phone number</li>
                  <li>
                    Basis: Necessary for the exercise of our legitimate
                    interests, in particular to communicate relevant information
                    about our Platform.
                  </li>
                </ul>

                <h5>
                  To analyse statistics about visitors to our Platform and games
                  to improve the Platform, the games, and the user experience
                </h5>
                <ul>
                  <li>
                    Personal Data: Data on visitors' behavior (usage & device
                    data) and in-game statistics
                  </li>
                  <li>
                    Basis: Necessary for the exercise of our legitimate
                    interests, particularly in collecting statistics about the
                    usage and performance of our Platform and games.
                  </li>
                </ul>
                <h5>To comply with legal obligations</h5>
                <ul>
                  <li>Personal Data: Data required by applicable law</li>
                  <li>Basis: Necessary to comply with a legal obligation.</li>
                </ul>

                <h5>
                  To prevent, detect, and combat fraud and other illegal or
                  unauthorized activities
                </h5>
                <ul>
                  <li>
                    Personal Data: Data required for detecting fraud and illegal
                    activities
                  </li>
                  <li>
                    Basis: Necessary for the exercise of our legitimate
                    interests, particularly the prevention of fraud and other
                    illegal activities.
                  </li>
                </ul>

                <h5>For marketing of Playzhub.com (e.g., newsletters)</h5>
                <ul>
                  <li>Personal Data: Username and Phone number</li>
                  <li>
                    Basis: The prior, express, free, specific, and informed
                    consent of you.
                  </li>
                </ul>

                <h5>To enable advertising on our Platform</h5>
                <ul>
                  <li>
                    Personal Data: Data gathered through cookies, web beacons,
                    or similar technologies, such as IP address, user IDs,
                    browser type, and operating system
                  </li>
                  <li>
                    Basis: The prior, express, free, specific, and informed
                    consent of you when you accept our Privacy and Cookie Policy
                    and confirm your ad preferences. This consent can be
                    withdrawn at any time. For more information, please refer to
                    ‘Manage Your Ad Preferences’ and our Cookie Policy.
                  </li>
                </ul>

                <h5>To fulfil our obligations to game developers</h5>
                <ul>
                  <li>
                    Personal Data: Email address, Phone number tax
                    identification number, bank account details, PayPal account,
                    preferred payment method
                  </li>
                  <li>Basis: Necessary for the performance of a contract.</li>
                </ul>

                <h5>To enable in-game purchases</h5>
                <ul>
                  <li>Personal Data: Data related to in-game purchases</li>
                  <li>
                    Playzhub.com, owned and operated by Thinktrek
                    Entertainment Private Limited does not collect specific
                    payment data when you make an in-game purchase; the payment
                    is processed by a third-party payment provider. We store and
                    link your in-game purchases to your Account.
                  </li>
                  <li>Basis: Necessary for the performance of a contract.</li>
                </ul>
                <h5>Registration of Account:</h5>
                <ul>
                  <li>
                    If you register an Account with us, you explicitly consent
                    to the collection of your phone number, , user agent, and IP
                    address. We use Google fire base services to collect
                    information about your visit to our Platform on their
                    websites. If you are logged into your Account while browsing
                    their pages, information they collect may be connected to
                    your Account with us. Please review their privacy policies
                    for further details.
                  </li>
                </ul>

                <h5>Advertising:</h5>
                <ul>
                  <li>
                    Playzhub.com, owned and operated by Thinktrek
                    Entertainment Private Limited displays advertisements from
                    various advertising companies on the Platform. These ads may
                    be personalised or non-personalised. To show you
                    personalised ads, we share certain data (such as your IP
                    address, ad identifiers, and the fact that you’ve consented
                    to personalised ads) with third-party advertisers and ad
                    networks, only if you’ve given consent through our Consent
                    Management Platform (“CMP”). We also use this data to
                    measure the effectiveness of ads. In the CMP that appears
                    when you first visit our Website, you can adjust your ad
                    preferences or opt out of personalised ads. If you choose
                    not to receive personalised ads, we will still display ads,
                    but they will not be tailored to your interests.
                  </li>
                </ul>
                <h5>Profiling:</h5>
                <ul>
                  <li>
                    Playzhub.com may analyse your game data and, based on your
                    game statistics, recommend games that might interest you.
                    However, you will not be subject to decisions that
                    significantly affect you based solely on automated
                    processing unless we have a lawful basis for doing so
                  </li>
                </ul>

                <h4>Do we share your personal data with others?</h4>
                <p>
                  Yes, we may share your personal data to our IT service
                  providers, external consultants, and other subcontractors of
                  Thinktrek Entertainment Private Limited who support the
                  operation of the Website and Platform.
                </p>
                <ul>
                  <li>
                    Users: We provide users with the option to view profiles of
                    other users. By default, your profile is visible to other
                    users.
                  </li>
                  <li>
                    Advertising: Additionally, we may share information we
                    collect from you, such as your email (in hashed form), IP
                    address, or details about your browser or operating system,
                    with our identity partners and service providers. These
                    partners return an online identification code that we may
                    store in our first-party cookies for our use in advertising.
                    This information may be shared with advertising companies to
                    enable interest-based and personalised advertising.
                  </li>
                  <li>
                    By accepting our Privacy Policy and the relevant
                    ID-providers in the Consent Management Platform (CMP), you
                    consent to the sharing of these identifiers with these
                    providers for advertising purposes.
                  </li>
                  <li>
                    Game Developers: The games available on our Platform are
                    developed by Thinktrek Entertainment Private Limited’s game
                    developers. If you provide feedback about a game, we may
                    share this feedback with the respective game developer.
                  </li>
                </ul>
                <p>
                  If you need more details regarding how we transfer data
                  internationally and the protective measures we implement,
                  please contact us via email at
                  data-protection@games.thinktrek.in or through our contact
                  form.
                </p>

                <h4>How long do we keep your personal data?</h4>
                <p>
                  Thinktrek Entertainment Private Limited will keep your data
                  for as long as strictly necessary for the purposes listed
                  below. With respect to your Account and linked personal data,
                  we will keep your Account until you request us to delete it.
                  You can always delete your account via Account Settings.
                  Please be aware that the deletion of your Account is permanent
                  and you will no longer have access to any purchases linked to
                  your Account.
                </p>
                <p>
                  Following a long period of inactivity, Thinktrek
                  Entertainment Private Limited.in might delete or archive
                  personal data. Where we have your email address, we will
                  notify you in advance before such deletion or archiving of
                  data.
                </p>
                <p>
                  We might keep your data for longer if necessary to comply with
                  a particular law or regulation, protect against fraud or
                  abusive incidents, or in correspondence with any legal claims
                  or disputes.
                </p>

                <h4>
                  Is your data safe at Thinktrek Entertainment Private Limited?
                </h4>
                <p>
                  Yes, protecting your personal data is very important to us. We
                  are committed to taking all necessary steps to ensure your
                  data is secure and to implement appropriate security measures.
                  Within Thinktrek Entertainment Private Limited personal data
                  is only accessible to individuals who need it for their
                  job-related responsibilities.
                </p>
                <p>
                  When we use external providers to help us process your
                  personal data ("Processors"), we ensure that your data is
                  handled confidentially and securely. We also enter into
                  contracts with these Processors, ensuring they cannot use your
                  personal data independently. Your personal data will be
                  deleted once the Processor has completed the task for
                  Thinktrek Entertainment Private Limited.
                </p>
                <p>
                  If you have any questions about our security measures, please
                  feel free to contact us at{" "}
                  <a href="mailto:support@playzhub.in">support@playzhub.in</a>.
                </p>

                <h4>In charge of your data</h4>
                <p>
                  You are the owner of your personal data, and you have full
                  control over it. At Thinktrek Entertainment Private Limited we
                  respect your rights regarding your data. You can exercise the
                  following rights with respect to your personal data:
                </p>
                <ul>
                  <li>
                    View: You have the right to see what personal data Thinktrek
                    Entertainment Private Limited holds about you.
                  </li>
                  <li>
                    Edit: You can update or correct your personal data, such as
                    your name, phone number, or location. You can make changes
                    to your Account information directly.
                  </li>
                  <li>
                    Delete: You can request that Thinktrek Entertainment Private
                    Limited delete your personal data. You can also withdraw any
                    consent you’ve given at any time. If we have no valid reason
                    to retain your data, we will delete it.
                  </li>
                  <li>
                    Freeze: In certain situations, you can request a temporary
                    restriction on the processing of your personal data. This
                    means we will not use your data for a period of time.
                  </li>
                  <li>
                    Stop receiving marketing communications: You can unsubscribe
                    from our marketing communications (such as newsletters). You
                    can easily do this by clicking the unsubscribe link at the
                    bottom of every newsletter.
                  </li>
                  <li>
                    Object to personalised advertising: If you do not want your
                    data used for personalised ads, you can ask us to stop
                    processing it. You can manage your ad preferences through
                    our Consent Management Platform (CMP) by accessing the
                    "Manage Your Ad Preferences" section. Please note that our
                    Platform is free for users, and revenue is generated through
                    ads. Therefore, ads will still appear, but they may not be
                    personalised if you opt-out.
                  </li>
                  <li>
                    Transfer: In some cases, you can request a copy of your
                    personal data to transfer it to another company.
                  </li>
                  <li>
                    Submit a complaint: If you have concerns about how your
                    personal data is being processed, you have the right to file
                    a complaint with Thinktrek Entertainment Private Limited.
                    You can also contact a data protection supervisory
                    authority.
                  </li>
                  <li>
                    Object to Automated Individual Decision Making: If you do
                    not want to be subject to decisions based solely on
                    automated processing, you can inform us. This right applies
                    if you believe such decisions significantly affect your
                    rights, interests, or legal status.
                  </li>
                  <li>
                    Withdraw Consents: If Thinktrek Entertainment Private
                    Limited relies on your consent to process your data, you
                    have the right to withdraw that consent at any time.
                  </li>
                </ul>
                <p>
                  To exercise any of these rights, you can email us at{" "}
                  <a href="mailto:support@playzhub.in">support@playzhub.in</a>.
                </p>
                <p>
                  We will respond to all requests promptly. If our response
                  takes longer than one month due to the complexity or volume of
                  requests, we will notify you accordingly and keep you updated.
                  Additionally, we may ask for more information to confirm your
                  identity before we can process your request.
                </p>

                <h4>Manage Your Ad Preferences</h4>
                <p>
                  When you first visit our Platform, you will be prompted to
                  accept, reject, or set your ad preferences via our Consent
                  Management Platform ("CMP"). You can access and adjust this
                  CMP at any time by clicking on the "Preferences" button in the
                  menu of our Platform.
                </p>
                <p>
                  Additionally, at the browser level, you can manage cookies by
                  adjusting your browser settings. Most modern browsers allow
                  you to block cookies or accept only those from specific
                  websites. You can instruct your browser to refuse all cookies
                  or to notify you when a cookie is being sent. However, please
                  note that if you do not accept cookies, certain features of
                  our Platform may not function properly. For more information
                  on cookies, you can visit All About Cookies. Please also be
                  aware that limiting third-party cookies through browser
                  settings does not prevent our first-party cookies from being
                  set.
                </p>
                <p>
                  If you wish to learn more about behavioural advertising or opt
                  out of having your information used by companies that are part
                  of the Network Advertising Initiative for personalised ads,
                  visit Network Advertising Initiative. Many of the same
                  companies are also members of the Self-Regulatory Program for
                  Online Behavioral Advertising. You can opt out of receiving
                  personalised ads from them by visiting About Ads Choices.
                </p>
                <p>
                  Some advertising networks may require specific opt-out links,
                  which will provide an "opt-out" cookie. This tells the network
                  not to assign you new cookies in the future. While you will
                  still receive ads from these networks, they will not be
                  targeted based on your behaviour. If you delete your browser’s
                  cookies, you may need to perform this opt-out process again.
                </p>
                <p>
                  Please note that if you opt-out of behaviourally targeted
                  advertising, you will still receive ads on our free,
                  ad-supported Platform, but these will not be personalised.
                  Additionally, if you opt-out of Thinktrek
                  Entertainment Private Limited advertising practices, you may
                  still receive interest-based ads from other companies.
                </p>

                <h4>Privacy of Children</h4>
                <p>
                  Thinktrek Entertainment Private Limited strives to create a
                  safe environment for children. Our Platform is intended for
                  users who are thirteen (13) years of age or older (or the
                  applicable minimum age in your country.
                </p>

                <h4>Links to Other Websites</h4>
                <p>
                  Our Platform or web games provided by third-party developers
                  may contain links to external websites. If you click on such a
                  link, you will be directed to that company's website, such as
                  social media platforms. We encourage you to check the privacy
                  policies of these websites as they are beyond our control, and
                  we are not responsible for their content or privacy practices.
                </p>

                <h5>Your rights may include:</h5>
                <ul>
                  <li>
                    Right to Know and Delete: You can request to know and delete
                    personal data we have collected about you.
                  </li>
                  <li>
                    Right to Opt-Out: You can opt out of the sale or sharing of
                    your personal information, as well as targeted advertising
                    and profiling. To opt out, please follow the opt-out
                    instructions provided.
                  </li>
                  <li>
                    Right to Limit Use and Disclosure: You may request that we
                    limit the use and disclosure of sensitive personal data.
                  </li>
                  <li>
                    Right to Obtain Consent: We may need to obtain your consent
                    before processing sensitive personal data.
                  </li>
                  <li>
                    Right to Confirm and Obtain a Copy: You can confirm whether
                    we are processing your personal data and, if feasible,
                    obtain a copy of it.
                  </li>
                  <li>
                    Right to Correct: You can request the correction of
                    inaccurate personal information we maintain about you.
                  </li>
                  <li>
                    Right to Non-Discrimination: You have the right to be free
                    from discrimination for exercising your privacy rights under
                    the CCPA.
                  </li>
                </ul>
                <p>
                  To exercise any of these rights, please contact us at{" "}
                  <a href="mailto:support@playzhub.in">support@playzhub.in.</a>
                </p>

                <h4>Changes to Our Privacy Policy</h4>
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be reflected by updating the "last updated" date
                  at the top of the document. If you have an Account, we will
                  notify you by email of any significant changes. You can always
                  access the most recent version of this Privacy Policy on our
                  Platform.
                </p>
                <h4>More Questions About Your Data</h4>
                <p>
                  If you have any questions or concerns about this Privacy
                  Policy or how we handle your personal data, please contact us
                  at{" "}
                  <a href="mailto:support@playzhub.in">support@playzhub.in</a>.
                </p>
                <p>
                  If your concerns are not resolved by us, you have the right to
                  file a complaint with the relevant data protection authority
                  in your country.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112"></div>
      </div>
    </div>
  );
};

export default Policy;
