import {
  ACTIVE_CAT,
  ACTIVE_TECH,
  ACTIVE_STATIC,
  DOB,
  UNAME,
  RESET,
  PROFILE_DATA,
  GENDER,
  AVATAR_ID,
  PROFILE_IMG,
  EXPIRE_TIME,
  LIKED,
  DISLIKED,
  CAMERA_FILE,
  GUEST_TOKEN,
  LOGIN_TOKEN,
  PHONE_NUMBER,
  GAME_STATE,
  GAME_DATA,
} from "./Types";
const initialState = {
  activeTechnology: null,
  activeStatic: "home",
  activeCategory: null,
  dateOfBirth: null,
  Uname: "",
  profile_data: [],
  game_data: null,
  gender: "",
  avatar_id: 1,
  profile_img: null,
  expire_time: null,
  liked: 0,
  disliked: 0,
  camera_file: null,
  guest_token: null,
  login_token: null,
  phone_number: "",
  game_state: {},
};

const Reducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case ACTIVE_TECH:
      return {
        ...state,
        activeTechnology: action.payload,
      };

    case ACTIVE_CAT:
      return {
        ...state,
        activeCategory: action.payload,
      };
    case ACTIVE_STATIC:
      return {
        ...state,
        activeStatic: action.payload,
      };
    case DOB:
      return {
        ...state,
        dateOfBirth: action.payload,
      };
    case UNAME:
      return {
        ...state,
        Uname: action.payload,
      };
    case PROFILE_DATA:
      return {
        ...state,
        profile_data: action.payload,
      };
    case GAME_DATA:
      return {
        ...state,
        game_data: action.payload,
      };
    case GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case AVATAR_ID:
      return {
        ...state,
        avatar_id: action.payload,
      };
    case PROFILE_IMG:
      return {
        ...state,
        profile_img: action.payload,
      };
    case EXPIRE_TIME:
      return {
        ...state,
        expire_time: action.payload,
      };
    case LIKED:
      return {
        ...state,
        liked: action.payload,
      };
    case DISLIKED:
      return {
        ...state,
        disliked: action.payload,
      };
    case CAMERA_FILE:
      return {
        ...state,
        camera_file: action.payload,
      };

    case GUEST_TOKEN:
      return {
        ...state,
        guest_token: action.payload,
      };
    case LOGIN_TOKEN:
      return {
        ...state,
        login_token: action.payload,
      };
    case PHONE_NUMBER:
      return {
        ...state,
        phone_number: action.payload,
      };

    case GAME_STATE:
      return {
        ...state,
        game_state: action.payload,
      };
    case RESET:
      return {
        state: initialState,
      };
  }
  return state;
};

export default Reducer;
