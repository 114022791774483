import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import Reducer from "./Reducer";
import localStorage from "redux-persist/lib/storage";

const PersistConfig = {
  key: "root",
  storage: localStorage,
};
const persistedReducer = persistReducer(PersistConfig, Reducer);
// const store = legacy_createStore(Reducer);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
