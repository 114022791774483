import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import Service from "../service/apis";
import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Avatar, useToaster, Loader } from "rsuite";
import { Input, InputGroup, MaskedInput } from "rsuite";
import moment from "moment";
import Cookies from "universal-cookie";
import Avatar_data from "../avatars.json";
import Flag from "react-world-flags";
import Header from "../Header";
import "../CCalendar.css";
import NavigationBar from "../NavigationBar.jsx";
import { useSelector, useDispatch } from "react-redux";
import { IoCamera } from "react-icons/io5";
import axios from "axios";
import {
  UNAME,
  DOB,
  GENDER,
  PROFILE_IMG,
  AVATAR_ID,
  LIKED,
  DISLIKED,
  GUEST_TOKEN,
  LOGIN_TOKEN,
  EXPIRE_TIME,
  PHONE_NUMBER,
} from "../store/Types";
import OtpInput from "react-otp-input";
import { TiTick } from "react-icons/ti";
import Countdown, { zeroPad } from "react-countdown";
import { analytics } from "../firebase";
import useStore from "../service/zustand";
import {
  Divider,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  Tooltip,
  Whisper,
  AvatarGroup,
  Progress,
} from "rsuite";
import { logEvent } from "firebase/analytics";
import PhoneInput from "react-phone-input-2";

let BASE_URL = process.env.REACT_APP_BASE_URL_PROD;

const Profile_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    let name_store = useSelector((state) => state.Uname);
    let date_store = useSelector((state) => state.dateOfBirth);
    let gender_store = useSelector((state) => state.gender);
    let avatar_store = useSelector((state) => state.avatar_id);
    let profile_img = useSelector((state) => state.profile_img);
    const { profile_status_load, add_profile_load } = useStore();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(profile_img);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(avatar_store);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);
    const [isProfileDelete, setIsProfileDelete] = useState(0);
    const [cameraFile, setCameraFile] = useState(null);
    const [cameraPreview, setCameraPreview] = useState(null);

    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    // Function to handle avatar selection
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
      setFileInfo(null);
      setFileList(null);
      setIsProfileDelete(1);
      setCameraFile(null);
      setCameraPreview(null);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      setLoading(true);
      if (!name_store) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);

      let form = new FormData();
      form.append("full_name", name_store);
      form.append("profile_picture", fileList);
      form.append(
        "date_of_birth",
        date_store ? moment(date_store).format("DD-MM-YYYY") : "" || ""
      );
      form.append("gender", gender_store || selectedGender || "");
      form.append("avatar_id", selectedAvatar);
      form.append("isProfileDelete", isProfileDelete);
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              modal.hide();
              add_profile_load(true);
              // window.location.reload(true);
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    useEffect(() => {
      setName(full_name);
    }, []);

    const genders = [
      "Male",
      "Female",
      "Non-Binary",
      "Other",
      "Prefer not to say",
    ];

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState("");

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      console.log(gender);
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    useEffect(() => {
      if (file) {
        setFileList(file);
        setFileInfo(preview);
        // setFileInfo(null);
        setSelectedAvatar(12);
      }
    }, [file]);

    return (
      <Modal
        backdrop="static"
        overflow={false}
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            {/* <i class="fa-solid fa-xmark" style={{ cursor: "pointer" }}></i> */}
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap  pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
                overflow: "visible",
              }}
            >
              {!cameraPreview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      navigate,
                      full_name,
                      file,
                      preview,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <>
                  {/* <img
                    style={{ borderRadius: 35, width: 40, height: 40 }}
                    onClick={() => {
                      NiceModal.show(Camera_Modal, {
                        full_name,
                        file,
                        preview,
                      });
                      modal.hide();
                    }}
                    src={cameraPreview}
                    className="after-ring"
                    width="100%"
                    height="100%"
                  /> */}
                  <div
                    className="d-flex align-items-center justify-content-center "
                    style={{
                      top: "30px",
                      left: "35px",
                      width: "13px",
                      height: "13px",
                      borderRadius: "3px",
                      background: cameraFile ? "#2ED573" : "",
                      position: "absolute",
                    }}
                  >
                    {cameraFile && (
                      <TiTick
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      />
                    )}
                  </div>
                </>
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => {
                  setFileList(event[0].blobFile);
                  setIsProfileDelete(0);
                }}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {fileInfo ? (
                    <>
                      <img
                        className="after-ring"
                        src={fileInfo}
                        width="100%"
                        height="100%"
                      />
                      <div
                        className="d-flex align-items-center justify-content-center after_ring1"
                        style={{
                          bottom: "11px",
                          right: "0",
                          width: "13px",
                          height: "13px",
                          borderRadius: "3px",
                          background: fileInfo ? "#2ED573" : "",
                          position: "absolute",
                        }}
                      >
                        {fileInfo && (
                          <TiTick
                            style={{ fontSize: "10px" }}
                            className="text-white"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <img
                      height={"15px"}
                      width={"15px"}
                      src={require("../assets/picked.png")}
                      style={{ fontSize: 15, color: "#fff" }}
                    />
                  )}
                </button>
              </Uploader>
              <span className="fromGallery">
                <img
                  height={"15px"}
                  width={"15px"}
                  src={require("../assets/picked.png")}
                  style={{ fontSize: 15, color: "#fff" }}
                />
              </span>
            </div>
            {Avatar_data.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={
                      fileInfo
                        ? false
                        : selectedAvatar === item?.avatar_id
                        ? true
                        : false
                    }
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: !fileInfo ? "#2ED573" : "",
                        position: "absolute",
                      }}
                    >
                      {!fileInfo && (
                        <TiTick
                          style={{ fontSize: "10px" }}
                          className="text-white"
                        />
                      )}
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div
            className="editField"
            style={{ marginTop: 8, cursor: "pointer" }}
            onClick={() => {
              NiceModal.show(Username_Modal, {
                navigate,
                full_name: name_store,
              });
            }}
          >
            <label className="text-white" style={{ cursor: "pointer" }}>
              Username*
            </label>
            <Input
              // disabled={name_status ? true : false}
              value={name_store}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none", cursor: "pointer" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Username_Modal, {
                  navigate,
                  full_name: name_store,
                });
              }}
            >
              Edit
            </span>
          </div>
          <div className="editField" style={{ marginTop: 8 }}>
            <label className="text-white d-block">
              Date of birth
              {/* <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="click"
                speaker={tooltip}
              >
                <img
                  className="ms-2"
                  src={require("../src/assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper> */}
            </label>
            {/* <DatePicker
            placeholder={"Not specified"}
            disabled={dob_status ? true : false}
            oneTap
            placement="top"
            className="w-100"
            onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
          /> */}
            <Input
              disabled={true}
              value={date_store && moment(date_store).format("DD-MM-YYYY")}
              placeholder="Not Specified"
              style={{ background: "none" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Dob_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 8 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Gender</label>
            <input
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender || gender_store}
            />

            <div className="custom-select-container">
              {isOpen && (
                <>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                onClick={() => {
                  onContactFromSubmit();
                  // modal.hide();
                }}
                className="guest-btn w-auto"
                style={{ borderRadius: 20, padding: "10px 25px" }}
                block
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
                block
              >
                Save & Continue
              </Button>
            )}
          </div>
          <p
            className="text-white text-center m-0 mt-3 login_para"
            style={{ fontSize: "12px" }}
          >
            Note: If DOB is not declared then you are agreeing to the {""}
            <br />
            <span
              onClick={() => {
                navigate("/terms_and_conditions");
                modal.hide();
              }}
              style={{ cursor: "pointer", color: "#F2641F" }}
            >
              Terms & Conditions
            </span>{" "}
            that you are 13+ years of age. To get the advantages and special
            privilages of partcipating in the Weekly Leaderboard Challenges, you
            need to Login.
          </p>
        </Modal.Body>
      </Modal>
    );
  }
);

const Username_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    const dispatch = useDispatch();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);
    let [msg, setMsg] = useState("");
    let [err_msg, setErr_msg] = useState("");

    const verify_username = (e) => {
      //console.log(e.length);
      setName(e);
      if (e.length >= 3) {
        setName(e);
        let params = {
          user_name: e,
        };
        Service.verify_username(params)
          .then((res) => {
            // console.log(res);
            if (res?.status == 0) {
              setErr_msg("Username available.");
              setMsg("");
            } else if (res?.status == 1) {
              setMsg("Username already exists.");
              setErr_msg("");
            }
          })
          .catch((e) => console.log(e));
      } else if (e.length == 0) {
        setMsg("");
        setErr_msg("");
      }
    };

    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Profile_Modal, { navigate });
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
            ></i>
            Update Username
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="brthdy_sec"
          style={{ maxHeight: "480px !important" }}
        >
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Username</label>
                <Input
                  defaultValue={full_name}
                  // value={name}
                  placeholder="Fill username"
                  onChange={verify_username}
                  style={{ background: "none" }}
                />
                <span
                  style={{
                    fontSize: "10px",
                    color: !msg ? "#118B50" : "#FA4032",
                  }}
                >
                  {msg || err_msg}
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              disabled={name.length == 0 ? true : false}
              className={name.length == 0 && "guest-btn"}
              onClick={() => {
                NiceModal.show(Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                dispatch({
                  type: UNAME,
                  payload: name,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: name.length !== 0 && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Dob_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview, select_dob }) => {
    const modal = useModal();
    const dispatch = useDispatch();
    let date_store = useSelector((state) => state.dateOfBirth);
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);

    const disableDate = (date) => {
      const today = new Date();
      return date > today;
    };
    return (
      <Modal
        backdrop="static"
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => {
          modal.hide();
          NiceModal.show(Profile_Modal, { navigate });
        }}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
            ></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 8 }}>
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  editable={false}
                  shouldDisableDate={disableDate}
                  format="dd-MM-yyyy"
                  // value={dob}
                  defaultValue={date_store && new Date(date_store)}
                  placeholder={"dd/mm/yyyy"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100"
                  onChange={(e) => {
                    setDob(moment(e).toISOString());
                  }}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none" }}
                >
                  <img
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                dispatch({
                  type: DOB,
                  payload: dob,
                });
                NiceModal.show(Profile_Modal, {
                  navigate,
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);
const Phone_Modal = NiceModal.create(({ navigate }) => {
  const modal = useModal();
  let cookies = new Cookies();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let validate_users = useSelector((state) => state.guest_token);
  let IP_URL = process.env.REACT_APP_IP_URL;
  const { profile_status_load, add_profile_load, add_guest_token } = useStore();
  let [offline_data, setOffline_data] = useState({
    ip: "122.176.24.171",
    network: "122.176.24.0/24",
    version: "IPv4",
    city: "Kolkata",
    region: "West Bengal",
    region_code: "WB",
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso3: "IND",
    country_capital: "New Delhi",
    country_tld: ".in",
    continent_code: "AS",
    in_eu: false,
    postal: "700054",
    latitude: 22.518,
    longitude: 88.3832,
    timezone: "Asia/Kolkata",
    utc_offset: "+0530",
    country_calling_code: "+91",
    currency: "INR",
    currency_name: "Rupee",
    languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    country_area: 3287590,
    country_population: 1352617328,
    asn: "AS24560",
    org: "Bharti Airtel Ltd., Telemedia Services",
  });

  const guest_user = () => {
    if (!validate_users) {
      fetch_user_details();
    }
    ///window.location.reload(true);
  };
  const fetch_user_details = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        console.log({ res });
        cookies.set("network_details", res.data, { path: "/" });
        let data = res.data;
        validate_user(data);
      })
      .catch((e) => {
        console.log(e.config.data);
        if (e.config.data === undefined) {
          validate_user(offline_data);
        }
      });
  };

  const validate_user = (data) => {
    let params = {
      ip_address: data.ip,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
      additional_details: JSON.stringify(data),
    };
    Service.login_register_visitor(params)
      .then((res) => {
        // console.log(res);
        logEvent(analytics, "guest_user_count", {
          country: data?.country_name,
          city: data?.city,
        });
        cookies.set("token", res.token, { path: "/" });
        window.localStorage.setItem("guest_token", res?.token);
        dispatch({
          type: GUEST_TOKEN,
          payload: res?.token,
        });
        add_guest_token(res.token);
        // window.location.reload(true);
      })
      .catch((e) => console.log(e.message));
  };
  const fetch_user_network = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        cookies.set("network_details", res.data, { path: "/" });
      })
      .catch((e) => {
        // console.log(e.config.data)
        if (e.config.data === undefined) {
          // validate_user(offline_data)
        }
      });
  };
  const handleInputChange = (value, data) => {
    let code = `+${data.dialCode}`;
    setDial(code);
    let number = value.toString().slice(data.dialCode.length);
    setPhone(number);
  };
  let network = cookies.get("network_details");

  const onContinue = async (e) => {
    // e.preventDefault();
    setLoading(true);
    let params = {
      phone_number: phone,
      country_code: dial,
    };
    await Service.login_number(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 0) {
          setLoading(false);
        }
        if (res.status == 1) {
          if (!network) {
            fetch_user_network();
          }
          setLoading(false);
          modal.hide();
          dispatch({
            type: EXPIRE_TIME,
            payload: res?.otp_expires,
          });
          NiceModal.show(Otp_Modal, {
            navigate,
            number: phone,
            otp_code: res?.otp,
            dial_code: dial,
            expire: res?.otp_expires,
          });
        }
      })
      .catch((e) => console.log(e.message));
  };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      onContinue(e);
    }
  };
  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-4 fw-normal">Log In</h4>

          <p className="mb-0" style={{ fontSize: "16px" }}>
            Welcome to Playzhub,
          </p>
          <p className="mb-1" style={{ fontSize: "16px" }}>
            Unleash the Fun, Win Big!
          </p>
          <i
            onClick={() => {
              guest_user();
              modal.hide();
            }}
            class="fa-solid fa-xmark"
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <PhoneInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onContinue();
            }
          }}
          className="phone"
          copyNumbersOnly={true}
          //autoFormat={false}
          prefix="+"
          countryCodeEditable={false}
          placeholder="Fill mobile number"
          country={"in"}
          //  value={phone}
          onChange={(value, data, event, formattedValue) =>
            handleInputChange(value, data)
          }
        />
        <Button
          // onKeyDown={handleKeyDown}
          className="my-4"
          loading={loading}
          onClick={() => {
            onContinue();
          }}
          disabled={phone.length >= 8 ? false : true}
          appearance="primary"
          style={{
            width: "40%",
            marginTop: 10,
            borderRadius: 20,
            background: phone.length >= 8 ? "#F2641F" : "#818A97",
            padding: "10px 22px",
          }}
          block
        >
          Login
        </Button>
        <p
          className="text-secondary"
          style={{ justifyItems: "flex-end", flex: 1 }}
        >
          OR
        </p>
        <div style={{ marginTop: 10 }}></div>
        <Button
          className="guest-btn"
          onClick={() => {
            guest_user();
            modal.hide();
            // window.location.reload(true);
          }}
          // appearance="ghost"
          style={{ marginTop: 10, borderRadius: 20, width: "auto" }}
          block
        >
          Continue as Guest
        </Button>
        <hr />
        <p
          className="text-white text-center m-0 login_para"
          style={{ fontSize: "12px" }}
        >
          Note: As a guest you are agreeing to the {""}
          <span
            onClick={() => {
              navigate("/terms_and_conditions");
              modal.hide();
            }}
            style={{ cursor: "pointer", color: "#F2641F" }}
          >
            Terms & Conditions
          </span>{" "}
          that you are 13+ years of age. To get the advantages and special
          privilages of partcipating in the Weekly Leaderboard Challenges, you
          need to Login.
        </p>
      </Modal.Body>
    </Modal>
  );
});

const Otp_Modal = NiceModal.create(
  ({ navigate, number, otp_code, dial_code, expire }) => {
    const modal = useModal();
    let cookies = new Cookies();
    const expire_times = useSelector((state) => state.expire_time);
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    const [dial, setDial] = useState("");
    const [show, setShow] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [expire_time, setExpire_time] = useState("");
    let [msg, setMsg] = useState("");
    const { profile_status_load, add_profile_load } = useStore();
    let network = cookies.get("network_details");

    const verifyCode = async () => {
      setLoading(true);
      let params = {
        phone_number: number,
        otp: otp,
        ip_address: network?.ip,
        country: network?.country_name,
        country_code: dial_code,
        city: network?.city,
        latitude: network?.latitude,
        longitude: network?.longitude,
      };
      await Service.verify_otp(params)
        .then((res) => {
          // console.log(res?.data);
          if (res.status == 0) {
            setMsg(res?.message);
            setLoading(false);
          }
          if (res.status == 1) {
            // if (!res?.data?.visitorDetails?.full_name) {
            if (res?.data?.profile_completion_status === false) {
              dispatch({
                type: PHONE_NUMBER,
                payload: res?.data?.visitorDetails?.phone_number,
              });
              dispatch({
                type: UNAME,
                payload: res?.data?.visitorDetails?.full_name,
              });
              dispatch({
                type: DOB,
                payload: res?.data?.visitorDetails?.date_of_birth,
              });
              dispatch({
                type: GENDER,
                payload: res?.data?.visitorDetails?.gender,
              });
              dispatch({
                type: AVATAR_ID,
                payload:
                  res?.data?.visitorDetails?.avatar_id == null
                    ? 1
                    : res?.data?.visitorDetails?.avatar_id,
              });
              dispatch({
                type: PROFILE_IMG,
                payload: res?.data?.visitorDetails?.profile_picture,
              });
              logEvent(analytics, "registered_user_count", {
                country: network?.country_name,
                city: network?.city,
              });
              cookies.set("login_token", res.token, { path: "/" });
              window.localStorage.setItem("login_token", res?.token);
              dispatch({
                type: LOGIN_TOKEN,
                payload: res?.token,
              });
              modal.hide();
              NiceModal.show(Profile_Modal, {
                navigate,
                full_name: res?.data?.visitorDetails?.full_name,
              });

              dispatch({
                type: UNAME,
                payload: res?.data?.visitorDetails?.full_name,
              });

              setLoading(false);
            }
            add_profile_load(true);
            dispatch({
              type: PHONE_NUMBER,
              payload: res?.data?.visitorDetails?.phone_number,
            });
            cookies.set("login_token", res.token, { path: "/" });
            window.localStorage.setItem("login_token", res?.token);
            dispatch({
              type: LOGIN_TOKEN,
              payload: res?.token,
            });
            modal.hide();
            setMsg("");
            ///window.location.reload();
            //NiceModal.show(Profile_Modal, { id: 'game_id', })
            setLoading(false);
          }
        })
        .catch((e) => console.log(e.message));
    };

    const maskPhoneNumber = (number) => {
      const countryCode = number.slice(0, 4); // +91 and space
      const firstTwoDigits = number.slice(0, 2); // First two digits after country code
      const lastThreeDigits = number.slice(-2); // Last three digits
      const maskedMiddle = "*".repeat(6); // Replace 5 middle digits with *

      return `${firstTwoDigits}${maskedMiddle}${lastThreeDigits}`;
    };

    const onContinue = async () => {
      // setLoading(true);
      let params = {
        phone_number: number,
        country_code: dial_code,
      };
      await Service.login_number(params)
        .then((res) => {
          //console.log(res);
          if (res.status == 0) {
            //setLoading(false);
          }
          if (res.status == 1) {
            restartCountdown();
            setCode(res?.otp);
            dispatch({
              type: EXPIRE_TIME,
              payload: res?.otp_expires,
            });
            setExpire_time(res?.otp_expires);
          }
        })
        .catch((e) => console.log(e.message));
    };
    const [key, setKey] = useState(0); // Key to reset the countdown

    // Function to handle restart of the countdown
    const restartCountdown = () => {
      setKey((prev) => prev + 1); // Changing key will re-render the Countdown component
    };
    const Resend = () => (
      <div
        className="text-end text-capitalize"
        style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
      >
        <span
          style={{
            cursor: "pointer",
            color: "#F2641F",
            textDecorationLine: "underline",
          }}
          onClick={() => {
            onContinue();
          }}
        >
          Resend
        </span>
      </div>
    );

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed, started }) => {
      if (started) {
        console.log("function start");
      }

      if (completed) {
        // Render a completed state
        // restartCountdown();
        return <Resend />;
      } else {
        // Render a countdown
        return (
          <div
            className="text-center"
            style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
          >
            <span className="text-white">
              Not received the code?{" "}
              <span style={{ color: "#F2641F" }}>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
            </span>
          </div>
        );
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        console.log("entr");
        verifyCode();
      }
    };

    const maskedNumber = maskPhoneNumber(number);

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Phone_Modal, { navigate });
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
            <h4 className="mb-4 fw-normal">Verify</h4>
            <p
              className="mb-0"
              style={{ fontSize: "16px", whiteSpace: "normal" }}
            >
              {/* {`Please enter your verification code sent to ${dial_code}${number}`} */}
              Please enter your verification code sent to
              <br />
              <span>{`${dial_code} ${maskedNumber}`}</span>
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p style={{ color: "red" }}>{msg}</p>
          <OtpInput
            inputType="tel"
            value={otp}
            onChange={setOtp}
            numInputs={4}
            containerStyle={{
              margin: "5px",
              justifyContent: "center",
            }}
            inputStyle={{
              height: "40px",
              width: "40px",
              margin: "5px",
              background: "#333947",
              color: "#F5F9FF",
              borderRadius: 5,
              border: "none",
            }}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                autoComplete="one-time-code"
                {...props}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
          />

          <Countdown
            onStart={(e) => {
              console.log("Started", e);
            }}
            onMount={(e) => console.log("Mounted", e)}
            onComplete={(e) => {
              console.log("Completed", e);
            }}
            key={key}
            autoStart={true}
            // intervalDelay={0}
            // precision={1}
            // date={Date.now() + 10000}
            date={new Date(expire_times)}
            renderer={renderer}
          >
            <Resend />
          </Countdown>
          <p className="text-white m-0">{code || otp_code}</p>
          <Button
            disabled={otp.length == 4 ? false : true}
            className="text-white"
            loading={loading}
            onClick={() => {
              verifyCode();
              // modal.hide()
              // NiceModal.show(Profile_Modal)
            }}
            //#818A97
            appearance="primary"
            style={{
              marginTop: 25,
              borderRadius: 20,
              padding: "10px 40px",
              background: otp.length == 4 ? "#F2641F" : "#818A97",
              color: otp.length == 4 ? "#fff" : "#ffffff47",
            }}
          >
            Verify
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
);

const Camera_Modal = NiceModal.create(
  ({ navigate, full_name, file, preview }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      // Start the camera when the component mounts
      startCamera();

      // Cleanup the stream on component unmount
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject;
          // const tracks = stream.getTracks();
          // tracks.forEach((track) => track.stop());
        }
      };
    }, [videoRef.current]);

    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStream(stream); // Store the stream
          setHasPermission(true);
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
        setHasPermission(false);
      }
    };

    const stopCamera = () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop each track
        // setIsCameraOn(false); // Update the camera status
      }
      setHasPermission(false);
    };

    const captureImage = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const context = canvas.getContext("2d");
        // Draw the current frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a Blob (image file)
        canvas.toBlob((blob) => {
          // Create a File from the Blob and append it to FormData
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          const imageURL = canvas.toDataURL("image/png");
          //console.log(imageURL);
          //  const formData = new FormData();
          //  formData.append("image", file);

          NiceModal.show(Profile_Modal, {
            navigate,
            full_name,
            file: file,
            preview: imageURL,
          });
          modal.hide();
          stopCamera();
          // Send the FormData to the server (for example, using fetch)
        }, "image/png"); // You can also use 'image/jpeg' for JPEG format
      }
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => modal.hide()}
        // onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Camera
            <i
              onClick={() => {
                modal.hide();
                NiceModal.show(Profile_Modal, { navigate });
              }}
              class="fa-solid fa-xmark"
              style={{ cursor: "pointer" }}
            ></i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row flex-wrap">
            <div className="w-100">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay
                style={{ background: "#333947" }}
              />

              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width="640"
                height="480"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              onClick={captureImage}
              appearance="primary"
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
              }}
            >
              Capture
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const History_Modal = NiceModal.create(({ start, end }) => {
  const modal = useModal();
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    leaderboard_fetch_search(start, end);
  }, [start, end]);

  const leaderboard_fetch_search = (start, end) => {
    let params = {
      start_date: start,
      end_date: end,
    };
    Service.playing_history(params)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status == 1) {
          setLeaderboard(res?.data?.reverse());
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Modal
        backdrop="static"
        onBackdropClick={() => modal.hide()}
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        className="profileModal"
      >
        <Modal.Body className="p-0 mh-100">
          <div>
            <div>
              <div className="dark-bg h-100 p-3">
                <Modal.Title className="text-white mb-3">
                  <i
                    onClick={modal.hide}
                    class="fa-solid fa-xmark"
                    style={{ cursor: "pointer" }}
                  ></i>
                  Playing History
                </Modal.Title>
                <div className="col-12">
                  <div className="scrollTable" style={{ minHeight: "430px" }}>
                    {leaderboard?.length == 0 && (
                      <div className="row m-0">
                        <div className="col">
                          <h5 className="text-center text-white mt-5 pt-5">
                            Sorry! You have not played any game. Start Playing.
                          </h5>
                        </div>
                      </div>
                    )}
                    <table
                      className="leaderboardTable w-100 text-white"
                      cellSpacing={0}
                    >
                      {leaderboard?.map((item, index) => {
                        return (
                          <tr>
                            {/* <td style={{ width: "50px" }}></td> */}
                            <td style={{ width: "45%" }}>
                              <div className=" d-flex align-items-center text-nowrap">
                                <Avatar
                                  bordered={false}
                                  src={item.Game_image}
                                  className="me-3"
                                  size="md"
                                />
                                {item?.Game_name.replace(/-/g, " ")}
                              </div>
                            </td>
                            <td>
                              {`${moment(item?.Playing_date_time).format(
                                "DD MMM, YYYY"
                              )}`}
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                {`Score : ${item?.Score}`}
                              </div>
                            </td>
                            <td style={{ width: "100px" }}>
                              <div className="d-flex align-items-center justify-content-start text-start">
                                <img
                                  src={require("../assets/star.png")}
                                  width={15}
                                  alt=""
                                  className="me-1"
                                />
                                {item?.Equivalent_Score}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

const Rules_Modal = NiceModal.create(({ navigate }) => {
  const modal = useModal();

  return (
    <Modal
      size={"md"}
      className="rs-modal-xl"
      open={modal.visible}
      onClose={() => modal.hide()}
      // onExited={modal.remove}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          Leaderboard Rules
          <i
            onClick={() => {
              modal.hide();
            }}
            class="fa-solid fa-xmark"
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column" style={{ color: "#818A97" }}>
          <h6>Weekly Leaderboard:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Dive into our thrilling games on the Playzhub platform and compete
              with other players to climb the leaderboard.
            </li>
            <li>The weekly leaderboard runs from Sunday to Saturday.</li>
            <li>
              At the end of each week, the leaderboard resets, giving everyone a
              fresh start with new challenges and competition.
            </li>
          </ul>
          <h6>Playzhub Coins:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Play and complete games to earn Playzhub coins based on your
              highest scores in each game.
            </li>
            <li>
              The highest Playzhub coins earned from a game will be used to
              feature your rank on the weekly leaderboard.
            </li>
            <li>
              Your total leaderboard position is determined by the sum of the
              highest Playzhub coins earned across all games during the week.
            </li>
            <li>
              Keep track of your progress through the “Playing History” section.
            </li>
          </ul>
          <h6>Points Calculation:</h6>
          <ul style={{ fontSize: "12px" }}>
            <li>
              Playzhub coins are awarded based on the scores you achieve in each
              game.
            </li>
            <li>
              The conversion of scores to Playzhub coins is outlined in the
              "Points Conversion" chart, available in the gameplay description
              of every game.
            </li>
            <li>
              These conversions determine your scores for the weekly leaderboard
              rankings.
            </li>
          </ul>
        </div>
        <div className="text-center">
          <Button
            onClick={() => modal.hide()}
            appearance="primary"
            style={{
              marginTop: 10,
              borderRadius: 20,
              background: "#F2641F",
              padding: "10px 25px",
            }}
          >
            Got It
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

const Leaderboard = () => {
  const navigate = useNavigate();
  let cookies = new Cookies();
  const [data, setData] = useState([]);
  let profile_details = useSelector((state) => state.profile_data);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  let user_id = useSelector((state) => state.profile_data?.id);
  let username = useSelector((state) => state.Uname);
  let Login_token = useSelector((state) => state.login_token);
  const [playing_history, setPlaying_history] = useState([]);
  const [calender_toggle, setCalender_toggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const disabledDate = (date) => {
    const today = new Date();
    return date > today; // Disable future dates
  };

  const [start_week, setStart_week] = useState("");
  const [end_week, setEnd_week] = useState("");

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    leaderboard_fetch();
  }, []);

  function getStartEndOfWeekMondayUTC(date = new Date()) {
    const dayOfWeek = date.getDay();

    // Calculate the difference in days to the start of the week (Sunday)
    const diffToSunday = dayOfWeek === 0 ? 1 : dayOfWeek; // If it's Sunday, no change; otherwise, adjust backward to previous Sunday

    // Set the start of the week to the calculated Sunday (in local time)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek);

    // Set the end of the week to the following Saturday (in local time)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return {
      startOfWeek: startOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      endOfWeek: endOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      startOfWeeks: startOfWeek, // Format as YYYY-MM-DD
      endOfWeeks: endOfWeek,
    };
  }
  const { startOfWeek, endOfWeek, startOfWeeks, endOfWeeks } =
    getStartEndOfWeekMondayUTC();

  const leaderboard_fetch = () => {
    setLoading(true);
    const { startOfWeek, endOfWeek } = getStartEndOfWeekMondayUTC();
    let params = {
      startDate: startOfWeek,
      endDate: endOfWeek,
    };
    Service.leaderboard(params)
      .then((res) => {
        //console.log(res);
        if (res?.status == 0) {
          setLeaderboard([]);
          setLoading(false);
        } else if (res?.status == 1) {
          setLeaderboard(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const leaderboard_fetch_search = (start, end) => {
    setLoading(true);
    let params = {
      startDate: start,
      endDate: end,
    };
    Service.leaderboard(params)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status == 0) {
          setLeaderboard([]);
          setLoading(false);
        } else {
          setLeaderboard(res?.data);
          setLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //setVisible(false);
          setCalender_toggle(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [activeIndex, setActiveIndex] = useState(null); // State to track active list item

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [previousDate, setPreviousDate] = useState(null); // To track the previously selected date

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  const datse = new Date();
  //console.log("CURRENTWEEK", currentDate.getMonth() === datse.getMonth());

  const generateCalendarGrid = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const startOffset = firstDayOfMonth;
    const endOffset = 6 - lastDayOfMonth;

    const placeholdersBefore = Array.from({ length: startOffset }, () => null);
    const placeholdersAfter = Array.from({ length: endOffset }, () => null);

    return [...placeholdersBefore, ...daysInMonth, ...placeholdersAfter];
  };

  // Adjusted function to get the week range (Monday to Sunday)
  const getWeekRange = (date) => {
    const dayIndex = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayIndex);
    startOfWeek.setHours(0, 0, 0, 0); // Reset time to the start of the day

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day

    return { startOfWeek, endOfWeek };
  };
  const generateWeeks = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = getDaysInMonth(year, month);

    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const placeholdersBefore = Array.from(
      { length: firstDayOfMonth },
      () => null
    );
    const placeholdersAfter = Array.from(
      { length: 6 - lastDayOfMonth },
      () => null
    );

    const allDays = [
      ...placeholdersBefore,
      ...daysInMonth,
      ...placeholdersAfter,
    ];

    // Split days into weeks (7 days per row)
    const weeks = [];
    for (let i = 0; i < allDays.length; i += 7) {
      weeks.push(allDays.slice(i, i + 7));
    }
    return weeks;
  };
  const isDateInWeekRange = (date, week) => {
    console.log(date);
    console.log(week);
    let dateInWeek = week.some(
      (day) => day && day.toDateString() === date.toDateString()
    ); // week.includes(date);
    console.log("hello", dateInWeek);
    return dateInWeek;
  };

  const getIndexOfWeekbyDate = (date) => {
    console.log(date);
    let index = -1;
    weeks.forEach((week, weekIndex) => {
      console.log("WEEK", week);
      let matchFound = isDateInWeekRange(date, week);
      if (matchFound) {
        index = weekIndex;
      }
    });

    return index;
  };
  const isDateInWeek = (date, weekRange) => {
    return date >= weekRange.startOfWeek && date <= weekRange.endOfWeek;
  };
  const weeks = generateWeeks(currentDate);
  const isFutureDate = (date) => {
    const today = new Date();
    return date > today; // Return true if the date is in the future
  };

  const getCurrentWeekRange = () => {
    return getWeekRange(new Date()); // Using today's date to calculate the current week
  };

  const handleDateClick = (date) => {
    setIsDateSelected(true);
    let newDateWeekIndex = getIndexOfWeekbyDate(date);
    let currentDateWeekIndex = -1;
    if (selectedDate) {
      currentDateWeekIndex = getIndexOfWeekbyDate(selectedDate);
    }

    // console.log("newDateWeekIndex", newDateWeekIndex);
    // console.log("currentDateWeekIndex", currentDateWeekIndex);

    if (newDateWeekIndex != currentDateWeekIndex) {
      if (selectedDate) setPreviousDate(selectedDate); // Update previously selected date
    }

    setSelectedDate(date);
    const { startOfWeek, endOfWeek } = getWeekRange(date);

    // Pass the start and end of week to the console
    leaderboard_fetch_search(
      moment(startOfWeek).format("YYYY-MM-DD"),
      moment(endOfWeek).format("YYYY-MM-DD")
    );
    setStart_week(startOfWeek);
    setEnd_week(endOfWeek);
    setCalender_toggle(false);
    // console.log(
    //   "Start of Week singleton: ",
    //   moment(startOfWeek).format("YYYY-MM-DD")
    // );
    //console.log("End of Week singleton: ", endOfWeek.toLocaleDateString());
    console.log("DATERANGE", selectedDate, previousDate, currentDate);
  };

  const calendarGrid = generateCalendarGrid(currentDate);
  const selectedWeekRange = selectedDate ? getWeekRange(selectedDate) : null;
  const previousWeekRange = previousDate ? getWeekRange(previousDate) : null;
  const [isDateSelected, setIsDateSelected] = useState(true);
  const currentWeekRange = getCurrentWeekRange();

  const handlePreviousMonth = () => {
    setIsDateSelected(false);
    setPreviousDate(null);
    // setSelectedDate(null);

    setCurrentDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        new Date().getDate()
      )
    );
  };
  const handleNextMonth = () => {
    setIsDateSelected(false);
    setPreviousDate(null);
    // setSelectedDate(null);
    setCurrentDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        new Date().getDate()
      )
    );
  };
  const [openRow, setOpenRow] = useState(null);

  // Toggle the accordion for the selected row
  const toggleAccordion = (id) => {
    setOpenRow(openRow === id ? null : id);
    if (!start_week) {
      history_fetch(startOfWeek, endOfWeek);
    }
    history_fetch(start_week, end_week);
  };

  const history_fetch = (start, end) => {
    let params = {
      start_date: start,
      end_date: end,
    };
    Service.playing_history(params)
      .then((res) => {
        console.log(res?.data);
        if (res?.status == 1) {
          setPlaying_history(res?.data?.reverse());
        }
      })
      .catch((e) => console.log(e));
  };

  const isIdPresent = (id) => {
    return leaderboard.some((item) => item.visitor_id === id);
  };

  return (
    <div>
      <Header onPress={toggleMenu} />
      {/* <CustomSelect /> */}

      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="total_side_gap">
          <div className="row h-100">
            {/* NAVIGATION */}
            <NavigationBar isActive={isActive} username={username} />
            {/* NAVIGATION */}
            {/* PAGE CONTENT */}
            <div className="col mt-3">
              <div className="row d-block d-lg-none">
                <div className="col-12 mb-3 d-flex justify-content-center">
                  {visible && (
                    <div className="custom-picker-popup-mobile">
                      {search_data?.map((item) => {
                        return (
                          <div
                            className="d-flex align-items-center searchItem"
                            onClick={() => {
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              });
                              //window.location.reload();
                            }}
                          >
                            <img
                              style={{
                                borderRadius: 5,
                                margin: 5,
                                objectFit: "cover",
                              }}
                              src={item.cover_picture}
                              height={"40px"}
                              width={"40px"}
                            />
                            <div className="ms-2">
                              {item.title}
                              <div className="d-flex gameInfo">
                                <small>{item?.category_names}</small>&nbsp; |
                                &nbsp;
                                <small>{item?.technology_names}</small>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-3 h-100 gx-2">
                <div className="col-12 col-sm-4 col-lg-4 col-xl-3 mb-3 mb-lg-0">
                  <div className="dark-bg h-100">
                    <h5 className="leaderboardTitle mb-3">Top Players</h5>

                    <div className="row m-0 mb-4">
                      <div className="col-12 mb-4 text-center">
                        <div className="d-flex flex-column align-items-center">
                          <div className="userPosition mb-3">
                            {leaderboard[0] ? (
                              <Avatar
                                className="profile_ring"
                                // borderColor="#F6BA00"
                                size="xl"
                                // bordered

                                src={
                                  !leaderboard[0]?.profile_picture
                                    ? leaderboard[0]?.avatar_id
                                      ? require(`../assets/avatars/${
                                          Avatar_data[
                                            leaderboard[0]?.avatar_id - 1
                                          ]?.avatar_icon
                                        }`)
                                      : ""
                                    : leaderboard[0]?.profile_picture
                                }
                                circle
                              />
                            ) : (
                              <Avatar
                                className="profile_ring"
                                size="xl"
                                src={require(`../assets/empty_user.png`)}
                                circle
                                style={{ objectFit: "contain" }}
                              />
                            )}
                            {leaderboard[0] ? (
                              <div className="positionBadge">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/gold.png")}
                                  alt=""
                                />
                                <span className="positionNumber">1</span>
                              </div>
                            ) : (
                              <div className="positionBadge">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/gold.png")}
                                  alt=""
                                />
                                <span className="positionNumber">1</span>
                              </div>
                            )}
                          </div>
                          {leaderboard[0] ? (
                            <p className="leaderboard_username m-0 text-white">
                              {leaderboard[0] && (
                                <Flag
                                  className="me-2"
                                  height={"20px"}
                                  width={"20px"}
                                  code={leaderboard[0]?.country_code}
                                />
                              )}
                              {leaderboard[0]?.visitor_id == user_id
                                ? "Me"
                                : leaderboard[0]?.full_name}
                            </p>
                          ) : (
                            <p className="playerName m-0 text-white">
                              Waiting...
                            </p>
                          )}
                          {leaderboard[0] && (
                            <p className="d-flex align-items-center points text-white">
                              <img
                                src={require("../assets/star.png")}
                                width={15}
                                alt=""
                                className="me-1"
                              />
                              {leaderboard[0]?.equivalent_point}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-column align-items-center">
                          <div className="userPosition mb-3">
                            {leaderboard[1] ? (
                              <Avatar
                                size="lg"
                                // borderColor="#31A4FF"
                                // bordered
                                src={
                                  !leaderboard[1]?.profile_picture
                                    ? leaderboard[1]?.avatar_id
                                      ? require(`../assets/avatars/${
                                          Avatar_data[
                                            leaderboard[1]?.avatar_id - 1
                                          ]?.avatar_icon
                                        }`)
                                      : ""
                                    : leaderboard[1]?.profile_picture
                                }
                                circle
                              />
                            ) : (
                              <Avatar
                                className="profile_ring"
                                size="lg"
                                src={require(`../assets/empty_user.png`)}
                                circle
                                style={{ objectFit: "contain" }}
                              />
                            )}
                            {leaderboard[1] ? (
                              <div className="positionBadge2">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/silver.png")}
                                  alt=""
                                />
                                <span className="positionNumber">2</span>
                              </div>
                            ) : (
                              <div className="positionBadge2">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/silver.png")}
                                  alt=""
                                />
                                <span className="positionNumber">2</span>
                              </div>
                            )}
                          </div>

                          {leaderboard[1] ? (
                            <p className="leaderboard_username m-0 text-white text-nowrap">
                              {leaderboard[1] && (
                                <Flag
                                  className="me-2"
                                  height={"20px"}
                                  width={"20px"}
                                  code={leaderboard[1]?.country_code}
                                />
                              )}
                              {leaderboard[1]?.visitor_id == user_id
                                ? "Me"
                                : leaderboard[1]?.full_name}
                            </p>
                          ) : (
                            <p className="playerName m-0 text-white text-nowrap">
                              Waiting...
                            </p>
                          )}
                          {leaderboard[1] && (
                            <p className="d-flex align-items-center points text-white">
                              <img
                                src={require("../assets/star.png")}
                                width={15}
                                alt=""
                                className="me-1"
                              />
                              {leaderboard[1]?.equivalent_point}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-column align-items-center">
                          <div className="userPosition mb-3">
                            {leaderboard[2] ? (
                              <Avatar
                                className="ndply"
                                size="lg"
                                // borderColor="#6A73A0"
                                // bordered
                                src={
                                  !leaderboard[2]?.profile_picture
                                    ? leaderboard[2]?.avatar_id
                                      ? require(`../assets/avatars/${
                                          Avatar_data[
                                            leaderboard[2]?.avatar_id - 1
                                          ]?.avatar_icon
                                        }`)
                                      : ""
                                    : leaderboard[2]?.profile_picture
                                }
                                circle
                              />
                            ) : (
                              <Avatar
                                className="profile_ring"
                                size="lg"
                                src={require(`../assets/empty_user.png`)}
                                circle
                                style={{
                                  objectFit: "contain",
                                  border: "2px solid #8b93bb",
                                }}
                              />
                            )}
                            {leaderboard[2] ? (
                              <div className="positionBadge2">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/bronze.png")}
                                  alt=""
                                />
                                <span className="positionNumber">3</span>
                              </div>
                            ) : (
                              <div className="positionBadge2">
                                <img
                                  className="w-100"
                                  src={require("../assets/avatars/bronze.png")}
                                  alt=""
                                />
                                <span className="positionNumber">3</span>
                              </div>
                            )}
                          </div>
                          {leaderboard[2] ? (
                            <p className="leaderboard_username m-0 text-white text-nowrap">
                              {leaderboard[2] && (
                                <Flag
                                  className="me-2"
                                  height={"20px"}
                                  width={"20px"}
                                  code={leaderboard[2]?.country_code}
                                />
                              )}
                              {leaderboard[2]?.visitor_id == user_id
                                ? "Me"
                                : leaderboard[2]?.full_name?.length > 15
                                ? leaderboard[2]?.full_name?.substring(
                                    0,
                                    15 - 1
                                  ) + ""
                                : leaderboard[2]?.full_name}
                            </p>
                          ) : (
                            <p className="playerName m-0 text-white text-nowrap">
                              Waiting...
                            </p>
                          )}
                          {leaderboard[2] && (
                            <p className="d-flex align-items-center points text-white">
                              <img
                                src={require("../assets/star.png")}
                                width={15}
                                alt=""
                                className="me-1"
                              />
                              {leaderboard[2]?.equivalent_point}
                            </p>
                          )}
                        </div>
                      </div>
                      {leaderboard[0]?.visitor_id == user_id ||
                      leaderboard[1]?.visitor_id == user_id ||
                      leaderboard[2]?.visitor_id == user_id ? (
                        <div className="text-center">
                          <Button
                            appearance="primary"
                            onClick={() => {
                              NiceModal.show(History_Modal, {
                                start: start_week || startOfWeek,
                                end: end_week || endOfWeek,
                              });
                              //   modal.hide();
                            }}
                            style={{
                              borderRadius: 20,
                              background: "#F2641F",
                              padding: "10px 25px",
                              color: "#fff",
                            }}
                            className="me-3"
                          >
                            Playing History
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-8 col-lg-8 col-xl-9">
                  <div className="dark-bg h-100 px-3">
                    <div className="row m-0">
                      <div className="col mb-2 d-flex align-items-center justify-content-between">
                        <h5 className="leaderboardTitle">
                          Leaderboard
                          <span
                            onClick={() =>
                              NiceModal.show(Rules_Modal, { navigate })
                            }
                          >
                            View Rules
                          </span>
                        </h5>
                        <span className="date" ref={wrapperRef}>
                          {calender_toggle && (
                            <div className="calendar">
                              <div className="calendar-header text-white">
                                <button onClick={handlePreviousMonth}>
                                  <i class="fa-solid fa-chevron-left"></i>
                                </button>
                                <h2>
                                  {currentDate.toLocaleDateString("en-US", {
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </h2>
                                <button onClick={handleNextMonth}>
                                  <i class="fa-solid fa-chevron-right"></i>
                                </button>
                              </div>
                              <div>
                                <div className="calendar-grid1">
                                  {[
                                    "Sun",
                                    "Mon",
                                    "Tue",
                                    "Wed",
                                    "Thu",
                                    "Fri",
                                    "Sat",
                                  ].map((day) => (
                                    <div
                                      key={day}
                                      className="calendar-day-header text-white"
                                    >
                                      {day}
                                    </div>
                                  ))}
                                </div>
                                <div className="calendar-grid">
                                  {weeks.map((week, weekIndex) => (
                                    <div
                                      key={weekIndex}
                                      className={`calendar-week ${
                                        selectedDate &&
                                        isDateInWeekRange(selectedDate, week)
                                          ? "highlighted-week-current"
                                          : previousDate &&
                                            currentDate &&
                                            isDateInWeekRange(currentDate, week)
                                          ? "highlighted-previous-week"
                                          : ""
                                      } 
                                   
                                    `}
                                    >
                                      {week.map((day, index) => (
                                        <div
                                          key={index}
                                          className={`calendar-day ${
                                            day &&
                                            selectedDate &&
                                            isDateInWeekRange(
                                              selectedDate,
                                              week
                                            )
                                              ? "highlighted-week-current"
                                              : "placeholder"
                                          }
                                       
                                        
                                        `}
                                          onClick={() =>
                                            day &&
                                            !isFutureDate(day) &&
                                            handleDateClick(day)
                                          }
                                        >
                                          {day ? day.getDate() : ""}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setCalender_toggle(!calender_toggle)}
                            className="orange_sec"
                          >
                            {`${moment(start_week || startOfWeek).format(
                              "DD MMM"
                            )} - ${moment(end_week || endOfWeek).format(
                              "DD MMM, YYYY"
                            )}`}
                            <span
                              // className="editLink d-flex align-items-center"
                              style={{
                                pointerEvents: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ marginLeft: 6 }}
                                src={require("../assets/down-arrow.png")}
                                width={14}
                                alt=""
                              />
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-12 position-relative">
                        <div className="scrollTable">
                          <table
                            className="leaderboardTable w-100 text-white"
                            cellSpacing={0}
                          >
                            {loading ? (
                              <Loader
                                center
                                size="md"
                                content="Please wait..."
                              />
                            ) : (
                              leaderboard?.slice(3).map((item, index) => {
                                // console.log({ item });
                                return (
                                  <React.Fragment key={item?.id}>
                                    <tr>
                                      <td
                                        style={{
                                          width: "50px",
                                          background:
                                            item?.visitor_id === user_id &&
                                            "#3D4063",
                                        }}
                                      >
                                        {item?.rank}
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (item?.visitor_id === user_id) {
                                            toggleAccordion(item?.id); // Toggle accordion
                                          }
                                        }}
                                        style={{
                                          width: "45%",
                                          background:
                                            item?.visitor_id === user_id &&
                                            "#3D4063",
                                          cursor:
                                            item?.visitor_id === user_id &&
                                            "pointer",
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <Avatar
                                            src={
                                              !item?.profile_picture
                                                ? item?.avatar_id
                                                  ? require(`../assets/avatars/${
                                                      Avatar_data[
                                                        item?.avatar_id - 1
                                                      ]?.avatar_icon
                                                    }`)
                                                  : ""
                                                : item?.profile_picture
                                            }
                                            className="me-3"
                                            circle
                                          />
                                          <span className="leaderboard_username">
                                            {item?.visitor_id === user_id
                                              ? "Me"
                                              : item?.full_name}
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (item?.visitor_id === user_id) {
                                            toggleAccordion(item.id); // Toggle accordion
                                          }
                                        }}
                                        style={{
                                          background:
                                            item?.visitor_id === user_id &&
                                            "#3D4063",
                                          cursor:
                                            item?.visitor_id === user_id &&
                                            "pointer",
                                        }}
                                      >
                                        <div className="d-flex align-items-center text-nowrap">
                                          <Flag
                                            className="me-2"
                                            height={"20px"}
                                            width={"20px"}
                                            code={item?.country_code}
                                          />
                                          {item?.country}
                                        </div>
                                      </td>
                                      <td
                                        onClick={() => {
                                          if (item?.visitor_id === user_id) {
                                            toggleAccordion(item.id); // Toggle accordion
                                          }
                                        }}
                                        style={{
                                          width: "100px",
                                          background:
                                            item?.visitor_id === user_id &&
                                            "#3D4063",
                                          cursor:
                                            item?.visitor_id === user_id &&
                                            "pointer",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-start position-relative">
                                          <img
                                            src={require("../assets/star.png")}
                                            width={15}
                                            alt=""
                                            className="me-1"
                                          />
                                          {item?.equivalent_point}
                                          {item?.visitor_id === user_id && (
                                            <span
                                              style={{
                                                pointerEvents: "none",
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: "10px",
                                                fontSize: "20px",
                                                color: "#ffffff7d",
                                              }}
                                            >
                                              {openRow === item?.id ? (
                                                <i
                                                  style={{ marginLeft: 6 }}
                                                  class="fa-solid fa-angle-up"
                                                ></i>
                                              ) : (
                                                <i
                                                  style={{ marginLeft: 6 }}
                                                  class="fa-solid fa-angle-down"
                                                ></i>
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                    </tr>

                                    {openRow === item?.id &&
                                      item?.visitor_id === user_id && (
                                        <tr>
                                          <td
                                            colSpan="4"
                                            style={{
                                              padding: "0px 0",
                                              borderBottom: "none",
                                            }}
                                          >
                                            <div className="leaderboard_play_history">
                                              <table
                                                className="leaderboardTable w-100 text-white"
                                                cellSpacing={0}
                                                style={{
                                                  background: "#333947",
                                                }}
                                              >
                                                {/* <thead>
                                              <tr>
                                                <th>Rank</th>
                                                <th>Game Name</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Score</th>
                                                <th>Equivalent Score</th>
                                              </tr>
                                            </thead> */}
                                                <tbody>
                                                  {playing_history?.map(
                                                    (
                                                      historyItem,
                                                      historyIndex
                                                    ) => {
                                                      return (
                                                        <tr key={historyIndex}>
                                                          <td
                                                            style={{
                                                              width: "50px",
                                                            }}
                                                          ></td>
                                                          <td
                                                            style={{
                                                              width: "45%",
                                                            }}
                                                          >
                                                            <div className="d-flex align-items-center text-nowrap">
                                                              <Avatar
                                                                bordered={false}
                                                                src={
                                                                  historyItem?.Game_image
                                                                }
                                                                className="me-3"
                                                                size="md"
                                                              />
                                                              {historyItem?.Game_name?.replace(
                                                                /-/g,
                                                                " "
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            {moment(
                                                              historyItem?.Playing_date_time
                                                            ).format(
                                                              "DD MMM, YYYY"
                                                            )}
                                                          </td>

                                                          <td>
                                                            <div className="d-flex align-items-center">
                                                              {`Score : ${historyItem?.Score}`}
                                                            </div>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "100px",
                                                            }}
                                                          >
                                                            <div
                                                              className="d-flex align-items-center justify-content-start"
                                                              style={{
                                                                marginLeft:
                                                                  "0px",
                                                              }}
                                                            >
                                                              <img
                                                                src={require("../assets/star.png")}
                                                                width={15}
                                                                alt=""
                                                                className="me-1"
                                                              />
                                                              {
                                                                historyItem?.Equivalent_Score
                                                              }
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </table>
                        </div>

                        {/* {leaderboard == 0 && (
                          <div className="row">
                            <div className="col">
                              <h5 className="text-center text-white">
                                Sorry! No leaderboard list available for
                                selected period.
                              </h5>
                            </div>
                          </div>
                        )} */}
                        {leaderboard.length <= 3 && (
                          <div className="row">
                            <div className="col">
                              <div className="empty_list">
                                <img src={require("../assets/empty.png")} />
                                <p className="text-center ">
                                  Opps! it seems there is no more player data
                                  available.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {!isIdPresent(profile_details?.id) && (
                      <div className="fixedtable">
                        <table
                          className="leaderboardTable leaderboard_static w-100 text-white"
                          cellSpacing={0}
                        >
                          <tr>
                            {Login_token && (
                              <td
                                style={{
                                  width: "50px",
                                  background: "#3D4063",
                                }}
                              >
                                {Login_token ? "0" : ""}
                              </td>
                            )}
                            <td
                              style={{
                                width: "45%",
                                background: "#3D4063",
                                // cursor: "pointer",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                {Login_token ? (
                                  <>
                                    <Avatar
                                      src={
                                        !profile_details?.profile_picture
                                          ? profile_details?.avatar_id
                                            ? require(`../assets/avatars/${
                                                Avatar_data[
                                                  profile_details?.avatar_id - 1
                                                ]?.avatar_icon
                                              }`)
                                            : ""
                                          : profile_details?.profile_picture
                                      }
                                      className="me-3"
                                      circle
                                    />
                                    <span>{"Me"}</span>
                                  </>
                                ) : (
                                  <div className="w-100 text-center">
                                    <p className="py-2 m-0">
                                      To check your progress on leaderboard!{" "}
                                      <span
                                        onClick={() =>
                                          NiceModal.show(Phone_Modal, {
                                            navigate,
                                          })
                                        }
                                        style={{
                                          color: "#F2641F",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Sign in Now.
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                            {Login_token && (
                              <td
                                style={{
                                  background: "#3D4063",
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <Flag
                                    className="me-2"
                                    height={"20px"}
                                    width={"20px"}
                                    code={profile_details?.country_code_alias}
                                  />
                                  {profile_details?.country}
                                </div>
                              </td>
                            )}
                            {Login_token && (
                              <td
                                style={{
                                  width: "100px",
                                  background: "#3D4063",
                                  // cursor: "pointer",
                                }}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <img
                                    src={require("../assets/star.png")}
                                    width={15}
                                    alt=""
                                    className="me-1"
                                  />
                                  {"0"}
                                </div>
                              </td>
                            )}
                          </tr>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112"></div>
      </div>
    </div>
  );
};

export default Leaderboard;
