import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Service from "../service/apis";
import HoverVideoPlayer from "react-hover-video-player";
import { alert, confirm, prompt } from "@rsuite/interactions";
import Cookies from "universal-cookie";
import useStore from "../service/zustand";
import Header from "../Header";
import NavigationBar from "../NavigationBar";
import { Input, InputGroup } from "rsuite";
import { useSelector } from "react-redux";
import Suggest_word from "../words.json";

const Search_game = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredTitles, setFilteredTitles] = useState(data);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [username, setUsername] = useState("");
  const [msg_err, setMsg_err] = useState(0);
  const { profile_status_load, add_profile_load, token } = useStore();
  const [loading, setLoading] = useState(false);
  const [all_game, setAll_Game] = useState([]);
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = useSelector((state) => state.login_token);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    recent_game();
    all_game_list();
  }, [location?.state]);
  const [searchQuery, setSearchQuery] = useState("");

  const [filteredGames, setFilteredGames] = useState(all_game);
  const [filteredSearch, setFilteredSearch] = useState([]);
  const normalizeText = (text) => {
    return text.toLowerCase().replace(/[-\s]/g, "");
  };

  const handleInputChange = (event) => {
    const query = event;

    if (!event) {
      setVisible(false);
    } else if (event) {
      setVisible(true);
    }

    setSearchQuery(query);

    const queryTerms = query
      .toLowerCase()
      .split(",")
      .map((term) => term.trim())
      .filter((term) => term);

    const filtered_suggest = Suggest_word.filter((game) => {
      return queryTerms.some((term) => {
        return normalizeText(game.suggestions).includes(normalizeText(term));
      });
    });
    setFilteredSearch(filtered_suggest);

    const filtered = all_game.filter((game) => {
      return queryTerms.some((term) => {
        return (
          normalizeText(game.title).includes(normalizeText(term)) ||
          normalizeText(game.platform).includes(normalizeText(term)) ||
          normalizeText(game.technology).includes(normalizeText(term)) ||
          normalizeText(game.category).includes(normalizeText(term))
        );
      });
    });
    setFilteredGames(filtered);
  };

  const all_game_list = (params) => {
    setLoading(true);
    Service.game_list(params)
      .then((res) => {
        if (res.data == null) {
          setAll_Game([]);
          setLoading(false);
        }
        // console.log(res.data);
        setAll_Game(res.data);
        setLoading(false);
      })
      .catch((e) => e.message);
  };

  const recent_game = () => {
    let params = {
      search: location?.state?.search,
    };
    Service.Get_suggestions(params)
      .then((res) => {
        // console.log(res.status);
        if (res?.status == 0) {
          setMsg_err(0);
          setData([]);
        }
        if (res?.status == 1) {
          setData(res?.data);
          setMsg_err(1);
        }
      })
      .catch((e) => console.log(e.message));
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="scroll_issue">
      <Header onPress={toggleMenu} />

      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="row ">
          {/* NAVIGATION */}
          <NavigationBar isActive={isActive} username={username} />

          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center">
                <InputGroup>
                  <Input
                    value={searchQuery}
                    onChange={handleInputChange}
                    className=" search-box"
                    placeholder={"Search Game"}
                  />
                  <InputGroup.Addon style={{ top: "0px" }}>
                    <img src={require("./skins/search.png")} alt="" />
                  </InputGroup.Addon>
                </InputGroup>
                {visible && (
                  <div className="custom-picker-popup-mobile">
                    <div className="p-2">
                      {filteredSearch.slice(0, 4).map((item, index) => {
                        return (
                          <span
                            onClick={() => {
                              navigate("/search", {
                                state: {
                                  search: item.suggestions,
                                },
                              });
                              setVisible(false);
                            }}
                            style={{
                              display: "inline-block",
                              border: "1px solid #818A97",
                              borderRadius: "10px",
                              padding: "1px 4px",
                              margin: "2px",
                              cursor: "pointer",
                              color: "#818A97",
                            }}
                          >
                            {item?.suggestions}
                          </span>
                        );
                      })}
                    </div>
                    {filteredGames?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem"
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            //window.location.reload();
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item?.title?.replace(/-/g, " ")}
                            <div className="d-flex gameInfo">
                              <small>{item?.category}</small>&nbsp; | &nbsp;
                              <small>{item?.technology}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="row gx-2">
              <div className="catgoryTitle mb-3">{location?.state?.search}</div>
              {/* <div className="catgoryTitle mb-3">{"Running"}</div> */}
              {searchValue.length > 1
                ? filteredTitles?.map((item, index) => {
                    //console.log(item);
                    return (
                      <div className="col-6 col-sm-3 col-lg-2">
                        <div className="gameItem">
                          <HoverVideoPlayer
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                            className="connect_the_ball_ek11 gameThumb"
                            restartOnPaused
                            overlayTransitionDuration={250}
                            // videoStyle={{
                            //   height: "25%",
                            //   width: "100%",
                            // }}
                            videoSrc={item?.video_url || item?.video_upload}
                            pausedOverlay={
                              <img
                                src={item?.cover_picture}
                                alt=""
                                className="connect_the_ball_ek11 h-100"
                              />
                            }
                            loadingOverlay={
                              <div className="loading-overlay">
                                <div className="loading-spinner" />
                              </div>
                            }
                          />
                          <div className="hoverText">
                            {item?.title?.replace(/-/g, " ")}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : data?.map((item, index) => {
                    //console.log(item);

                    let is30DaysOrMore = false;
                    const targetDate = new Date(item?.last_updated);
                    const currentDate = new Date();
                    const timeDifference = currentDate - targetDate;
                    const daysDifference = timeDifference / (1000 * 3600 * 24);
                    if (daysDifference >= 30) {
                      is30DaysOrMore = true;
                    } else {
                      is30DaysOrMore = false;
                    }
                    return (
                      <div className="col-6 col-sm-3 col-lg-2 position-relative">
                        <div className="gameItem">
                          <HoverVideoPlayer
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                            className="connect_the_ball_ek11 gameThumb"
                            restartOnPaused
                            overlayTransitionDuration={250}
                            videoSrc={item?.video_url || item?.video_upload}
                            pausedOverlay={
                              <img
                                src={item?.cover_picture}
                                alt=""
                                className="connect_the_ball_ek11 h-100"
                              />
                            }
                            loadingOverlay={
                              <div className="loading-overlay">
                                <div className="loading-spinner" />
                              </div>
                            }
                          />
                          <div className="hoverText">
                            {item?.title?.replace(/-/g, " ")}
                          </div>
                          {location?.state?.name == "Recently Played" &&
                            ACCESS_TOKEN_USER && (
                              <div className="deleteGame">
                                <img
                                  onClick={async () => {
                                    const options = {
                                      okButtonText: "Yes",
                                      cancelButtonText: "Cancel",
                                    };
                                    const result = await confirm(
                                      "Are you sure? You want to remove this game from your Recently Played games list",
                                      options
                                    );

                                    if (result) {
                                      // fav_status(item?.id, 0);
                                    }
                                  }}
                                  src={require("../assets/close.png")}
                                  style={{ width: 15 }}
                                  alt=""
                                />
                              </div>
                            )}
                        </div>
                        {item.tag == "new" && (
                          <div className="new-badge">New</div>
                        )}
                        {!is30DaysOrMore ? (
                          <div className="update-badge">Updated</div>
                        ) : null}
                      </div>
                    );
                  })}
            </div>

            {data?.length == 0 && (
              <div className="row">
                <div className="col">
                  <div className="empty_list mt-0 mb-3">
                    <img src={require("../assets/empty.png")} width={"70"} />
                    <p className="text-center ">
                      Oops! No matches found. <br />
                      Try adjusting your search or explore our exciting games
                      and challenges on PlayZhub.com!
                    </p>
                  </div>
                </div>
              </div>
            )}

            {data.length == 0 && (
              <div className="row gx-2">
                <div className="catgoryTitle mb-3">{"Recommended Games"}</div>
                {all_game?.slice(3, 15).map((item, index) => {
                  let is30DaysOrMore = false;
                  const targetDate = new Date(item?.last_updated);
                  const currentDate = new Date();
                  const timeDifference = currentDate - targetDate;
                  const daysDifference = timeDifference / (1000 * 3600 * 24);
                  if (daysDifference >= 30) {
                    is30DaysOrMore = true;
                  } else {
                    is30DaysOrMore = false;
                  }
                  return (
                    <div className="col-6 col-sm-3 col-lg-2 position-relative">
                      <div className="gameItem">
                        <HoverVideoPlayer
                          onClick={() =>
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            })
                          }
                          style={{ cursor: "pointer" }}
                          className="connect_the_ball_ek11 gameThumb"
                          restartOnPaused
                          overlayTransitionDuration={250}
                          videoSrc={item?.video_url || item?.video_upload}
                          pausedOverlay={
                            <img
                              src={item?.cover_picture}
                              alt=""
                              className="connect_the_ball_ek11 h-100"
                            />
                          }
                          loadingOverlay={
                            <div className="loading-overlay">
                              <div className="loading-spinner" />
                            </div>
                          }
                        />
                        <div className="hoverText">
                          {item?.title?.replace(/-/g, " ")}
                        </div>
                        {location?.state?.name == "Recently Played" &&
                          ACCESS_TOKEN_USER && (
                            <div className="deleteGame">
                              <img
                                onClick={async () => {
                                  const options = {
                                    okButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                  };
                                  const result = await confirm(
                                    "Are you sure? You want to remove this game from your Recently Played games list",
                                    options
                                  );

                                  if (result) {
                                    // fav_status(item?.id, 0);
                                  }
                                }}
                                src={require("../assets/close.png")}
                                style={{ width: 15 }}
                                alt=""
                              />
                            </div>
                          )}
                      </div>
                      {item.tag == "new" && (
                        <div className="new-badge">New</div>
                      )}
                      {!is30DaysOrMore ? (
                        <div className="update-badge">Updated</div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112"></div>
      </div>
    </div>
  );
};

export default Search_game;
